import { END_POINTS } from "../helpers/constants/urls";
import {
  Brand,
  Category,
  User,
  Paginated,
  SubCategory,
  UserType,
  SubAmin,
  Role,
  Reviews,
} from "../types/General";
import emptySplitApi from "../utils/rtk";

type PostCategoryBody = {
  name: string;
  description: string;
  category_image: number;
};

type PostSubCategoryBody = {
  name: string;
  description: string;
  category: number;
};

type PostBrandBody = {
  name: string;
  description: string;
  brand_logo_id: number;
};

type CategoriesResponse = {
  code: number;
  message: string;
  data: Category[];
  recordsTotal: number;
};

type CategoryResponse = {
  code: number;
  message: string;
  data: Category;
};

type SubCategoriesResponse = {
  code: number;
  message: string;
  data: SubCategory[];
  recordsTotal: number;
};

type SubCategoryByIdResponse = {
  code: number;
  message: string;
  data: SubCategory;
};

type BrandsResponse = {
  code: number;
  message: string;
  data: Brand[];
  recordsTotal: number;
};

type BrandByIdResponse = {
  code: number;
  message: string;
  data: Brand;
};

type GetBrandsPayload = {
  start: number;
  is_active?: boolean;
  length: number;
  search?: Paginated;
};

type GetAllUsersResponse = {
  code: number;
  message: string;
  data: User[];
  recordsTotal: number;
};

type GetUserResponseById = {
  code: number;
  message: string;
  data: User;
};

type UserByIdResponse = {
  code: number;
  message: string;
  data: boolean;
};

type SubAminResponse = {
  recordsTotal: number;
  code: number;
  message: string;
  data: SubAmin[];
};

type PostSubAdminBody = {
  id?: number;
  first_name: string;
  last_name: string;
  image?: number | string;
  email: string;
  office_phone_no: string;
  password?: string;
  user_role: number | undefined;
  office_country_code: string;
  office_country_iso_code: string;
};

type PostSubAdminResponse = {
  code: number;
  message: string;
  data: PostSubAdminBody;
};

type getSubAdminResponse = {
  code: number;
  message: string;
  data: SubAmin;
};

type editSubAminResponse = {
  code: number;
  message: string;
  data: null;
};

type RolesResponse = {
  code: number;
  message: string;
  data: {
    id: number;
    name: string;
    is_active: boolean;
  }[];
  recordsTotal: number;
};

type RoleDetailsResponse = {
  code: number;
  message: string;
  data: Role;
  recordsTotal: number;
};

type SubAdminModulesResponse = {
  code: number;
  message: string;
  data: {
    id: number;
    name: string;
  }[];
};

export const mainApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.mutation<
      CategoriesResponse,
      { body: GetBrandsPayload }
    >({
      query: ({ body }) => ({
        url: `${END_POINTS.categories_list}`,
        method: "POST",
        body,
      }),
    }),
    getCategoryById: builder.query<CategoryResponse, { category_id: string }>({
      query: ({ category_id }) => ({
        url: `${END_POINTS.category}${category_id}/`,
        method: "GET",
      }),
    }),
    updateCategoryById: builder.mutation<
      CategoryResponse,
      {
        category_id: string;
        body:
          | { name: string; description: string; category_image: number }
          | Pick<Category, "is_active">;
      }
    >({
      query: ({ category_id, body }) => ({
        url: `${END_POINTS.update_category}${category_id}/`,
        method: "PUT",
        body,
      }),
    }),
    postCategory: builder.mutation<CategoryResponse, PostCategoryBody>({
      query: (body) => ({
        url: END_POINTS.add_category,
        method: "POST",
        body,
      }),
    }),
    getSubCategories: builder.mutation<
      SubCategoriesResponse,
      { body: GetBrandsPayload }
    >({
      query: ({ body }) => ({
        url: `${END_POINTS.subcategories_list}`,
        method: "POST",
        body,
      }),
    }),
    getSubCategoryById: builder.query<
      SubCategoryByIdResponse,
      { sub_category_id: string }
    >({
      query: ({ sub_category_id }) => ({
        url: `${END_POINTS.sub_category}${sub_category_id}`,
        method: "GET",
      }),
    }),
    getSubCategoryByCategoryId: builder.query<
      SubCategoriesResponse,
      { category_id: string }
    >({
      query: ({ category_id }) => ({
        url: `${END_POINTS.get_sub_category_by_category_id}${category_id}/`,
        method: "GET",
      }),
    }),
    updateSubCategoryById: builder.mutation<
      CategoryResponse,
      {
        sub_category_id: string;
        body: Omit<SubCategory, "id"> | Pick<SubCategory, "is_active">;
      }
    >({
      query: ({ sub_category_id, body }) => ({
        url: `${END_POINTS.update_sub_category}${sub_category_id}/`,
        method: "PUT",
        body,
      }),
    }),
    postSubCategory: builder.mutation<
      SubCategoryByIdResponse,
      PostSubCategoryBody
    >({
      query: (body) => ({
        url: END_POINTS.add_subcategory,
        method: "POST",
        body,
      }),
    }),
    getBrands: builder.mutation<BrandsResponse, { body: GetBrandsPayload }>({
      query: ({ body }) => ({
        url: END_POINTS.brands_list,
        method: "POST",
        body,
      }),
    }),
    getBrandById: builder.query<BrandByIdResponse, { brand_id: string }>({
      query: ({ brand_id }) => ({
        url: `${END_POINTS.brand}${brand_id}`,
        method: "GET",
      }),
    }),
    updateBrandById: builder.mutation<
      BrandByIdResponse,
      {
        brand_id: string;
        body: any;
      }
    >({
      query: ({ brand_id, body }) => ({
        url: `${END_POINTS.update_brand}${brand_id}/`,
        method: "PUT",
        body,
      }),
    }),
    postBrand: builder.mutation<BrandsResponse, PostBrandBody>({
      query: (body) => ({
        url: END_POINTS.add_brand,
        method: "POST",
        body,
      }),
    }),
    getAllUsers: builder.query<GetAllUsersResponse, { body: GetBrandsPayload }>(
      {
        query: ({ body }) => ({
          url: END_POINTS.get_all_user,
          method: "POST",
          body,
        }),
      }
    ),
    getUserId: builder.query<GetUserResponseById, { user_id: string }>({
      query: ({ user_id }) => ({
        url: `${END_POINTS.user}${user_id}`,
        method: "GET",
      }),
    }),
    updateUserById: builder.mutation<
      UserByIdResponse,
      {
        user_id: string;
        body: Omit<UserType, "id"> | Pick<UserType, "is_active">;
      }
    >({
      query: ({ user_id, body }) => ({
        url: `${END_POINTS.update_user_status_by_id}${user_id}/`,
        method: "PUT",
        body,
      }),
    }),
    updateUserDetailById: builder.mutation<
      GetUserResponseById,
      { user_id: string; body: Omit<UserType, "id"> }
    >({
      query: ({ user_id, body }) => ({
        url: `${END_POINTS.update_user_detail}${user_id}/`,
        method: "PUT",
        body,
      }),
    }),
    getSubAdmin: builder.mutation<SubAminResponse, { body: GetBrandsPayload }>({
      query: ({ body }) => ({
        url: END_POINTS.get_all_subAdmin,
        method: "POST",
        body,
      }),
    }),
    addSubAdmin: builder.mutation<PostSubAdminResponse, PostSubAdminBody>({
      query: (body) => ({
        url: END_POINTS.add_subAdmin,
        method: "POST",
        body,
      }),
    }),
    getSubAdminById: builder.query<
      getSubAdminResponse,
      { subAdmin_id: string }
    >({
      query: ({ subAdmin_id }) => ({
        url: `${END_POINTS.get_subAdmin_by_id}${subAdmin_id}`,
        method: "GET",
      }),
    }),
    updateSubAdminById: builder.mutation<
      editSubAminResponse,
      { subAdmin_id: string; body: Omit<PostSubAdminBody, "id"> }
    >({
      query: ({ subAdmin_id, body }) => ({
        url: `${END_POINTS.update_subAdmin_by_id}${subAdmin_id}/`,
        method: "PUT",
        body,
      }),
    }),
    updateSubAdminRole: builder.mutation<
      editSubAminResponse,
      { subAdmin_id: string; body: { role_id: number } }
    >({
      query: ({ subAdmin_id, body }) => ({
        url: `${END_POINTS.update_user_role}${subAdmin_id}/`,
        method: "PUT",
        body,
      }),
    }),
    getRole: builder.query<RolesResponse, { body: GetBrandsPayload }>({
      query: ({ body }) => ({
        url: END_POINTS.get_roles,
        method: "POST",
        body,
      }),
    }),
    getRoleById: builder.query<RoleDetailsResponse, { role_id: string }>({
      query: ({ role_id }) => ({
        url: `${END_POINTS.get_role_by_id}${role_id}/`,
        method: "GET",
      }),
    }),
    changeRoleStatus: builder.mutation<
      RolesResponse,
      { role_id: string; body: { is_active: boolean } }
    >({
      query: ({ role_id, body }) => ({
        url: `${END_POINTS.change_role_status}${role_id}/`,
        method: "PUT",
        body,
      }),
    }),
    addRole: builder.mutation<
      RolesResponse,
      { body: { name: string; role_permissions: any } }
    >({
      query: ({ body }) => ({
        url: END_POINTS.create_role,
        method: "POST",
        body,
      }),
    }),
    updateRole: builder.mutation<
      RolesResponse,
      { role_id: string; body: { name: string; role_permissions: any } }
    >({
      query: ({ role_id, body }) => ({
        url: `${END_POINTS.update_role}${role_id}/`,
        method: "PUT",
        body,
      }),
    }),
    getSubAdminModules: builder.query<SubAdminModulesResponse, {}>({
      query: () => ({
        url: END_POINTS.get_sub_admin_modules,
        method: "GET",
      }),
    }),
    getTokenAmount: builder.query<
      { code: number; message: string; data: { amount: string; id: number } },
      {}
    >({
      query: () => ({
        url: END_POINTS.get_token_amount,
        method: "GET",
      }),
    }),
    updateTokenAmount: builder.mutation<
      { code: number; message: string; data: { amount: string; id: number } },
      { body: { amount: string }; token_id: number }
    >({
      query: ({ body, token_id }) => ({
        url: `${END_POINTS.update_token_amount}${token_id}/`,
        method: "PUT",
        body,
      }),
    }),
    getDashboardValues: builder.query<
      {
        code: number;
        message: string;
        data: {
          closed_orders: number;
          group_sellers: number;
          individual_sellers: number;
          new_customers: number;
          total_customers: number;
          total_earning: number;
          total_orders: number;
          total_sellers: number;
        };
      },
      {}
    >({
      query: () => ({
        url: END_POINTS.dashboard,
        method: "GET",
      }),
    }),
    getRatings: builder.mutation<
      { code: number; message: string; data: Reviews[]; recordsTotal: number },
      { body: GetBrandsPayload }
    >({
      query: ({ body }) => ({
        url: END_POINTS.get_ratings,
        method: "POST",
        body,
      }),
    }),
    updateRatingsById: builder.mutation<
      { code: number; message: string; data: Reviews },
      {
        review_id: string;
        body: { is_active: boolean };
      }
    >({
      query: ({ review_id, body }) => ({
        url: `${END_POINTS.update_rating}${review_id}/`,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useGetCategoriesMutation,
  useLazyGetCategoryByIdQuery,
  usePostCategoryMutation,
  useUpdateCategoryByIdMutation,
  useGetSubCategoriesMutation,
  usePostSubCategoryMutation,
  useLazyGetSubCategoryByIdQuery,
  useUpdateSubCategoryByIdMutation,
  useLazyGetSubCategoryByCategoryIdQuery,
  useGetBrandsMutation,
  useLazyGetBrandByIdQuery,
  usePostBrandMutation,
  useUpdateBrandByIdMutation,
  useLazyGetAllUsersQuery,
  useLazyGetUserIdQuery,
  useUpdateUserByIdMutation,
  useUpdateUserDetailByIdMutation,
  useGetSubAdminMutation,
  useAddSubAdminMutation,
  useUpdateSubAdminRoleMutation,
  useLazyGetRoleQuery,
  useLazyGetSubAdminModulesQuery,
  useAddRoleMutation,
  useLazyGetSubAdminByIdQuery,
  useUpdateSubAdminByIdMutation,
  useChangeRoleStatusMutation,
  useLazyGetRoleByIdQuery,
  useUpdateRoleMutation,
  useLazyGetTokenAmountQuery,
  useUpdateTokenAmountMutation,
  useLazyGetDashboardValuesQuery,
  useGetRatingsMutation,
  useUpdateRatingsByIdMutation,
} = mainApi;
