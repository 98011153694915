import { Button, Card, Col, Row } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLazyGetProductByIdQuery } from "../../../services/product";
import { useEffect, useState } from "react";
import { Product } from "../../../types/General";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ProductDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [getProductById] = useLazyGetProductByIdQuery();

  const [productDetails, setProductDetails] = useState<Product | null>(null);
  const [documentPreview, setDocumentPreview] = useState([]);

  const getProductDetails = async () => {
    try {
      const response = await getProductById({ product_id: `${id}` }).unwrap();
      if (response?.code === 200) {
        setProductDetails(response?.data);
        setDocumentPreview([
          {
            url:
              (
                response?.data?.product_certificate_image as any
              )?.media_file_url?.replace("http", "https") || "",
          },
        ] as any);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getProductDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="module-details-container">
      <Card className="product_details">
        <div className="detail-module-card">
          <h2>Product Details</h2>
          <Button
            className="back-btn-container"
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigate("/manage-products", {
                replace: true,
                state: { tab: state?.tab || "1" },
              });
            }}
          >
            Back
          </Button>
        </div>

        <div>
          <Row gutter={16}>
            <Col xs={24} md={24}>
              <h4>Product image</h4>
              <div className="product_figure_preview">
                {/* <img
                  className="image-preview"
                  src={"/static/images/profile.png"}
                  alt=""
                /> */}
                {productDetails?.product_images?.length
                  ? productDetails?.product_images?.map((item: any) => {
                      return (
                        <figure key={item?.id}>
                          <img
                            src={item?.image?.media_file_url}
                            alt="Product"
                          />
                        </figure>
                      );
                    })
                  : ""}
              </div>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Name</h4>
              <h5>{productDetails?.product_name || ""}</h5>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Category</h4>
              <h5>
                {productDetails?.product_category &&
                typeof productDetails?.product_category !== "number"
                  ? productDetails?.product_category?.name || ""
                  : ""}
              </h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Sub Category</h4>
              <h5>Sub Category 1</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Brand</h4>
              <h5>
                {productDetails?.brand &&
                typeof productDetails?.brand !== "number"
                  ? productDetails?.brand?.name || ""
                  : ""}
              </h5>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Short Description</h4>
              <h5>{productDetails?.short_description || ""}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Specification/Material Description</h4>
              <h5>{productDetails?.detailed_description || ""}</h5>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Cost</h4>
              <h5>{productDetails?.product_cost || ""}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Stock</h4>
              <h5>{productDetails?.product_stock || ""}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Key Dimensions</h4>
              <h5>{productDetails?.product_key_dimension || ""}</h5>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Remarks</h4>
              <h5>{productDetails?.remark || ""}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Grade of Material</h4>
              <h5>{productDetails?.material_grade || ""}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Material of Construction</h4>
              <h5>{productDetails?.material_of_construction || ""}</h5>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Unit</h4>
              <h5>{productDetails?.product_unit || ""}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Country of Origin</h4>
              <h5>
                {productDetails?.country_of_origin &&
                typeof productDetails?.country_of_origin !== "number"
                  ? productDetails?.country_of_origin.name
                  : ""}
              </h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>NACE</h4>
              <h5>{productDetails?.nance ? "Yes" : "No"}</h5>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <h4>Test Certificate</h4>
              <div className="pdf_divv">
                {documentPreview?.length ? (
                  <div
                    className="pdf_cont"
                    onClick={() =>
                      window.open(`${(documentPreview[0] as any)?.url || ""}`)
                    }
                  >
                    <Document file={documentPreview[0]}>
                      <Page pageNumber={1} />
                    </Document>
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};
export default ProductDetails;
