import { Button, Card } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import FormData from "./CategoryForm";
import { useEffect } from "react";
import { useLazyGetCategoryByIdQuery } from "../../../services/main";
import usePermissions from "../../../hooks/permissions";
import { PageLoader } from "../../../components";
import useAuth from "../../../hooks/useAuth";
import { showWarning } from "../../../helpers/toast";
import { SideBarIds } from "../../../utils/constants";

const CategoryForm = () => {
  const { id } = useParams();
  const user = useAuth();
  const navigate = useNavigate();
  const permissions = usePermissions();

  const url = window.location.pathname;
  const path = url.includes("add");

  const [getCategoryById, { isLoading }] = useLazyGetCategoryByIdQuery();

  useEffect(() => {
    if (user?.role !== 1) {
      if (permissions) {
        let idx = -1;
        idx = permissions?.findIndex(
          (ele) => ele?.module?.id === SideBarIds.category
        );
        if (idx > -1) {
          if (!permissions[idx]?.is_add || !permissions[idx]?.is_edit) {
            showWarning("You are not authorized to access this page");
            navigate(-1);
          } else {
            if (!path) {
              if (id) {
                getCategoryDetails(id);
              }
            }
          }
        }
        showWarning("You are not authorized to access this page");
        navigate(-1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, user, id, path]);

  const getCategoryDetails = async (id: string) => {
    const response = await getCategoryById({ category_id: id }).unwrap();
    console.log(response);
  };

  return (
    <div className="module-container">
      <PageLoader isLoading={isLoading} />
      <Card>
        <div className="detail-module-card">
          <h2>{!path ? "Edit " : "Add "}Category</h2>
          <Button
            className="back-btn-container"
            type="primary"
            onClick={() => {
              navigate("/manage-categories", { replace: true });
            }}
          >
            Back
          </Button>
        </div>
        <FormData path={!path} />
      </Card>
    </div>
  );
};

export default CategoryForm;
