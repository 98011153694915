import { Button, Col, Form, Input, Row } from "antd";
import {
  useLazyGetCategoryByIdQuery,
  usePostCategoryMutation,
  useUpdateCategoryByIdMutation,
} from "../../../services/main";
import { showError, showToast } from "../../../helpers/toast";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Upload, { RcFile } from "antd/es/upload";
import { UploadMedia } from "../../../utils/imageUpload";
import { ImageViewer } from "../../../components";
import { UploadOutlined } from "@ant-design/icons";
import { isValidInput } from "../../../utils/validations";

const { TextArea } = Input;

let imageId = 0;
const FormData = (props: any) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const [categoryMutation, addLoader] = usePostCategoryMutation();
  const [getCategoryById] = useLazyGetCategoryByIdQuery();
  const [updateCategoryById, editLoader] = useUpdateCategoryByIdMutation();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [imageObject, setImageObject] = useState<RcFile | null>(null);
  const [imagePreview, setImagePreview] = useState("");

  const handleImageUpload = async () => {
    try {
      const res = await UploadMedia(imageObject);
      if (res?.code === 200) {
        return res?.data[0]?.id;
      }
      return 0;
    } catch (error: any) {
      console.log(error);
      return 0;
    }
  };

  const getCategoryDetails = async (id: string) => {
    const response = await getCategoryById({ category_id: id }).unwrap();
    if (response?.code === 200) {
      setName(response?.data?.name);
      setDescription(response?.data?.description);
      setImagePreview(response?.data?.category_image?.media_file_url);
      imageId = response?.data?.category_image?.id;
      form.setFieldsValue({
        product_name: response?.data?.name,
        description: response?.data?.description,
        image: imageId,
      });
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getCategoryDetails(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onFinish = async (values: any) => {
    console.log("Received values of form: ", values);
    try {
      if (imageObject || imageId) {
        if (!imageId) {
          imageId = await handleImageUpload();
        }
        let response = null;
        const body = {
          name,
          description,
        };
        if (id && id !== "add") {
          response = await updateCategoryById({
            category_id: id,
            body: { ...body, category_image: imageId },
          }).unwrap();
        } else {
          response = await categoryMutation({
            ...body,
            category_image: imageId,
          }).unwrap();
        }
        console.log(response);
        if (response?.code === 201 || response?.code === 200) {
          showToast(response?.message || "");
          navigate("/manage-categories", { replace: true });
        }
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error);
    }
  };

  const handleUpload = (File: RcFile) => {
    setImageObject(File);
    const result = window.URL.createObjectURL(File);
    setImagePreview(result);
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }: any) => {
    console.log(
      values,
      errorFields,
      outOfDate,
      "values, errorFields, outOfDate"
    );
  };

  return (
    <div>
      <Form
        form={form}
        initialValues={{ name: "sdasd", description: "dasdasd" }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="image"
              rules={[
                {
                  required: true,
                  message: "Please upload category image.",
                },
              ]}
            >
              {imagePreview ? (
                <ImageViewer
                  squared
                  source={imagePreview}
                  resetImage={() => {
                    setImageObject(null);
                    setImagePreview("");
                    imageId = 0;
                  }}
                />
              ) : (
                <Upload
                  name="logo"
                  beforeUpload={handleUpload}
                  accept="image/png, image/gif, image/jpeg"
                >
                  <h4>Upload Category Image</h4>
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="product_name"
              rules={[
                {
                  required: true,
                  message: "Please enter category name.",
                },
                {
                  min: name?.length ? 1 : 2,
                  message: "Please enter category name.",
                },
              ]}
            >
              <div>
                <h4>Name</h4>
                <Input
                  value={name}
                  onChange={(val) => {
                    if (isValidInput(val.target.value)) {
                      setName(val.target.value);
                    }
                  }}
                  placeholder="Name"
                  style={{ height: 55 }}
                  maxLength={50}
                />
              </div>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please enter category description.",
                },
                {
                  min: 10,
                  message: "Description must be at least 10 characters",
                },
              ]}
            >
              <div>
                <h4>Description</h4>
                <TextArea
                  maxLength={255}
                  value={description}
                  onChange={(val) => {
                    if (isValidInput(val.target.value)) {
                      setDescription(val.target.value);
                    }
                  }}
                  rows={10}
                  placeholder="Description"
                />
              </div>
            </Form.Item>
          </Col>
        </Row>

        <div className="submit-btn-wrapper">
          <Button
            className="submit-btn-container"
            type="primary"
            htmlType="submit"
            loading={addLoader?.isLoading || editLoader?.isLoading}
          >
            {props?.path ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default FormData;
