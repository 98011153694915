/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Col, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useLazyGetRoleByIdQuery } from "../../../services/main";
import { Role } from "../../../types/General";

const RoleDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [getRoleDetails] = useLazyGetRoleByIdQuery();

  const [roleDetails, setRoleDetails] = useState<Role | null>(null);

  const getRoleById = async () => {
    try {
      const response = await getRoleDetails({
        role_id: `${id || ""}`,
      }).unwrap();
      if (response?.code === 200) {
        setRoleDetails(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getRoleById();
    }
  }, [id]);

  const columns: ColumnsType<{
    id: number;
    is_add: boolean;
    is_edit: boolean;
    is_view: boolean;
    module: {
      id: number;
      name: string;
    };
  }> = [
    {
      title: "Modules",
      dataIndex: "label",
      key: "label",
      render: (_, record) => {
        return <div>{record?.module?.name || ""}</div>;
      },
    },
    {
      title: "Add",
      dataIndex: "",
      key: "",
      render: (_, record) => {
        return (
          <div>
            {record?.is_add ? (
              <CheckOutlined style={{ color: "green" }} />
            ) : (
              <CloseOutlined style={{ color: "red" }} />
            )}
          </div>
        );
      },
    },
    {
      title: "Edit",
      dataIndex: "",
      key: "",
      render: (_, record) => {
        return (
          <div>
            {record?.is_edit ? (
              <CheckOutlined style={{ color: "green" }} />
            ) : (
              <CloseOutlined style={{ color: "red" }} />
            )}
          </div>
        );
      },
    },
    {
      title: "View",
      dataIndex: "",
      key: "",
      render: (_, record) => {
        return (
          <div>
            {record?.is_view ? (
              <CheckOutlined style={{ color: "green" }} />
            ) : (
              <CloseOutlined style={{ color: "red" }} />
            )}
          </div>
        );
      },
    },
  ];
  return (
    <div className="module-details-container">
      <Card className="product_details">
        <div className="detail-module-card">
          <h2>Role & Permission Details</h2>
          <Button
            className="back-btn-container"
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigate("/manage-roles", { replace: true });
            }}
          >
            Back
          </Button>
        </div>
        <div>
          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Role</h4>
              <h5>{roleDetails?.name || ""}</h5>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={24}>
              <Table
                className="table-container"
                style={{ marginTop: 0, marginBottom: 0 }}
                pagination={false}
                dataSource={roleDetails?.role_permissions || []}
                columns={columns}
              />
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};
export default RoleDetails;
