import { Card, Col, Row, Select } from "antd";
import { ContentHeader } from "../../components";
import { LineChart } from "../../components/charts";
import {
  usePostGraphMutation,
  usePostRevenueGraphMutation,
} from "../../services/cms";
import { showError } from "../../helpers/toast";
import { useEffect, useState } from "react";

const Analytics = () => {
  const [getGraphdata] = usePostGraphMutation();
  const [getRevenueGraphdata] = usePostRevenueGraphMutation();

  const [revenueFilter, setRevenueFilter] = useState(2);
  const [customerFilter, setCustomerFilter] = useState(1);

  const [revenueNames, setRevenueNames] = useState<string[]>([]);
  const [revenueValues, setRevenueValues] = useState<number[]>([]);

  const [customersNames, setCustomersNames] = useState<string[]>([]);
  const [customersValues, setCustomersValues] = useState<number[]>([]);

  const graphData = {
    labels: revenueNames,
    datasets: [
      {
        label: "Revenue",
        data: revenueValues,
        borderColor: "rgb(55, 76, 152)",
        backgroundColor: "rgb(55, 76, 152, 0.5)",
      },
    ],
  };

  const graphCustomerData = {
    labels: customersNames,
    datasets: [
      {
        label: "Users",
        data: customersValues,
        borderColor: "rgb(55, 76, 152)",
        backgroundColor: "rgb(55, 76, 152, 0.5)",
      },
    ],
  };

  const hangleUserGraph = async (val: number) => {
    const body = {
      filter_type: val,
    };
    try {
      const response = await getGraphdata(body).unwrap();
      const data = response?.data;
      if (data?.length) {
        const customerName = data?.map((item) => item.name);
        setCustomersNames([...customerName]);
        const cutomerValues = data?.map((item) => item.value);
        setCustomersValues([...cutomerValues]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error);
    }
  };

  const hangleRevenueGraph = async (val: number) => {
    const body = {
      filter_type: val,
    };
    try {
      const response = await getRevenueGraphdata(body).unwrap();
      const data = response?.data;
      if (data) {
        if (val === 1) {
          setRevenueNames([
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ]);
        } else if (val === 2) {
          setRevenueNames(["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]);
        } else {
          setRevenueNames(["W1", "W2", "W3", "W4"]);
        }

        const revenueValues = Object.values(data);

        setRevenueValues([...revenueValues]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error);
    }
  };

  useEffect(() => {
    hangleUserGraph(customerFilter);
    hangleRevenueGraph(revenueFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="module-container">
      <ContentHeader heading="Analytics" />
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card>
            <div className="detail-module-card">
              <h2>Revenue Generated</h2>
              <div style={{ width: "20%" }}>
                <Select
                  className="analytics_card"
                  value={revenueFilter}
                  onChange={(val) => {
                    setRevenueFilter(val);
                    hangleRevenueGraph(val);
                  }}
                  placeholder="Filter"
                  options={[
                    { value: 2, label: "Weekly" },
                    { value: 1, label: "Yearly" },
                    { value: 3, label: "Monthly" },
                  ]}
                />
              </div>
            </div>
            <div style={{ margin: "auto" }}>
              <LineChart data={graphData} />
            </div>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card>
            <div className="detail-module-card">
              <h2>Total Customers</h2>
              <div style={{ width: "20%" }}>
                <Select
                  className="analytics_card"
                  // style={{ height: 75, width: "100%" }}
                  value={customerFilter}
                  onChange={(val: number) => {
                    setCustomerFilter(val);
                    hangleUserGraph(val);
                  }}
                  placeholder="Filter"
                  options={[
                    { value: 1, label: "Weekly" },
                    { value: 2, label: "Yearly" },
                    { value: 3, label: "Monthly" },
                  ]}
                />
              </div>
            </div>
            <div style={{ margin: "auto" }}>
              <LineChart data={graphCustomerData} />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Analytics;
