import { useEffect, useState } from "react";
import { STORAGE_KEYS } from "../../../helpers/constants/storageKeys";
import {
  getFromStorage,
  removeFromStorage,
  setToStorage,
} from "../../../helpers/storage";
import { showError, showToast } from "../../../helpers/toast";
import AuthWrapper from "../../../layout/authWrapper";
import { usePostLoginMutation } from "../../../services/auth";
import "./login.css";
import { Button, Card, Checkbox, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import {
  setCredentials,
  setPermissions,
} from "../../../features/auth/authSlice";
import { useAppDispatch } from "../../../hooks/store";

const Login = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loginMutation, { isLoading }] = usePostLoginMutation();
  const [checked, setChecked] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onFinish = async (values: any) => {
    console.log("Received values of form: ", values);
    try {
      const response = await loginMutation(values).unwrap();
      console.log(response);
      if (response?.code === 200) {
        showToast(response?.message || "");
        setToStorage(STORAGE_KEYS.token, response?.data?.token || "");
        dispatch(
          setCredentials({
            token: response?.data?.token || "",
            user: response?.data,
          })
        );
        if (response?.data?.user_role?.permissions?.length) {
          dispatch(setPermissions(response?.data?.user_role?.permissions));
        }
        if (checked) {
          setToStorage(STORAGE_KEYS.credentials, JSON.stringify(values));
        } else {
          removeFromStorage(STORAGE_KEYS.credentials);
        }
        navigate("/dashboard");
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error);
    }
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }: any) => {
    console.log(
      values,
      errorFields,
      outOfDate,
      "values, errorFields, outOfDate"
    );
  };

  useEffect(() => {
    const credentials = getFromStorage(STORAGE_KEYS.credentials);
    if (credentials) {
      const creds = JSON.parse(credentials as any);
      if (credentials) {
        form.setFieldsValue({
          email: creds?.email,
          password: creds?.password,
        });
        setEmail(creds?.email);
        setPassword(creds?.password);
      }
    }
  }, []);

  return (
    <AuthWrapper>
      <Card className="login_card">
        <div className="login_img">
          <img src="/static/images/logo.png" alt="" />
        </div>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{
            maxWidth: 600,
            marginTop: 50,
          }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter your email",
              },
            ]}
          >
            <Input
              value={email}
              onChange={(val) => setEmail(val.target.value)}
              style={{ width: "400px", height: "50px" }}
              placeholder="Email"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please enter your password." }]}
          >
            <Input.Password
              value={password}
              onChange={(val) => setPassword(val.target.value)}
              style={{ width: "400px", height: "50px", paddingTop: 10 }}
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item valuePropName="checked">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "400px",
              }}
            >
              <Checkbox value={checked} onChange={() => setChecked(!checked)}>
                Remember me
              </Checkbox>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/forgotpassword");
                }}
              >
                Forgot password?
              </div>
            </div>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <div style={{ display: "flex" }}>
              <Button
                style={{
                  background: "#0C163F",
                  width: 200,
                  height: 50,
                  margin: "auto",
                }}
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                Login
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </AuthWrapper>
  );
};

export default Login;
