import { Table, Layout, Select, Space, Button, Modal } from "antd";
import type { ColumnsType } from "antd/es/table";
import "./dashboard.css";
import {
  DollarCircleOutlined,
  CheckCircleOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  UserOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { DashboardCard, PageLoader } from "../../components";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLazyGetDashboardValuesQuery } from "../../services/main";
import { Permissions, ProductMatrix, TableOrder } from "../../types/General";
import { OrderStatus, SideBarIds } from "../../utils/constants";
import {
  useAssignSubAdminToOrderMutation,
  useGetOrdersMutation,
  useUpdateOrderStatusMutation,
} from "../../services/orders";
import { showToast } from "../../helpers/toast";
import useAuth from "../../hooks/useAuth";
import usePermissions from "../../hooks/permissions";
import {
  useGetAssignedQcProductsMutation,
  useLazyGetProductMatrixQuery,
} from "../../services/product";
import { useLazyGetSubAdminListQuery } from "../../services/warehouse";

const { Option } = Select;
const { Content } = Layout;

const Dashboard = () => {
  const user = useAuth();
  const navigate = useNavigate();
  const permissions = usePermissions();

  const [getDashboardValues] = useLazyGetDashboardValuesQuery();
  const [getProductCounts] = useLazyGetProductMatrixQuery();
  const [getOrders] = useGetOrdersMutation();
  const [getProducts, getProductsLoader] = useGetAssignedQcProductsMutation();
  const [updateOrder, updateOrderLoader] = useUpdateOrderStatusMutation();
  const [getSubAdminList] = useLazyGetSubAdminListQuery();
  const [assignSubAdminToOrder, assignSubAdminToOrderLoader] =
    useAssignSubAdminToOrderMutation();

  const [stats, setStats] = useState({
    closed_orders: 0,
    group_sellers: 0,
    individual_sellers: 0,
    new_customers: 0,
    total_customers: 0,
    total_earning: 0,
    total_orders: 0,
    total_sellers: 0,
  });

  const [orders, setOrders] = useState<TableOrder[]>([]);
  console.log(orders[0]?.assigned_sub_admin, "lll");

  const [subAdmins, setSubAdmins] = useState<
    { first_name: string; last_name: string; id: number }[]
  >([]);
  const [productCounts, setProductCounts] = useState<ProductMatrix[]>([]);
  const [products, setProducts] = useState<
    { id: number; product_name: string }[]
  >([]);
  const [modulePermissions, setModulePermissions] = useState<
    Permissions | null | undefined
  >(null);
  const [productModulePermissions, setProductModulePermissions] = useState<
    Permissions | null | undefined
  >(null);

  const [visible, setVisible] = useState(false);
  const [qcName, setQcName] = useState("");

  const toggleModal = () => {
    setVisible(!visible);
  };

  const getAssignedProducts = async (qcId: number, type: number) => {
    try {
      const response = await getProducts({ qc_id: qcId, type }).unwrap();
      if (response?.code === 200) {
        if (response?.data?.qcs?.length) {
          setQcName(response?.data?.qcs[0]?.name || "");
          setProducts(response?.data?.qcs[0]?.products || []);
          toggleModal();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user && user?.role !== 1) {
      if (permissions) {
        let idx = -1;
        idx = permissions?.findIndex(
          (ele) => ele?.module?.id === SideBarIds.orders
        );
        if (idx > -1) {
          setModulePermissions(permissions[idx]);
        }
        let idx2 = -1;
        idx2 = permissions?.findIndex(
          (ele) => ele?.module?.id === SideBarIds.products
        );
        if (idx2 > -1) {
          setProductModulePermissions(permissions[idx2]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, user]);

  const getCounts = async () => {
    try {
      const response = await getDashboardValues({}).unwrap();
      if (response?.code === 200) {
        setStats(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProductMatrix = async () => {
    try {
      const response = await getProductCounts({}).unwrap();
      if (response?.code === 200) {
        setProductCounts(response?.data?.assigned_qc || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubAdmins = async () => {
    try {
      const response = await getSubAdminList({}).unwrap();
      console.log(response);
      if (response?.code === 200) {
        setSubAdmins(response.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOrdersList = async () => {
    try {
      const body = {
        start: 1,
        length: 5,
        product_category: "",
        brand: "",
        id: "",
        start_date: "",
        end_date: "",
        search: {
          value: "",
          regex: false,
        },
      };
      const result = await getOrders(body).unwrap();
      if (result?.code === 200) {
        const flattenedData = result?.data.flatMap((item) =>
          item.ordered_product.map((product) => ({
            ...product,
            user: `${product?.user?.first_name || ""} ${
              product?.user?.last_name || ""
            }`,
            product: product?.product?.product_name || "",
            product_category: product?.product?.product_category?.name || "",
            product_sub_category:
              product.product.product_sub_category.name || "",
            brand: product?.product?.brand?.name || "",
            delivery_status: product?.delivery_status || 0,
            order_id: item?.id || 0,
            product_order_id: product?.id || 0,
            quantity: product?.quantity || 0,
            cost: product?.cost || 0,
            assigned_warehouse:
              product?.assign_warehouse_to_ordered_product || 0,
            assigned_sub_admin: product?.assign_qc_to_ordered_product || 0,
          }))
        );
        setOrders(flattenedData || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const assignSubAdmin = async (id: number, subAdminId: number) => {
    try {
      const body = {
        qc_id: subAdminId,
      };
      const result = await assignSubAdminToOrder({
        body,
        product_order_id: `${id}`,
      }).unwrap();
      if (result?.code === 200) {
        showToast("Sub Admin assigned successfully.");
        let idx = -1;
        idx = orders?.findIndex((ele) => ele?.product_order_id === id);
        if (idx > -1) {
          const updatedData = orders.map((x) => {
            if (x.product_order_id === id) {
              return {
                ...x,
                assigned_sub_admin: subAdminId,
              };
            }
            return x;
          });
          setOrders(updatedData);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateOrderStatus = async (id: number, status: number) => {
    try {
      const body = {
        order_status: status,
      };
      const result = await updateOrder({
        body,
        product_order_id: `${id}`,
      }).unwrap();
      if (result?.code === 200) {
        showToast("Order status updated successfully.");
        let idx = -1;
        idx = orders?.findIndex((ele) => ele?.product_order_id === id);
        if (idx > -1) {
          const updatedData = orders.map((x) => {
            if (x.product_order_id === id) {
              return {
                ...x,
                delivery_status: status,
              };
            }
            return x;
          });
          setOrders(updatedData);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCounts();
    getOrdersList();
    getSubAdmins();
    getProductMatrix();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const STATS = [
    {
      name: "Total Users",
      icon: <TeamOutlined />,
      number: stats?.total_customers || 0,
      prefix: "#",
    },
    {
      name: "Total Orders",
      icon: <ShoppingCartOutlined />,
      number: stats?.total_orders || 0,
      prefix: "#",
    },
    {
      name: "New Users",
      icon: <UsergroupAddOutlined />,
      number: stats?.new_customers || 0,
      prefix: "#",
    },
    {
      name: "Closed Orders",
      icon: <CheckCircleOutlined />,
      number: stats?.closed_orders || 0,
      prefix: "#",
    },
    {
      name: "Total Sellers",
      icon: <ShoppingOutlined />,
      number: stats?.total_sellers || 0,
      prefix: "#",
    },
    {
      name: "Individual Sellers",
      icon: <UserOutlined />,
      number: stats?.individual_sellers || 0,
      prefix: "#",
    },
    {
      name: "Group Sellers",
      icon: <ShopOutlined />,
      number: stats?.group_sellers || 0,
      prefix: "#",
    },
    {
      name: "Total Earning",
      icon: <DollarCircleOutlined />,
      number: stats?.total_earning || 0,
      prefix: "$",
    },
  ];

  const columns: ColumnsType<TableOrder> = [
    {
      title: "S.no.",
      key: "sno",
      render: (value, item, index) => index + 1,
      align: "center",
    },
    {
      title: "Order No.",
      dataIndex: "orderNo",
      key: "orderNo",
      align: "center",
      render: (_, record) => <div>{`#${record?.order_id}`}</div>,
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      align: "center",
    },
    {
      title: "Category",
      dataIndex: "product_category",
      key: "product_category",
      align: "center",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      align: "center",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      align: "center",
      render: (_, record) => <div>{`$${record?.cost}`}</div>,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
    },
    {
      title: "Assign Sub Admin",
      key: "status",
      align: "center",
      className: modulePermissions?.is_edit || user?.role === 1 ? "" : "hidden",
      render: (_, record) => (
        <Select
          className="select-div"
          value={record?.assigned_sub_admin || null}
          onChange={(val) => assignSubAdmin(record?.product_order_id, val)}
          placeholder={"Assign Subadmin"}
        >
          {subAdmins?.map((item, index) => (
            <Option key={index} value={item.id}>
              {`${item?.first_name || ""} ${item?.last_name || ""}`}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Order Status",
      key: "status",
      align: "center",
      className:
        modulePermissions?.is_edit ||
        user?.role === 1 ||
        user?.assigned_warehouse?.length
          ? ""
          : "hidden",
      render: (_, record) => (
        <Select
          className="select-div"
          value={record?.delivery_status || null}
          onChange={(val) => updateOrderStatus(record?.product_order_id, val)}
          placeholder={"Order status"}
        >
          {OrderStatus?.map((item, index) => (
            <Option key={index} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manage-orders/details/${record?.product_order_id}`);
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>
        </Space>
      ),
    },
  ];

  const productColumns: ColumnsType<ProductMatrix> = [
    {
      title: "QC",
      key: "name",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "within 2 hours",
      key: "product_two_hours_ago",
      dataIndex: "product_two_hours_ago",
      align: "center",
      className: "clickable",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            if (record?.product_two_hours_ago) {
              console.log(record, rowIndex);
              getAssignedProducts(record?.id, 1);
            }
          },
        };
      },
    },
    {
      title: "within 4 hours",
      key: "product_four_hours_ago",
      dataIndex: "product_four_hours_ago",
      align: "center",
      className: "clickable",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            if (record?.product_four_hours_ago) {
              console.log(record, rowIndex);
              getAssignedProducts(record?.id, 2);
            }
          },
        };
      },
    },
    {
      title: "within 8 hours",
      key: "product_eight_hours_ago",
      dataIndex: "product_eight_hours_ago",
      align: "center",
      className: "clickable",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            if (record?.product_eight_hours_ago) {
              console.log(record, rowIndex);
              getAssignedProducts(record?.id, 3);
            }
          },
        };
      },
    },
    {
      title: "within 12 hours",
      key: "product_twelve_hours_ago",
      dataIndex: "product_twelve_hours_ago",
      align: "center",
      className: "clickable",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            if (record?.product_twelve_hours_ago) {
              console.log(record, rowIndex);
              getAssignedProducts(record?.id, 4);
            }
          },
        };
      },
    },
    {
      title: "more than 1 day",
      key: "product_one_day_ago",
      dataIndex: "product_one_day_ago",
      align: "center",
      className: "clickable",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            if (record?.product_one_day_ago) {
              console.log(record, rowIndex);
              getAssignedProducts(record?.id, 5);
            }
          },
        };
      },
    },
    {
      title: "Total",
      key: "product",
      dataIndex: "product",
      align: "center",
    },
  ];

  return (
    <Content className="dashboard_container">
      <div className="dash_head">
        <h1>Dashboard</h1>
      </div>
      <div className="top_bar">
        {STATS.map((item, index) => (
          <DashboardCard data={item} key={index} />
        ))}
      </div>
      {modulePermissions || user?.role === 1 ? (
        <div style={{ marginBottom: 30 }}>
          <PageLoader
            isLoading={
              updateOrderLoader?.isLoading ||
              assignSubAdminToOrderLoader?.isLoading ||
              getProductsLoader?.isLoading
            }
          />
          <div>
            <h1>Recent Orders</h1>
          </div>
          <Table
            pagination={false}
            columns={columns}
            key={"id"}
            dataSource={orders || []}
            className="dashbord_table"
          />
        </div>
      ) : null}
      {productModulePermissions || user?.role === 1 ? (
        <div>
          <div>
            <h1>Assigned Products</h1>
          </div>
          <Table
            pagination={false}
            columns={productColumns}
            key={"id"}
            dataSource={productCounts || []}
            className="dashbord_table"
          />
        </div>
      ) : null}
      <Modal
        open={visible}
        title={`Products assigned to ${qcName}`}
        onCancel={toggleModal}
        footer={false}
      >
        <div style={{ width: "100%", marginTop: 20, marginBottom: 20 }}>
          {products?.map((item, index) => (
            <div key={index}>{`${index + 1}. ${item?.product_name || ""}`}</div>
          ))}
        </div>
      </Modal>
    </Content>
  );
};

export default Dashboard;
