import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { removeFromStorage } from "../../helpers/storage";
import { STORAGE_KEYS } from "../../helpers/constants/storageKeys";
import type { User } from "../../types/User";
import { Permissions } from "../../types/General";

export interface AuthState {
  user: User | null;
  token: string | number | true | object | null;
  permissions?: Permissions[] | null;
}

const initialState: AuthState = {
  user: null,
  token: null,
  permissions: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuth: (state) => {
      removeFromStorage(STORAGE_KEYS.token);
      state.user = null;
      state.token = null;
    },
    setCredentials: (
      state,
      action: PayloadAction<Pick<AuthState, "user" | "token">>
    ) => {
      state.user = action.payload.user ? action.payload.user : null;
      state.token = action.payload.token ? action.payload.token : null;
    },
    setPermissions: (state, action: PayloadAction<Permissions[]>) => {
      state.permissions = action.payload ? action.payload : null;
    },
  },
});

export const { resetAuth, setCredentials, setPermissions } = authSlice.actions;
export const getCurrentUser = (state: RootState) => state.auth.user;
export const getPermissions = (state: RootState) => state.auth.permissions;
export const getToken = (state: RootState) => state.auth.token;

export default authSlice.reducer;
