/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { EyeOutlined, CheckOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import ProductFilters from "../filters";
import {
  useGetProductMutation,
  useUpdateProductAcceptRejectStatusMutation,
} from "../../../services/product";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Brand, Category, Product } from "../../../types/General";
import { PageLoader, TableLoader } from "../../../components";
import { showError, showToast } from "../../../helpers/toast";

const { confirm } = Modal;

const UnApprovedProducts = ({
  activeTab,
  brands,
  categories,
  setPendingCount,
  setUpdateCount,
}: {
  activeTab: string;
  categories: Category[];
  brands: Brand[];
  setPendingCount: Dispatch<SetStateAction<number>>;
  setUpdateCount: Dispatch<SetStateAction<number>>;
}) => {
  const navigate = useNavigate();
  const [getProducts, { isLoading }] = useGetProductMutation();
  const [updateProductStatus, updateProductStatusLoader] =
    useUpdateProductAcceptRejectStatusMutation();

  const [page, setPage] = useState(1);
  const [products, setProducts] = useState<Product[]>([]);
  const [total, setTotal] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const [selectedBrand, setSelectedBrand] = useState<number | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [productId, setProductId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const getUnApprovedProducts = async () => {
    const body = {
      start: page,
      length: 10,
      is_unapproved: true,
      product_category: selectedCategory ? `${selectedCategory}` : "",
      brand: selectedBrand ? `${selectedBrand}` : "",
      id: productId || "",
      start_date: startDate || "",
      end_date: endDate || "",
      search: {
        value: debouncedSearchTerm.trim(),
        regex: false,
      },
    };
    try {
      const result = await getProducts(body).unwrap();
      console.log(result);
      if (result?.code === 200) {
        setProducts(result?.data || []);
        setTotal(result?.recordsTotal || 1);
        setPendingCount(result?.pending_obj_count || 0);
        setUpdateCount(result?.requested_obj_count || 0);
      } else {
        setProducts([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleAcceptProductStatus = async (productId: number) => {
    try {
      const body = {
        is_approved: true,
      };
      const response = await updateProductStatus({
        product_id: `${productId}`,
        body,
      }).unwrap();
      console.log(response);
      if (response?.code === 200) {
        showToast("Product accepted");
        getUnApprovedProducts();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showDeleteConfirm = (id: number) => {
    confirm({
      title: "Hold on",
      content: "Are you sure you want to approve this product?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onCancel() {
        console.log("Cancel");
      },
      onOk() {
        handleAcceptProductStatus(id);
        console.log("OK", id);
      },
    });
  };

  const showIncompleteForm = (id: number) => {
    confirm({
      title: "Alert",
      content:
        "There are some fields in the form which are not completed by the user. Do you wish to fill them?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onCancel() {
        console.log("Cancel");
      },
      onOk() {
        navigate(`/manage-products/${id}`);
        console.log("OK", id);
      },
    });
  };

  useEffect(() => {
    if (activeTab === "4") {
      getUnApprovedProducts();
    }
  }, [page, debouncedSearchTerm, activeTab]);

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [searchTerm]);

  const columns: ColumnsType<Product> = [
    {
      title: "S.no.",
      key: "sno",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
      align: "center",
    },
    {
      title: "Product Id",
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      render: (_, record) => (
        <div>
          {record?.by_company && typeof record?.by_company !== "number"
            ? record?.by_company?.name || ""
            : ""}
        </div>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (_, record) => (
        <div>
          {record?.product_category &&
          typeof record?.product_category !== "number"
            ? record?.product_category?.name || "other"
            : "other"}
        </div>
      ),
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      render: (_, record) => (
        <div>
          {record?.brand && typeof record?.brand !== "number"
            ? record?.brand?.name || "other"
            : "other"}
        </div>
      ),
    },
    {
      title: "Country of Origin",
      dataIndex: "country_of_origin",
      key: "country_of_origin",
      render: (_, record) => (
        <div>
          {record?.country_of_origin &&
          typeof record?.country_of_origin !== "number"
            ? record?.country_of_origin.name
            : ""}
        </div>
      ),
    },
    {
      title: "Price",
      dataIndex: "product_cost",
      key: "product_cost",
      align: "center",
      render: (_, record) => <div>{`$${record?.product_cost || ""}`}</div>,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              window.open(
                `/manage-products/${record?.id}/${activeTab}`,
                "_blank"
              );
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>
        </Space>
      ),
    },
    {
      title: "Approve",
      key: "approve",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              if (
                !record?.brand ||
                !record?.product_category ||
                !record?.product_sub_category ||
                !record?.commission
              ) {
                showIncompleteForm(record?.id);
                return;
              }
              if (!record?.assign_qc) {
                showError("Please assign a QC, before approving this product");
                return;
              }
              showDeleteConfirm(record?.id);
            }}
          >
            <CheckOutlined style={{ color: "#52c41a" }} />
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <PageLoader isLoading={updateProductStatusLoader?.isLoading} />
      <div className="srch">
        <ProductFilters
          brands={brands}
          categories={categories}
          selectedBrand={selectedBrand}
          setSelectedBrand={setSelectedBrand}
          selectedCategory={selectedCategory}
          endDate={endDate}
          setSelectedCategory={setSelectedCategory}
          productId={productId}
          setProductId={setProductId}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        {isLoading ? (
          <TableLoader />
        ) : (
          <Table
            className="table-container"
            columns={columns}
            dataSource={products || []}
            pagination={{
              defaultPageSize: 10,
              current: page,
              onChange: onPageChange,
              total,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default UnApprovedProducts;
