import { CloseCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select, Upload } from "antd";
import { Document, Page, pdfjs } from "react-pdf";
import { useEffect, useState } from "react";
import {
  isAlphanumeric,
  isFloat,
  isNumber,
  isValidInput,
} from "../../../utils/validations";
import {
  useGetBrandsMutation,
  useGetCategoriesMutation,
  useLazyGetSubCategoryByCategoryIdQuery,
} from "../../../services/main";

import { Brand, Category, SubCategory } from "../../../types/General";
import ProductImages from "./ProductImages";
import { RcFile } from "antd/es/upload";
import { PageLoader } from "../../../components";
import { UploadDocument, UploadMedia } from "../../../utils/imageUpload";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { showError, showToast } from "../../../helpers/toast";
import {
  useAddProductMutation,
  useGetCountriesMutation,
  useLazyGetProductByIdQuery,
  useLazyGetQcListQuery,
  useLazyGetRequestedProductByIdQuery,
  useLazyGetScListQuery,
  useUpdateProductMutation,
} from "../../../services/product";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const { Option } = Select;
const { TextArea } = Input;

const FormData = (props: any) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id, tab } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const param = queryParams.get("type");

  const [getCategoryList] = useGetCategoriesMutation();
  const [getBrandList] = useGetBrandsMutation();
  const [getQcsList] = useLazyGetQcListQuery();
  const [getScsList] = useLazyGetScListQuery();
  const [getSubCategoryList] = useLazyGetSubCategoryByCategoryIdQuery();
  const [getCountriesList] = useGetCountriesMutation();
  const [addProduct, AddProps] = useAddProductMutation();
  const [updateProduct, UpdateProps] = useUpdateProductMutation();
  const [getProductById] = useLazyGetProductByIdQuery();
  const [getUpdatedProductById] = useLazyGetRequestedProductByIdQuery();

  const [imageError, setImageError] = useState(false);
  const [type, setType] = useState("percentage");
  const [tokenType, setTokenType] = useState("percentage");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [approvedCategories, setApprovedCategories] = useState<Category[]>([]);
  const [approvedBrands, setApprovedBrands] = useState<Brand[]>([]);
  const [approvedSubCategories, setApprovedSubCategories] = useState<
    SubCategory[]
  >([]);
  const [countries, setCountries] = useState<{ id: number; name: string }[]>(
    []
  );
  const [qcs, setQcs] = useState<
    { first_name: string; last_name: string; id: number }[]
  >([]);
  const [scs, setScs] = useState<
    { first_name: string; last_name: string; id: number }[]
  >([]);

  const [imageObject, setImageObject] = useState<RcFile[] | number[]>([]);
  const [imagePreview, setImagePreview] = useState<string[]>([]);

  const [documentObject, setDocumentObject] = useState<RcFile | number | null>(
    null
  );
  const [documentPreview, setDocumentPreview] = useState([]);

  const [productDetail, setProductDetail] = useState({
    name: "",
    shortDescription: "",
    category: null,
    subCategory: null,
    brand: null,
    materialDescription: "",
    cost: "",
    stock: "",
    keyDimensions: "",
    remarks: "",
    materialGrade: "",
    constructionMaterial: "",
    unit: "",
    country: null,
    nance: null,
    assign_qc: null,
    assign_sales_consultant: null,
    commissionType: 1,
    commission: "",
    minimumCommission: "",
    maximumCommission: "",
    productTokenAmount: "",
    token_type: 1,
  });

  const handleImageUpload = async (file: RcFile) => {
    try {
      const res = await UploadMedia(file);
      if (res?.code === 200) {
        return res?.data[0]?.id;
      }
      return 0;
    } catch (error: any) {
      console.log(error);
      return 0;
    }
  };

  const handleDocumentUpload = async (file: RcFile) => {
    try {
      const res = await UploadDocument(file);
      if (res?.code === 200) {
        return res?.data[0]?.id;
      }
      return 0;
    } catch (error: any) {
      console.log(error);
      return 0;
    }
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onFinish = async (values: any) => {
    if (!imageObject?.length) {
      setImageError(true);
      showError("Please provide a product image.");
      const ele = document.getElementById("product_img_uploader");
      ele?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    let product_certificate_image = null;
    if (documentObject && typeof documentObject !== "number") {
      product_certificate_image = await handleDocumentUpload(documentObject);
    } else {
      product_certificate_image = documentObject;
    }
    let images = [] as any;
    if (imageObject?.length) {
      await Promise.all(
        imageObject?.map(async (item) => {
          if (typeof item !== "number") {
            let result = await handleImageUpload(item);
            console.log(result);
            images?.push({
              image: result,
            });
          } else {
            images?.push({
              image: item,
            });
          }
        })
      );
    }

    const body = {
      product_name: `${productDetail.name}:::`,
      short_description: `${productDetail.shortDescription}:::`,
      product_category: productDetail.category,
      product_sub_category: productDetail.subCategory,
      detailed_description: `${productDetail.materialDescription}:::`,
      product_cost: `${productDetail.cost}:::`,
      brand: productDetail.brand,
      assign_qc: productDetail.assign_qc,
      assign_sales_consultant: productDetail.assign_sales_consultant,
      product_stock: `${productDetail.stock}:::`,
      product_key_dimension: `${productDetail.keyDimensions}:::`,
      remark: `${productDetail.remarks}:::`,
      material_grade: `${productDetail.materialGrade}:::`,
      material_of_construction: `${productDetail.constructionMaterial}:::`,
      product_unit: `${productDetail.unit}:::`,
      country_of_origin: productDetail.country,
      nance: !!productDetail.nance,
      product_certificate_image,
      token_type: tokenType === "percentage" ? 1 : 2,
      product_token_amount: productDetail.productTokenAmount,
      commission_type: type === "percentage" ? 1 : 2,
      commission: productDetail.commission,
      minimum_commission: productDetail?.minimumCommission || null,
      maximum_commission: productDetail?.maximumCommission || null,
      by_company: 1,
      product_images: images?.length ? images : null,
    };
    try {
      let response = null;
      if (id && id !== "add") {
        response = await updateProduct({ product_id: `${id}`, body }).unwrap();
      } else {
        response = await addProduct(body).unwrap();
      }
      console.log(response);
      if (response?.code === 200 || response?.code === 201) {
        showToast(response?.message || "");
        navigate("/manage-products", {
          replace: true,
          state: {
            tab: tab || "1",
          },
        });
      }
    } catch (error: any) {
      console.log(error);
      showError(error?.data?.message || "");
    }
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }: any) => {
    console.log(
      values,
      errorFields,
      outOfDate,
      "values, errorFields, outOfDate"
    );
  };

  const getBrands = async () => {
    try {
      const body = {
        start: 1,
        length: 50,
        is_active: true,
        search: {
          value: "",
          regex: false,
        },
      };
      const response = await getBrandList({ body: body }).unwrap();
      console.log(response);
      if (response?.code === 200) {
        setApprovedBrands(response.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubCategoryByCategory = async (category_id: null | number) => {
    console.log("category_id: ", category_id);
    try {
      const response = await getSubCategoryList({
        category_id: `${category_id}`,
      }).unwrap();
      console.log(response);
      if (response?.code === 200) {
        setApprovedSubCategories(response.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCategories = async () => {
    try {
      const body = {
        start: 1,
        length: 10,
        is_active: true,
        search: {
          value: "",
          regex: false,
        },
      };
      const response = await getCategoryList({
        body,
      }).unwrap();
      console.log(response);
      if (response?.code === 200) {
        setApprovedCategories(response.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCountries = async () => {
    try {
      const body = {
        start: 1,
        length: 200,
        search: {
          value: debouncedSearchTerm,
          regex: false,
        },
      };
      const response = await getCountriesList(body).unwrap();
      console.log(response);
      if (response?.code === 200) {
        setCountries(response.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getQcList = async () => {
    try {
      const response = await getQcsList({}).unwrap();
      console.log(response);
      if (response?.code === 200) {
        setQcs(response?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getScList = async () => {
    try {
      const response = await getScsList({}).unwrap();
      console.log(response);
      if (response?.code === 200) {
        setScs(response?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpload = (File: RcFile) => {
    setDocumentObject(File);
    const result = window.URL.createObjectURL(File);
    setDocumentPreview([
      {
        url: result,
      },
    ] as any);
  };

  const handleSearch = (val: string) => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(val);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  };

  const getProductDetails = async (id: string) => {
    let response = null;
    if (param === "unapproved") {
      response = await getUpdatedProductById({ product_id: id }).unwrap();
    } else {
      response = await getProductById({ product_id: id }).unwrap();
    }
    if (response?.code === 200) {
      if (response?.data) {
        const data = response?.data;

        if ((data?.product_category as any)?.id) {
          getSubCategoryByCategory((data?.product_category as any)?.id);
        }
        if (data?.product_images?.length) {
          let images = data?.product_images?.map(
            (item: { image: any }) => (item?.image as any)?.media_file_url
          );
          if (images?.length) {
            setImagePreview(images);
          }
          let imageIds = data?.product_images?.map(
            (item: { image: any }) => (item?.image as any)?.id
          );
          if (images?.length) {
            setImagePreview(images);
          }
          if (imageIds?.length) {
            setImageObject(imageIds);
          }
        }

        if (data?.product_certificate_image) {
          setDocumentPreview([
            {
              url: (
                data?.product_certificate_image as any
              )?.media_file_url?.replace("http", "https"),
            },
          ] as any);
          setDocumentObject((data?.product_certificate_image as any)?.id);
        }

        if (data?.commission_type === 1) {
          setType("percentage");
        } else {
          setType("flat");
        }

        if (data?.token_type === 1) {
          setTokenType("percentage");
        } else {
          setTokenType("flat");
        }

        setProductDetail({
          ...productDetail,
          name: data?.product_name || "",
          shortDescription: data?.short_description || "",
          category: (data?.product_category &&
          typeof data?.product_category !== "number"
            ? data?.product_category.id
            : null) as any,
          subCategory: (data?.product_sub_category &&
          typeof data?.product_sub_category !== "number"
            ? data?.product_sub_category.id
            : null) as any,
          brand: (data?.brand && typeof data?.brand !== "number"
            ? data?.brand.id
            : null) as any,
          materialDescription: data?.detailed_description || "",
          cost: `${data?.product_cost}` || "",
          stock: data?.product_stock || "",
          keyDimensions: data?.product_key_dimension || "",
          remarks: data?.remark || "",
          materialGrade: data?.material_grade || "",
          constructionMaterial: data?.material_of_construction || "",
          unit: data?.product_unit || "",
          country: (data?.country_of_origin &&
          typeof data?.country_of_origin !== "number"
            ? data?.country_of_origin.id
            : null) as any,
          nance: (data?.nance ? data?.nance : null) as any,
          commissionType: data?.commission_type,
          commission: `${data?.commission || ""}`,
          token_type: data?.token_type,
          productTokenAmount: `${data?.product_token_amount || ""}`,
          maximumCommission: `${data?.maximum_commission || ""}`,
          minimumCommission: `${data?.minimum_commission || ""}`,
          assign_qc: data?.assign_qc?.id as any,
          assign_sales_consultant: data?.assign_sales_consultant?.id as any,
        });
        form.setFieldsValue({
          productName: data?.product_name || "",
          shortDescription: data?.short_description || "",
          category:
            data?.product_category && typeof data?.product_category !== "number"
              ? data?.product_category.id
              : "",
          subCategory: (data?.product_sub_category &&
          typeof data?.product_sub_category !== "number"
            ? data?.product_sub_category.id
            : null) as any,
          brand: (data?.brand && typeof data?.brand !== "number"
            ? data?.brand.id
            : null) as any,
          productMaterial: data?.detailed_description || "",
          cost: `${data?.product_cost}` || "",
          stock: data?.product_stock || "",
          dimensions: data?.product_key_dimension || "",
          remarks: data?.remark || "",
          gradeOfMaterial: data?.material_grade || "",
          constructionMaterial: data?.material_of_construction || "",
          unit: data?.product_unit || "",
          country: (data?.country_of_origin &&
          typeof data?.country_of_origin !== "number"
            ? data?.country_of_origin.id
            : null) as any,
          nance: (data?.nance ? data?.nance : null) as any,
          commissionType: data?.commission_type,
          commission: `${data?.commission || ""}`,
          tokenType: data?.token_type,
          productTokenAmount: `${data?.product_token_amount || ""}`,
          maximumCommission: `${data?.maximum_commission || ""}`,
          minimumCommission: `${data?.minimum_commission || ""}`,
        });
      }
    }
  };

  const handleImageDelete = (index: number) => {
    let previewImages = imagePreview.filter((_, i) => i !== index);
    setImagePreview(previewImages);
    let objectImages = (imageObject as any).filter(
      (_: any, i: number) => i !== index
    );
    setImageObject(objectImages as any);
  };

  useEffect(() => {
    if (id && id !== "add") {
      getProductDetails(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    getCategories();
    getBrands();
    getQcList();
    getScList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <div>
      <PageLoader isLoading={AddProps.isLoading || UpdateProps.isLoading} />
      <Form
        name="basic"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row gutter={16}>
          <div className="product_div" id="product_img_uploader">
            {imagePreview?.map((item, index) => (
              <div
                key={index}
                onClick={() => handleImageDelete(index)}
                className="img_contnr"
              >
                <CloseCircleOutlined
                  style={{
                    color: "red",
                    position: "absolute",
                    right: 4,
                    top: 4,
                    fontSize: 20,
                    cursor: "pointer",
                  }}
                />

                <figure>
                  <img alt="" src={item} />
                </figure>
              </div>
            ))}

            {imagePreview?.length < 6 ? (
              <div className="img_contnr2">
                <ProductImages
                  imageError={imageError}
                  setImageError={setImageError}
                  imageObject={imageObject}
                  setImageObject={setImageObject}
                  imagePreview={imagePreview}
                  setImagePreview={setImagePreview}
                />
              </div>
            ) : null}
          </div>
        </Row>
        <Row gutter={16}>
          <Col xs={24} md={8}>
            <Form.Item
              name="productName"
              rules={[
                {
                  required: true,
                  message: "Please enter product name.",
                },
              ]}
            >
              <div>
                <h4>Name</h4>
                <Input
                  value={productDetail.name}
                  onChange={(val) => {
                    if (isAlphanumeric(val.target.value)) {
                      setProductDetail({
                        ...productDetail,
                        name: val.target.value,
                      });
                    }
                  }}
                  placeholder="Name"
                  style={{ height: 55 }}
                />
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="category"
              rules={[
                {
                  required: true,
                  message: "Please select a category.",
                },
              ]}
            >
              <div>
                <h4>Category</h4>
                <Select
                  style={{ width: "100%" }}
                  placeholder={"Choose Category"}
                  value={productDetail.category}
                  onChange={(value) => {
                    setProductDetail({
                      ...productDetail,
                      category: value,
                    });
                    getSubCategoryByCategory(value);
                    form.setFieldsValue({
                      category: value,
                    });
                    form.validateFields(["category"]);
                  }}
                >
                  {approvedCategories?.map((item, index) => (
                    <Option value={item?.id} key={index}>
                      {item?.name || ""}
                    </Option>
                  ))}
                </Select>
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="subCategory"
              rules={[
                {
                  required: true,
                  message: "Please select a sub-category.",
                },
              ]}
            >
              <div>
                <h4>Sub-Category</h4>
                <Select
                  style={{ width: "100%" }}
                  placeholder={"Choose sub-category"}
                  value={productDetail.subCategory}
                  onChange={(value) => {
                    setProductDetail({
                      ...productDetail,
                      subCategory: value,
                    });
                    form.setFieldsValue({
                      subCategory: value,
                    });
                    form.validateFields(["subCategory"]);
                  }}
                >
                  {approvedSubCategories?.map((item, index) => (
                    <Option value={item?.id} key={index}>
                      {item?.name || ""}
                    </Option>
                  ))}
                </Select>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} md={8}>
            <Form.Item
              name="brand"
              rules={[
                {
                  required: true,
                  message: "Please select a brand.",
                },
              ]}
            >
              <div>
                <h4>Brand</h4>
                <Select
                  style={{ width: "100%" }}
                  placeholder={"Choose Brand"}
                  value={productDetail.brand}
                  onChange={(value) => {
                    setProductDetail({
                      ...productDetail,
                      brand: value,
                    });
                    form.setFieldsValue({
                      brand: value,
                    });
                    form.validateFields(["brand"]);
                  }}
                >
                  {approvedBrands?.map((item, index) => (
                    <Option value={item?.id} key={index}>
                      {item?.name || ""}
                    </Option>
                  ))}
                </Select>
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item name="assign_qc">
              <div>
                <h4>Assign QC</h4>
                <Select
                  style={{ width: "100%" }}
                  placeholder={"Assign QC"}
                  value={productDetail.assign_qc}
                  onChange={(value) => {
                    setProductDetail({
                      ...productDetail,
                      assign_qc: value,
                    });
                    // getSubCategoryByCategory(value);
                    // form.setFieldsValue({
                    //   assign_qc: value,
                    // });
                    form.validateFields(["assign_qc"]);
                  }}
                >
                  {qcs?.map((item, index) => (
                    <Option value={item?.id} key={index}>
                      {`${item?.first_name ? item?.first_name : ""} ${
                        item?.last_name ? item?.last_name : ""
                      }`}
                    </Option>
                  ))}
                </Select>
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item name="assign_sales_consultant">
              <div>
                <h4>Assign Sales Consultant</h4>
                <Select
                  style={{ width: "100%" }}
                  placeholder={"Choose Assign Sales Consultant"}
                  value={productDetail.assign_sales_consultant}
                  onChange={(value) => {
                    setProductDetail({
                      ...productDetail,
                      assign_sales_consultant: value,
                    });
                    form.setFieldsValue({
                      assign_sales_consultant: value,
                    });
                    form.validateFields(["assign_sales_consultant"]);
                  }}
                >
                  {scs?.map((item, index) => (
                    <Option value={item?.id} key={index}>
                      {`${item?.first_name ? item?.first_name : ""} ${
                        item?.last_name ? item?.last_name : ""
                      }`}
                    </Option>
                  ))}
                </Select>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} md={24}>
            <Form.Item
              name="shortDescription"
              rules={[
                {
                  required: true,
                  message: "Please enter short description about the product.",
                },
                {
                  min: productDetail?.shortDescription?.length ? 1 : 2,
                  message: "Please enter short description about the product.",
                },
              ]}
            >
              <div>
                <h4>Short Description</h4>
                <Input
                  value={productDetail.shortDescription}
                  onChange={(val) => {
                    if (isValidInput(val.target.value)) {
                      setProductDetail({
                        ...productDetail,
                        shortDescription: val.target.value,
                      });
                    }
                  }}
                  placeholder="Short Description"
                  style={{ height: 55 }}
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} md={24}>
            <Form.Item
              name="productMaterial"
              rules={[
                {
                  required: true,
                  message: "Please enter Specification/Material Description.",
                },
                {
                  min: productDetail?.materialDescription?.length ? 1 : 2,
                  message: "Please enter Specification/Material Description.",
                },
              ]}
            >
              <div>
                <h4>Specification/Material Description</h4>
                <TextArea
                  value={productDetail.materialDescription}
                  onChange={(value) => {
                    if (isValidInput(value.target.value)) {
                      setProductDetail({
                        ...productDetail,
                        materialDescription: value.target.value,
                      });
                    }
                  }}
                  rows={10}
                  placeholder="Specification/Material Description"
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} md={8}>
            <Form.Item
              name="cost"
              rules={[
                {
                  required: true,
                  message: "Please enter cost.",
                },
                {
                  min: productDetail?.cost?.length ? 1 : 2,
                  message: "Please enter cost.",
                },
              ]}
            >
              <div>
                <h4>Cost</h4>
                <Input
                  value={productDetail.cost}
                  onChange={(val) => {
                    if (isFloat(val.target.value)) {
                      setProductDetail({
                        ...productDetail,
                        cost: val.target.value,
                      });
                    }
                  }}
                  style={{ height: 55 }}
                  placeholder="Cost"
                />
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="stock"
              rules={[
                {
                  required: true,
                  message: "Please enter stock.",
                },
                {
                  min: productDetail?.stock?.length ? 1 : 2,
                  message: "Please enter stock.",
                },
              ]}
            >
              <div>
                <h4>Stock</h4>
                <Input
                  value={productDetail.stock}
                  onChange={(val) => {
                    if (isNumber(val.target.value)) {
                      setProductDetail({
                        ...productDetail,
                        stock: val.target.value,
                      });
                    }
                  }}
                  style={{ height: 55 }}
                  placeholder="Stock"
                />
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="dimensions"
              rules={[
                {
                  required: true,
                  message: "Please enter dimensions.",
                },
                {
                  min: productDetail?.keyDimensions?.length ? 1 : 2,
                  message: "Please enter dimensions.",
                },
              ]}
            >
              <div>
                <h4>Key Dimension</h4>
                <Input
                  value={productDetail.keyDimensions}
                  onChange={(val) => {
                    if (isValidInput(val.target.value)) {
                      setProductDetail({
                        ...productDetail,
                        keyDimensions: val.target.value,
                      });
                    }
                  }}
                  style={{ height: 55 }}
                  placeholder="Key Dimension"
                />
              </div>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} md={8}>
            <Form.Item
              name="remarks"
              rules={[
                {
                  required: true,
                  message: "Please enter remarks.",
                },
                {
                  min: productDetail?.remarks?.length ? 1 : 2,
                  message: "Please enter remarks.",
                },
              ]}
            >
              <div>
                <h4>Remarks</h4>
                <Input
                  value={productDetail.remarks}
                  onChange={(val) => {
                    if (isValidInput(val.target.value)) {
                      setProductDetail({
                        ...productDetail,
                        remarks: val.target.value,
                      });
                    }
                  }}
                  style={{ height: 55 }}
                  placeholder="Remarks"
                />
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="gradeOfMaterial"
              rules={[
                {
                  required: true,
                  message: "Please enter grade of material.",
                },
                {
                  min: productDetail?.materialGrade?.length ? 1 : 2,
                  message: "Please enter grade of material.",
                },
              ]}
            >
              <div>
                <h4>Grade of Material</h4>
                <Input
                  value={productDetail.materialGrade}
                  onChange={(val) => {
                    if (isValidInput(val.target.value)) {
                      setProductDetail({
                        ...productDetail,
                        materialGrade: val.target.value,
                      });
                    }
                  }}
                  style={{ height: 55 }}
                  placeholder="Grade of Material"
                />
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="constructionMaterial"
              rules={[
                {
                  required: true,
                  message: "Please enter material of construction.",
                },
                {
                  min: productDetail?.constructionMaterial?.length ? 1 : 2,
                  message: "Please enter material of construction.",
                },
              ]}
            >
              <div>
                <h4>Material of Construction</h4>
                <Input
                  value={productDetail.constructionMaterial}
                  onChange={(val) => {
                    if (isValidInput(val.target.value)) {
                      setProductDetail({
                        ...productDetail,
                        constructionMaterial: val.target.value,
                      });
                    }
                  }}
                  style={{ height: 55 }}
                  placeholder="Material of Construction"
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} md={8}>
            <Form.Item
              name="unit"
              rules={[
                {
                  required: true,
                  message: "Please enter unit.",
                },
                {
                  min: productDetail?.unit?.length ? 1 : 2,
                  message: "Please enter unit.",
                },
              ]}
            >
              <div>
                <h4>Unit</h4>
                <Input
                  value={productDetail.unit}
                  onChange={(val) => {
                    if (isValidInput(val.target.value)) {
                      setProductDetail({
                        ...productDetail,
                        unit: val.target.value,
                      });
                    }
                  }}
                  style={{ height: 55 }}
                  placeholder="Unit"
                />
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="country"
              rules={[
                {
                  required: true,
                  message: "Please select country of origin.",
                },
              ]}
            >
              <div>
                <h4>Country of Origin</h4>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder={"Country of Origin"}
                  onSearch={handleSearch}
                  value={productDetail.country}
                  filterOption={false}
                  onChange={(value) => {
                    setProductDetail({
                      ...productDetail,
                      country: value,
                    });
                    form.setFieldsValue({
                      country: value,
                    });
                    form.validateFields(["country"]);
                  }}
                  options={countries?.map((d) => ({
                    value: d.id,
                    label: d.name,
                  }))}
                />
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="nance"
              rules={[
                {
                  required: true,
                  message: "Please select nace.",
                },
              ]}
            >
              <div>
                <h4>Nace</h4>
                <Select
                  style={{ width: "100%" }}
                  placeholder={"Nance"}
                  value={productDetail.nance}
                  onChange={(value) => {
                    setProductDetail({
                      ...productDetail,
                      nance: value,
                    });
                    form.setFieldsValue({
                      nance: value,
                    });
                    form.validateFields(["nance"]);
                  }}
                >
                  <Option value={true}>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
              </div>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} md={12}>
            <h4>Test Certificate</h4>
            <Form.Item
              name="upload"
              label="Upload"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              {documentPreview?.length ? (
                <div
                  onClick={() =>
                    window.open(`${(documentPreview[0] as any)?.url || ""}`)
                  }
                  className="pdf_divv"
                  style={{ position: "relative" }}
                >
                  <div
                    className="pdf_cont"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDocumentObject(null);
                      setDocumentPreview([]);
                    }}
                  >
                    <CloseCircleOutlined
                      style={{
                        color: "red",
                        position: "absolute",
                        right: 6,
                        top: 5,
                        fontSize: 20,
                        cursor: "pointer",
                        zIndex: 2,
                      }}
                    />
                  </div>
                  <Document file={documentPreview[0]}>
                    <Page pageNumber={1} />
                  </Document>
                </div>
              ) : (
                <Upload
                  name="logo"
                  beforeUpload={handleUpload}
                  // accept="image/png, image/gif, image/jpeg"
                  accept="application/pdf"
                >
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <h4>Token Amount Type</h4>
            <Select
              value={tokenType}
              style={{ height: 75, width: "100%" }}
              onChange={(val) => {
                setTokenType(val);
                setProductDetail({
                  ...productDetail,
                  productTokenAmount: "",
                });
              }}
              placeholder="Token Amount Type"
              options={[
                { value: "percentage", label: "Percentage" },
                { value: "flat", label: "Flat" },
              ]}
            />
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="productTokenAmount"
              rules={[
                {
                  required: true,
                  message: "Please enter token amount.",
                },
                {
                  min: productDetail?.productTokenAmount?.length ? 1 : 2,
                  message: "Please enter token amount.",
                },
              ]}
            >
              <div>
                <h4>Token Amount</h4>
                <Input
                  value={productDetail.productTokenAmount}
                  maxLength={8}
                  onChange={(val) => {
                    if (isFloat(val.target.value)) {
                      if (
                        tokenType === "percentage" &&
                        (parseFloat(val.target.value) < 100 ||
                          val.target.value === "")
                      ) {
                        setProductDetail({
                          ...productDetail,
                          productTokenAmount: val.target.value,
                        });
                      } else if (tokenType === "flat") {
                        setProductDetail({
                          ...productDetail,
                          productTokenAmount: val.target.value,
                        });
                      }
                    }
                  }}
                  prefix={tokenType === "flat" ? <p>$</p> : null}
                  suffix={tokenType === "percentage" ? <p>%</p> : null}
                  placeholder="Token Amount"
                  style={{ height: 55 }}
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <h4>Commission Type</h4>
            <Select
              value={type}
              style={{ height: 75, width: "100%" }}
              onChange={(val) => {
                setType(val);
                setProductDetail({
                  ...productDetail,
                  commission: "",
                });
              }}
              placeholder="Comission Type"
              options={[
                { value: "percentage", label: "Percentage" },
                { value: "flat", label: "Flat" },
              ]}
            />
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="commission"
              rules={[
                {
                  required: true,
                  message: "Please enter commission.",
                },
                {
                  min: productDetail?.commission?.length ? 1 : 2,
                  message: "Please enter commission.",
                },
              ]}
            >
              <div>
                <h4>Commission</h4>
                <Input
                  value={productDetail.commission}
                  maxLength={8}
                  onChange={(val) => {
                    if (isFloat(val.target.value)) {
                      if (
                        type === "percentage" &&
                        (parseFloat(val.target.value) < 100 ||
                          val.target.value === "")
                      ) {
                        setProductDetail({
                          ...productDetail,
                          commission: val.target.value,
                        });
                      } else if (type === "flat") {
                        setProductDetail({
                          ...productDetail,
                          commission: val.target.value,
                        });
                      }
                    }
                  }}
                  prefix={type === "flat" ? <p>$</p> : null}
                  suffix={type === "percentage" ? <p>%</p> : null}
                  placeholder="Commission"
                  style={{ height: 55 }}
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
        {type === "percentage" ? (
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item
                name="minimumCommission"
                rules={[
                  {
                    required: type === "percentage",
                    message: "Please enter minimum commission.",
                  },
                  {
                    min:
                      type === "percentage"
                        ? productDetail?.minimumCommission?.length
                          ? 1
                          : 2
                        : 0,
                    message: "Please enter minimum commission.",
                  },
                ]}
              >
                <div>
                  <h4>Minimum Commission</h4>
                  <Input
                    value={productDetail.minimumCommission}
                    onChange={(value) => {
                      if (isFloat(value.target.value)) {
                        setProductDetail({
                          ...productDetail,
                          minimumCommission: value.target.value,
                        });
                      }
                    }}
                    prefix={<p>$</p>}
                    placeholder="Minimum Commission"
                    style={{ height: 55 }}
                  />
                </div>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="maximumCommission"
                rules={[
                  {
                    required: type === "percentage",
                    message: "Please enter maximum commission.",
                  },
                  {
                    min:
                      type === "percentage"
                        ? productDetail?.maximumCommission?.length
                          ? 1
                          : 2
                        : 0,
                    message: "Please enter maximum commission.",
                  },
                ]}
              >
                <div>
                  <h4>Maximum Commission</h4>
                  <Input
                    prefix={<p>$</p>}
                    placeholder="Maximum Commission"
                    style={{ height: 55 }}
                    value={productDetail.maximumCommission}
                    onChange={(value) => {
                      if (isFloat(value.target.value)) {
                        setProductDetail({
                          ...productDetail,
                          maximumCommission: value.target.value,
                        });
                      }
                    }}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>
        ) : null}

        <div className="submit-btn-wrapper">
          <Button
            className="submit-btn-container"
            type="primary"
            htmlType="submit"
            loading={AddProps.isLoading || UpdateProps.isLoading}
          >
            {props?.path ? "Save" : "Add"}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default FormData;
