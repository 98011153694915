import { Button, Card, Select, Space, Table } from "antd";
import { ContentHeader, PageLoader, TableLoader } from "../../components";
import { EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import OrdersFilters from "./filters";
import { useEffect, useState } from "react";
import {
  useAssignSubAdminToOrderMutation,
  useGetOrdersMutation,
  useUpdateOrderStatusMutation,
} from "../../services/orders";
import { Brand, Category, TableOrder } from "../../types/General";
import { OrderStatus } from "../../utils/constants";
import { showToast } from "../../helpers/toast";
import {
  useGetBrandsMutation,
  useGetCategoriesMutation,
} from "../../services/main";
import useAuth from "../../hooks/useAuth";
import { useLazyGetSubAdminListQuery } from "../../services/warehouse";

const { Option } = Select;

const Orders = () => {
  const user = useAuth();
  const navigate = useNavigate();

  const [getCategoryList] = useGetCategoriesMutation();
  const [getBrandList] = useGetBrandsMutation();
  const [getOrders, { isLoading }] = useGetOrdersMutation();
  const [getSubAdminList] = useLazyGetSubAdminListQuery();
  const [updateOrder, updateOrderLoader] = useUpdateOrderStatusMutation();
  const [assignSubAdminToOrder, assignSubAdminToOrderLoader] =
    useAssignSubAdminToOrderMutation();

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const [selectedBrand, setSelectedBrand] = useState<number | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [productId, setProductId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [orders, setOrders] = useState<TableOrder[]>([]);
  console.log(orders[0]?.assigned_sub_admin, "lll");

  const [approvedCategories, setApprovedCategories] = useState<Category[]>([]);
  const [approvedBrands, setApprovedBrands] = useState<Brand[]>([]);
  const [subAdmins, setSubAdmins] = useState<
    { first_name: string; last_name: string; id: number }[]
  >([]);

  const getBrands = async () => {
    try {
      const body = {
        start: 1,
        length: 50,
        is_active: true,
        search: {
          value: "",
          regex: false,
        },
      };
      const response = await getBrandList({ body: body }).unwrap();
      console.log(response);
      if (response?.code === 200) {
        setApprovedBrands(response.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCategories = async () => {
    try {
      const body = {
        start: 1,
        length: 50,
        is_active: true,
        search: {
          value: "",
          regex: false,
        },
      };
      const response = await getCategoryList({
        body,
      }).unwrap();
      console.log(response);
      if (response?.code === 200) {
        setApprovedCategories(response.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubAdmins = async () => {
    try {
      const response = await getSubAdminList({}).unwrap();
      console.log(response);
      if (response?.code === 200) {
        setSubAdmins(response.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateOrderStatus = async (id: number, status: number) => {
    try {
      const body = {
        order_status: status,
      };
      const result = await updateOrder({
        body,
        product_order_id: `${id}`,
      }).unwrap();
      if (result?.code === 200) {
        showToast("Order status updated successfully.");
        let idx = -1;
        idx = orders?.findIndex((ele) => ele?.product_order_id === id);
        if (idx > -1) {
          const updatedData = orders.map((x) => {
            if (x.product_order_id === id) {
              return {
                ...x,
                delivery_status: status,
              };
            }
            return x;
          });
          setOrders(updatedData);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const assignSubAdmin = async (id: number, subAdminId: number) => {
    try {
      const body = {
        qc_id: subAdminId,
      };
      const result = await assignSubAdminToOrder({
        body,
        product_order_id: `${id}`,
      }).unwrap();
      if (result?.code === 200) {
        showToast("Sub Admin assigned successfully.");
        let idx = -1;
        idx = orders?.findIndex((ele) => ele?.product_order_id === id);
        if (idx > -1) {
          const updatedData = orders.map((x) => {
            if (x.product_order_id === id) {
              return {
                ...x,
                assigned_sub_admin: subAdminId,
              };
            }
            return x;
          });
          setOrders(updatedData);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns: ColumnsType<TableOrder> = [
    {
      title: "S.no.",
      key: "sno",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
      align: "center",
    },
    {
      title: "Order No.",
      dataIndex: "orderNo",
      key: "orderNo",
      align: "center",
      render: (_, record) => <div>{`#${record?.order_id}`}</div>,
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
    },
    {
      title: "Category",
      dataIndex: "product_category",
      key: "product_category",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      align: "center",
      render: (_, record) => <div>{`$${record?.cost}`}</div>,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
    },
    {
      title: "Assign Sub Admin",
      key: "status",
      align: "center",
      className: user?.role === 1 ? "" : "hidden",
      render: (_, record) => (
        <Select
          className="select-div"
          value={record?.assigned_sub_admin || null}
          onChange={(val) => assignSubAdmin(record?.product_order_id, val)}
          placeholder={"Assign Subadmin"}
        >
          {subAdmins?.map((item, index) => (
            <Option key={index} value={item.id}>
              {`${item?.first_name || ""} ${item?.last_name || ""}`}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Order Status",
      key: "status",
      align: "center",
      render: (_, record) => (
        <Select
          className="select-div"
          value={record?.delivery_status || null}
          onChange={(val) => updateOrderStatus(record?.product_order_id, val)}
          placeholder={"Order status"}
        >
          {OrderStatus?.map((item, index) => (
            <Option key={index} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: "View",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              window.open(
                `/manage-orders/details/${record?.product_order_id}`,
                "blank"
              );
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>
        </Space>
      ),
    },
  ];

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getOrdersList = async () => {
    try {
      const body = {
        start: page,
        length: 10,
        product_category: selectedCategory ? `${selectedCategory}` : "",
        brand: selectedBrand ? `${selectedBrand}` : "",
        id: productId || "",
        start_date: startDate || "",
        end_date: endDate || "",
        search: {
          value: debouncedSearchTerm.trim(),
          regex: false,
        },
      };
      const result = await getOrders(body).unwrap();
      if (result?.code === 200) {
        const flattenedData = result?.data.flatMap((item) =>
          item.ordered_product.map((product) => ({
            ...product,
            user: `${product?.user?.first_name || ""} ${
              product?.user?.last_name || ""
            }`,
            product: product?.product?.product_name || "",
            product_category: product?.product?.product_category?.name || "",
            product_sub_category:
              product.product.product_sub_category.name || "",
            brand: product?.product?.brand?.name || "",
            delivery_status: product?.delivery_status || 0,
            order_id: item?.id || 0,
            product_order_id: product?.id || 0,
            quantity: product?.quantity || 0,
            cost: product?.cost || 0,
            assigned_warehouse:
              product?.assign_warehouse_to_ordered_product || 0,
            assigned_sub_admin: product?.assign_qc_to_ordered_product || 0,
          }))
        );
        setOrders(flattenedData || []);
        setTotal(result?.recordsTotal || 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!approvedCategories?.length) {
      getCategories();
    }
    if (!approvedBrands?.length) {
      getBrands();
    }
    if (!subAdmins?.length) {
      getSubAdmins();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getOrdersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    debouncedSearchTerm,
    selectedBrand,
    selectedCategory,
    productId,
    startDate,
  ]);

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [searchTerm]);

  return (
    <div className="module-container">
      <PageLoader
        isLoading={
          updateOrderLoader?.isLoading || assignSubAdminToOrderLoader?.isLoading
        }
      />
      <ContentHeader heading="Manage Orders" />
      <Card>
        <div className="srch">
          <OrdersFilters
            brands={approvedBrands}
            categories={approvedCategories}
            productId={productId}
            searchTerm={searchTerm}
            selectedBrand={selectedBrand}
            selectedCategory={selectedCategory}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setProductId={setProductId}
            setSearchTerm={setSearchTerm}
            setSelectedBrand={setSelectedBrand}
            setSelectedCategory={setSelectedCategory}
          />
          {isLoading ? (
            <TableLoader />
          ) : (
            <Table
              className="table-container"
              columns={columns}
              dataSource={orders || []}
              pagination={{
                defaultPageSize: 10,
                current: page,
                onChange: onPageChange,
                total,
              }}
            />
          )}
        </div>
      </Card>
    </div>
  );
};

export default Orders;
