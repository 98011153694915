import { Button, Col, Form, Input, Row } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { showError, showToast } from "../../../helpers/toast";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { PageLoader } from "../../../components";
import {
  useLazyGetCompanyDetailsQuery,
  useUpdateCompanyDetailsMutation,
} from "../../../services/company";
import { isValidInput } from "../../../utils/validations";

const FormData = (props: any) => {
  const [form] = Form.useForm();
  const { state } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [getCompanyDetails, { isLoading }] = useLazyGetCompanyDetailsQuery();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [location, setLocation] = useState("");

  const [businessPhoneCode, setBusinessPhoneCode] = useState("+91");
  const [businessCountryName, setBusinessCountryName] = useState("in");
  const [businessPhoneNo, setBusinessPhoneNo] = useState("");

  const [phoneCode, setPhoneCode] = useState("+91");
  const [countryName, setCountryName] = useState("in");
  const [phoneNo, setPhoneNo] = useState("");

  const [updateCompanyDetails] = useUpdateCompanyDetailsMutation();

  const onFinish = async (values: any) => {
    console.log("Received values of form: ", values);
    const body = {
      first_name: firstName,
      last_name: lastName,
      job_title: jobTitle,
      location: location,
      office_country_code: phoneCode?.includes("+")
        ? phoneCode
        : `+${phoneCode}`,
      office_country_iso_code: countryName,
      office_phone_no: phoneNo,
      company_phone_no: businessPhoneNo,
      company_country_iso_code: businessCountryName,
      company_country_code: businessPhoneCode?.includes("+")
        ? businessPhoneCode
        : `+${businessPhoneCode}`,
      email: businessEmail,
      user_company: companyName,
    };
    try {
      const response = await updateCompanyDetails({
        body,
        company_id: id || "",
      }).unwrap();
      console.log(response);
      if (response?.code === 200) {
        showToast(response?.message || "");
        navigate("/manage-companies", {
          replace: true,
          state: { tab: state?.tab || "1" },
        });
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error);
    }
  };

  const getDetails = async (id: string) => {
    try {
      const response = await getCompanyDetails({ id }).unwrap();
      console.log(response);
      if (response?.code === 200) {
        const data = response?.data;
        if (data) {
          setFirstName(data?.first_name || "");
          setLastName(data?.last_name || "");
          setCompanyName(data?.user_company || "");
          setBusinessEmail(data?.email || "");
          setBusinessPhoneNo(data?.company_phone_no || "");
          setBusinessCountryName(data?.company_country_iso_code || "");
          setBusinessPhoneCode(data?.company_country_code || "");
          setJobTitle(data?.job_title || "");
          setPhoneNo(data?.office_phone_no || "");
          setPhoneCode(data?.office_country_code || "");
          setCountryName(data?.office_country_iso_code || "");
          setLocation(data?.location || "");
          form.setFieldsValue({
            first_name: data?.first_name || "",
            business_phone: data?.company_phone_no || "",
            business_email: data?.email || "",
            company_name: data?.user_company || "",
            job_title: data?.job_title || "",
            location: data?.location || "",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      getDetails(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onFinishFailed = ({ values, errorFields, outOfDate }: any) => {
    console.log(
      values,
      errorFields,
      outOfDate,
      "values, errorFields, outOfDate"
    );
  };

  const handleChangeBusinessPhone = (phone: any, country: any) => {
    setBusinessPhoneCode(`+${country?.dialCode}`);
    setBusinessPhoneNo(phone?.replace(country.dialCode, ""));
    setBusinessCountryName(country?.countryCode);
  };

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(`+${country?.dialCode}`);
    setPhoneNo(phone?.replace(country.dialCode, ""));
    setCountryName(country?.countryCode);
  };

  return (
    <div>
      <PageLoader isLoading={isLoading} />
      <Form
        name="basic"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "Please enter first name.",
                },
              ]}
            >
              <div>
                <h4>First Name</h4>
                <Input
                  value={firstName}
                  onChange={(val) => {
                    setFirstName(val.target.value);
                  }}
                  placeholder="First Name"
                  style={{ height: 55 }}
                />
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name="last_name">
              <div>
                <h4>Last Name</h4>
                <Input
                  value={lastName}
                  onChange={(val) => setLastName(val.target.value)}
                  placeholder="Last Name"
                  style={{ height: 55 }}
                />
              </div>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="business_email"
              rules={[
                {
                  required: true,
                  message: "Please enter email.",
                },
              ]}
            >
              <div>
                <h4>Business Email</h4>
                <Input
                  value={businessEmail}
                  onChange={(val) => setBusinessEmail(val.target.value)}
                  placeholder="Business Email"
                  style={{ height: 55 }}
                />
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="business_phone"
              rules={[
                {
                  required: true,
                  message: "Please input business phone number.",
                },
              ]}
            >
              <div>
                <h4>Company Phone Number</h4>
                <div className="phn_cs">
                  <PhoneInput
                    value={businessPhoneCode + businessPhoneNo}
                    enableSearch={true}
                    placeholder="Company Phone number"
                    containerClass={"cntn_telCls"}
                    inputClass={"cstm_cls"}
                    buttonClass={"custm_btn"}
                    onChange={(phone, country) =>
                      handleChangeBusinessPhone(phone, country)
                    }
                  />
                </div>
              </div>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="job_title"
              rules={[
                {
                  required: true,
                  message: "Please enter job title.",
                },
              ]}
            >
              <div>
                <h4>Job Title</h4>
                <Input
                  value={jobTitle}
                  onChange={(val) => setJobTitle(val.target.value)}
                  placeholder="Job Title"
                  style={{ height: 55 }}
                />
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="company_name"
              rules={[
                {
                  required: true,
                  message: "Please input company name.",
                },
                {
                  min: companyName?.length ? 1 : 2,
                  message: "Please input company name.",
                },
              ]}
            >
              <div>
                <h4>Company Name</h4>
                <Input
                  value={companyName}
                  onChange={(val) => {
                    if (isValidInput(val.target.value)) {
                      setCompanyName(val.target.value);
                    }
                  }}
                  placeholder="Company Name"
                  style={{ height: 55 }}
                />
              </div>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item name="phone_number">
              <div>
                <h4>Phone Number</h4>
                <div className="phn_cs">
                  <PhoneInput
                    value={phoneCode + phoneNo}
                    enableSearch={true}
                    placeholder="Phone number"
                    containerClass={"cntn_telCls"}
                    inputClass={"cstm_cls"}
                    buttonClass={"custm_btn"}
                    onChange={(phone, country) =>
                      handleChangePhone(phone, country)
                    }
                  />
                </div>
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name="location">
              <div>
                <h4>Location</h4>
                <Input
                  value={location}
                  onChange={(val) => {
                    if (isValidInput(val.target.value)) {
                      setLocation(val.target.value);
                    }
                  }}
                  placeholder="Location"
                  style={{ height: 55 }}
                />
              </div>
            </Form.Item>
          </Col>
        </Row>

        <div className="submit-btn-wrapper">
          <Button
            className="submit-btn-container"
            type="primary"
            htmlType="submit"
          >
            {"Save"}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default FormData;
