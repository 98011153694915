import { toast } from "react-toastify";

export const showToast = (message: string) => {
  toast.dismiss();
  toast.success(message);
};

export const showWarning = (message: string) => {
  toast.dismiss();
  toast.warning(message);
};

export const showError = (message: string) => {
  toast.dismiss();
  toast.error(message);
};
