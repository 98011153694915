import { CloseCircleOutlined } from "@ant-design/icons";

const ImageViewer = ({
  source,
  resetImage,
  squared = false,
}: {
  source: string;
  squared?: boolean;
  resetImage: () => void;
}) => {
  return (
    <div style={{ position: "relative", width: "fit-content" }}>
      <div onClick={resetImage}>
        <CloseCircleOutlined
          style={{
            color: "red",
            position: "absolute",
            right: 5,
            top: 5,
            fontSize: 20,
            cursor: "pointer",
          }}
        />
      </div>
      <figure
        style={{
          height: squared ? "150px" : "110px",
          width: squared ? "150px" : "110px",
          border: "1px solid black",
          borderRadius: squared ? "10%" : "100%",
          overflow: "hidden",
        }}
      >
        <img src={source} alt="" />
      </figure>
    </div>
  );
};

export default ImageViewer;
