/* eslint-disable react-hooks/exhaustive-deps */
import { Badge, Card, Tabs } from "antd";
import { ContentHeader } from "../../components";
import { useEffect, useState } from "react";
import {
  ApprovedCompanies,
  PendingCompanies,
  UnApprovedCompanies,
} from "./types";
import { Permissions } from "../../types/General";
import { SideBarIds } from "../../utils/constants";
import useAuth from "../../hooks/useAuth";
import { useLocation, useNavigate } from "react-router-dom";
import usePermissions from "../../hooks/permissions";
import GroupedCompanies from "./types/Group";

const { TabPane } = Tabs;

const ManageCompanies = () => {
  const user = useAuth();
  const navigate = useNavigate();
  const permissions = usePermissions();
  const { state } = useLocation();

  const [activeTab, setActiveTab] = useState("1");
  const [pendingCount, setPendingCount] = useState(0);
  const [modulePermissions, setModulePermissions] = useState<
    Permissions | null | undefined
  >(null);

  useEffect(() => {
    console.log({ state });
    if (state) {
      if (state?.tab) {
        setActiveTab(state?.tab);
      }
    }
  }, [state]);

  useEffect(() => {
    if (user && user?.role !== 1) {
      if (permissions) {
        let idx = -1;
        idx = permissions?.findIndex(
          (ele) => ele?.module?.id === SideBarIds.companies
        );
        if (idx > -1) {
          setModulePermissions(permissions[idx]);
        } else {
          navigate(-1);
        }
      }
    }
  }, [permissions, user]);

  const onChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <div className="module-container">
      <ContentHeader
        heading="Manage Companies"
        buttonNavigateTo="manage-companies/add"
      />
      <Card>
        <Tabs activeKey={activeTab} defaultActiveKey="1" onChange={onChange}>
          <TabPane tab="Approved" key="1">
            <ApprovedCompanies
              activeTab={activeTab}
              setPendingCount={setPendingCount}
              modulePermissions={modulePermissions}
            />
          </TabPane>
          <TabPane
            tab={
              <Badge
                style={{ marginLeft: 8, top: -5, right: -6 }}
                count={pendingCount}
                overflowCount={99}
              >
                Pending
              </Badge>
            }
            key="2"
          >
            <PendingCompanies
              activeTab={activeTab}
              setPendingCount={setPendingCount}
              modulePermissions={modulePermissions}
            />
          </TabPane>
          <TabPane tab="Un-Approved" key="3">
            <UnApprovedCompanies
              activeTab={activeTab}
              setPendingCount={setPendingCount}
              modulePermissions={modulePermissions}
            />
          </TabPane>
          <TabPane tab="Groups" key="4">
            <GroupedCompanies
              activeTab={activeTab}
              modulePermissions={modulePermissions}
            />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default ManageCompanies;
