import { END_POINTS } from "../helpers/constants/urls";
import { Paginated, UserType, Product, ProductMatrix } from "../types/General";
import emptySplitApi from "../utils/rtk";

type GetProductsPayload = {
  start: number;
  is_active?: boolean;
  length: number;
  search?: Paginated;
};

type CountryResponse = {
  code: number;
  message: string;
  data: {
    id: number;
    name: string;
  }[];
  recordsTotal: number;
};

type GetProductPayload = {
  start: number;
  brand?: string;
  id?: string;
  start_date?: string;
  end_date?: string;
  product_category?: string;
  is_unapproved?: boolean;
  is_approved?: boolean;
  length: number;
  search: Paginated;
};

type ProductResponse = {
  code: number;
  message: string;
  data: Product[];
  recordsTotal: number;
  pending_obj_count: number;
  requested_obj_count: number;
};

type ProductDetailsResponse = {
  code: number;
  message: string;
  data: Product;
};

type UserDetailsResponse = {
  code: number;
  message: string;
  data: UserType;
};

export const productApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCountries: builder.mutation<CountryResponse, GetProductsPayload>({
      query: (body) => ({
        url: END_POINTS.get_countries_list,
        method: "POST",
        body,
      }),
    }),
    addProduct: builder.mutation<
      UserDetailsResponse,
      Partial<
        Omit<
          Product,
          "id" | "is_active" | "assign_qc" | "assign_sales_consultant"
        >
      > & { assign_qc: number | null; assign_sales_consultant: number | null }
    >({
      query: (body) => ({
        url: `${END_POINTS.add_product}`,
        method: "POST",
        body,
      }),
    }),
    getProduct: builder.mutation<ProductResponse, GetProductPayload>({
      query: (body) => ({
        url: `${END_POINTS.get_products}`,
        method: "POST",
        body,
      }),
    }),
    getProductById: builder.query<
      ProductDetailsResponse,
      { product_id: string }
    >({
      query: ({ product_id }) => ({
        url: `${END_POINTS.get_product_detail}${product_id}`,
        method: "GET",
      }),
    }),
    getRequestedProductById: builder.query<
      ProductDetailsResponse,
      { product_id: string }
    >({
      query: ({ product_id }) => ({
        url: `${END_POINTS.get_requested_product_detail}${product_id}`,
        method: "GET",
      }),
    }),
    updateProductStatus: builder.mutation<
      ProductResponse,
      {
        product_id: string;
        body: {
          is_active: boolean;
        };
      }
    >({
      query: ({ product_id, body }) => ({
        url: `${END_POINTS.update_product_status}${product_id}/`,
        method: "PUT",
        body,
      }),
    }),
    updateProductAcceptRejectStatus: builder.mutation<
      ProductResponse,
      {
        product_id: string;
        body: {
          is_unapproved?: boolean;
          is_approved?: boolean;
          reject_reason?: string;
        };
      }
    >({
      query: ({ product_id, body }) => ({
        url: `${END_POINTS.update_product_accept_reject_status}${product_id}/`,
        method: "PUT",
        body,
      }),
    }),
    approveProductAcceptRejectStatus: builder.mutation<
      ProductResponse,
      {
        product_id: string;
        body: {
          status?: boolean;
          product_rejection_reason?: string;
        };
      }
    >({
      query: ({ product_id, body }) => ({
        url: `${END_POINTS.approve_product_accept_reject_status}${product_id}/`,
        method: "PUT",
        body,
      }),
    }),
    assignQcToProduct: builder.mutation<
      ProductResponse,
      {
        product_id: string;
        body: {
          qc: number;
        };
      }
    >({
      query: ({ product_id, body }) => ({
        url: `${END_POINTS.assign_qc}${product_id}/`,
        method: "PUT",
        body,
      }),
    }),
    assignScToProduct: builder.mutation<
      ProductResponse,
      {
        product_id: string;
        body: {
          sc: number;
        };
      }
    >({
      query: ({ product_id, body }) => ({
        url: `${END_POINTS.assign_sales_consultant}${product_id}/`,
        method: "PUT",
        body,
      }),
    }),
    updateProduct: builder.mutation<
      ProductResponse,
      {
        product_id: string;
        body: Partial<
          Omit<
            Product,
            "id" | "is_active" | "assign_qc" | "assign_sales_consultant"
          >
        > & {
          assign_qc: number | null;
          assign_sales_consultant: number | null;
        };
      }
    >({
      query: ({ product_id, body }) => ({
        url: `${END_POINTS.update_product}${product_id}/`,
        method: "PUT",
        body,
      }),
    }),
    getQcList: builder.query<
      {
        code: number;
        data: { first_name: string; last_name: string; id: number }[];
        message: string;
      },
      {}
    >({
      query: () => ({
        url: END_POINTS.get_qc_list,
        method: "GET",
      }),
    }),
    getScList: builder.query<
      {
        code: number;
        data: { first_name: string; last_name: string; id: number }[];
        message: string;
      },
      {}
    >({
      query: () => ({
        url: END_POINTS.get_sales_consultants,
        method: "GET",
      }),
    }),
    getProductMatrix: builder.query<
      {
        code: number;
        data: {
          assigned_qc: ProductMatrix[];
        };
        message: string;
      },
      {}
    >({
      query: () => ({
        url: END_POINTS.get_product_matrix,
        method: "GET",
      }),
    }),
    getAssignedQcProducts: builder.mutation<
      {
        code: number;
        data: {
          qcs: {
            name: string;
            products: {
              id: number;
              product_name: string;
            }[];
          }[];
        };
      },
      { qc_id: number; type: number }
    >({
      query: (body) => ({
        url: END_POINTS.get_assigned_qc_products,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetCountriesMutation,
  useAddProductMutation,
  useGetProductMutation,
  useUpdateProductMutation,
  useUpdateProductStatusMutation,
  useLazyGetProductByIdQuery,
  useLazyGetQcListQuery,
  useLazyGetScListQuery,
  useAssignQcToProductMutation,
  useAssignScToProductMutation,
  useLazyGetRequestedProductByIdQuery,
  useLazyGetProductMatrixQuery,
  useGetAssignedQcProductsMutation,
  useUpdateProductAcceptRejectStatusMutation,
  useApproveProductAcceptRejectStatusMutation,
} = productApi;
