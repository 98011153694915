import { useMemo } from "react";
import { useAppSelector } from "./store";
import { getPermissions } from "../features/auth/authSlice";

function usePermissions() {
  const permissions = useAppSelector(getPermissions);

  return useMemo(() => permissions, [permissions]);
}

export default usePermissions;
