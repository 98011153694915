import { Route, Routes, useNavigate } from "react-router-dom";
import {
  Dashboard,
  ManageUsers,
  Notifications,
  Settings,
  ManageCms,
  ManageFaq,
  ManageSubAdmins,
  Profile,
  SubAdminDetails,
  SubAdminForm,
  UsersForm,
  UserDetails,
  Products,
  Analytics,
  Categories,
  SubCategories,
  Company,
  Roles,
  Orders,
  Reviews,
  RoleForm,
  ProductForm,
  CategoriesForm,
  SubCategoriesForm,
  Brands,
  BrandForm,
  WarehouseDetails,
  OrdersDetails,
  CategoriesDetails,
  SubCategoryDetails,
  BrandDetails,
  CompanyDetails,
  ProductDetails,
  RoleDetails,
  VerifyCompany,
  PageNotFound,
} from "../pages";
import { ForgetPassword, Login, ResetPassword, VerifyOtp } from "../pages/auth";
import MainLayout from "../layout";
import NotificationsHistory from "../pages/notifications/details";
import WareHouse from "../pages/warehouse";
import WareHouseForm from "../pages/warehouse/form";
import { useEffect } from "react";
import { getFromStorage } from "../helpers/storage";
import { STORAGE_KEYS } from "../helpers/constants/storageKeys";
import { resetAuth, setCredentials } from "../features/auth/authSlice";
import { useAppDispatch } from "../hooks/store";
import CompanyGroupForm from "../pages/company/form";
import GroupDetails from "../pages/company/details/GroupDetails";

const RoutesNavigation = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    if (token && window.location.pathname === "/") {
      dispatch(
        setCredentials({
          token: token,
          user: null,
        })
      );
      navigate("/dashboard", { replace: true });
    } else if (!token && window?.location?.pathname === "/") {
      dispatch(resetAuth());
      navigate("/", { replace: true });
    } else if (token) {
      dispatch(
        setCredentials({
          token: token,
          user: null,
        })
      );
      navigate(window?.location?.pathname);
    } else {
      dispatch(resetAuth());
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Login />}></Route>
      <Route path="/forgotpassword" element={<ForgetPassword />}></Route>
      <Route path="/verify-otp" element={<VerifyOtp />}></Route>
      <Route path="/reset-password" element={<ResetPassword />}></Route>
      <Route
        path="/dashboard"
        element={
          <MainLayout>
            <Dashboard />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manageusers"
        element={
          <MainLayout>
            <ManageUsers />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manageusers/edit/:id"
        element={
          <MainLayout>
            <UsersForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manageusers/userdetail/:id"
        element={
          <MainLayout>
            <UserDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/managesubadmins"
        element={
          <MainLayout>
            <ManageSubAdmins />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/profile"
        element={
          <MainLayout>
            <Profile />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-cms"
        element={
          <MainLayout>
            <ManageCms />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/settings"
        element={
          <MainLayout>
            <Settings />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/notifications"
        element={
          <MainLayout>
            <Notifications />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/notifications/history"
        element={
          <MainLayout>
            <NotificationsHistory />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-faq"
        element={
          <MainLayout>
            <ManageFaq />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-products"
        element={
          <MainLayout>
            <Products />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-products/:id/:tab"
        element={
          <MainLayout>
            <ProductForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-products/add"
        element={
          <MainLayout>
            <ProductForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-products/details/:id"
        element={
          <MainLayout>
            <ProductDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/analytics"
        element={
          <MainLayout>
            <Analytics />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-categories"
        element={
          <MainLayout>
            <Categories />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-categories/:id"
        element={
          <MainLayout>
            <CategoriesForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-categories/details/:id"
        element={
          <MainLayout>
            <CategoriesDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-subcategories"
        element={
          <MainLayout>
            <SubCategories />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-subcategories/:id"
        element={
          <MainLayout>
            <SubCategoriesForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-subcategories/details/:id"
        element={
          <MainLayout>
            <SubCategoryDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-brands"
        element={
          <MainLayout>
            <Brands />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-brands/:id"
        element={
          <MainLayout>
            <BrandForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-brands/details/:id"
        element={
          <MainLayout>
            <BrandDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-warehouse"
        element={
          <MainLayout>
            <WareHouse />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-warehouse/:id"
        element={
          <MainLayout>
            <WareHouseForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-warehouse/detail/:id"
        element={
          <MainLayout>
            <WarehouseDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-companies"
        element={
          <MainLayout>
            <Company />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-companies/details/:id"
        element={
          <MainLayout>
            <CompanyDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-group/details/:id"
        element={
          <MainLayout>
            <GroupDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-companies/group/:id/:tab"
        element={
          <MainLayout>
            <CompanyGroupForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-companies/group/add"
        element={
          <MainLayout>
            <CompanyGroupForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-companies/update/:id/:tab"
        element={
          <MainLayout>
            <VerifyCompany />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-roles"
        element={
          <MainLayout>
            <Roles />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-roles/:id"
        element={
          <MainLayout>
            <RoleForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-roles/details/:id"
        element={
          <MainLayout>
            <RoleDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-orders"
        element={
          <MainLayout>
            <Orders />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-orders/details/:id"
        element={
          <MainLayout>
            <OrdersDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-reviews"
        element={
          <MainLayout>
            <Reviews />
          </MainLayout>
        }
      ></Route>
      <Route
        path={`/managesubadmins/edit/:id`}
        element={
          <MainLayout>
            <SubAdminForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path={`/managesubadmins/add`}
        element={
          <MainLayout>
            <SubAdminForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/managesubadmins/subadmindetail/:id"
        element={
          <MainLayout>
            <SubAdminDetails />
          </MainLayout>
        }
      ></Route>

      <Route path="*" element={<PageNotFound />}></Route>
    </Routes>
  );
};

export default RoutesNavigation;
