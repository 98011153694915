import { Button, Card } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FormData from "./ProductForm";

const ProductForm = () => {
  const navigate = useNavigate();
  const { tab } = useParams();
  const url = window.location.pathname;
  const path = url.includes("add");

  return (
    <div className="module-container">
      <Card>
        <div className="detail-module-card">
          <h2>{!path ? "Edit " : "Add "}Product</h2>
          <Button
            className="back-btn-container"
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigate("/manage-products", {
                replace: true,
                state: {
                  tab: tab || "1",
                },
              });
            }}
          >
            Back
          </Button>
        </div>
        <FormData path={!path} />
      </Card>
    </div>
  );
};

export default ProductForm;
