/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Input, Row, Space, Switch, Table, Select } from "antd";
import type { ColumnsType } from "antd/es/table";
import { EditOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  useAssignCompanyGroupMutation,
  useGetAllCompanyGroupMutation,
  useGetCompaniesMutation,
  useUpdateCompanyApprovedStatusMutation,
} from "../../../services/company";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Company, Permissions } from "../../../types/General";
import TableLoader from "../../../components/loader/TableLoader";
import { showError, showToast } from "../../../helpers/toast";
import { PageLoader } from "../../../components";
import { isValidInput } from "../../../utils/validations";
import useAuth from "../../../hooks/useAuth";

const { Option } = Select;

const ApprovedCompanies = ({
  activeTab,
  setPendingCount,
  modulePermissions,
}: {
  activeTab: string;
  setPendingCount: Dispatch<SetStateAction<number>>;
  modulePermissions: Permissions | null | undefined;
}) => {
  const user = useAuth();
  const navigate = useNavigate();

  const [getCompanyGroups] = useGetAllCompanyGroupMutation();
  const [getCompanies, { isLoading }] = useGetCompaniesMutation();
  const [updateCompanyStatus, UpdateStatus] =
    useUpdateCompanyApprovedStatusMutation();
  const [assignCompanyToGroup] = useAssignCompanyGroupMutation();

  const [page, setPage] = useState(1);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [companyGroups, setCompanyGroups] = useState<
    {
      id: number;
      name: string;
      companies: { id: number; name: string }[];
    }[]
  >([]);
  const [total, setTotal] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const getApprovedCompanies = async () => {
    const body = {
      start: page,
      length: 10,
      is_approved: true,
      search: {
        value: debouncedSearchTerm.trim(),
        regex: false,
      },
    };
    try {
      const result = await getCompanies(body).unwrap();
      console.log(result);
      if (result?.code === 200) {
        setCompanies(result?.data || []);
        setTotal(result?.recordsTotal || 1);
        setPendingCount(result?.pending_compay_obj_count || 0);
      } else {
        setCompanies([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCompanyGroup = async () => {
    const body = {
      start: page,
      length: 100,
      is_approved: true,
      search: {
        value: debouncedSearchTerm.trim(),
        regex: false,
      },
    };
    try {
      const result = await getCompanyGroups(body).unwrap();
      console.log(result);
      if (result?.code === 200) {
        setCompanyGroups(result?.data || []);
        setTotal(result?.recordsTotal || 1);
      } else {
        setCompanies([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (activeTab === "1") {
      getApprovedCompanies();
    }
  }, [page, debouncedSearchTerm, activeTab]);

  useEffect(() => {
    getCompanyGroup();
  }, []);

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [searchTerm]);

  const updateStatus = async (id: string, state: boolean) => {
    const response = await updateCompanyStatus({
      company_id: id,
      body: {
        is_active: state,
      },
    }).unwrap();
    if (response?.code === 200) {
      const updatedData = companies.map((x) => {
        if (x.id === parseInt(id, 10)) {
          return {
            ...x,
            is_active: state,
          };
        }
        return x;
      });
      setCompanies(updatedData);
      showToast("Status updated successfully");
    } else {
      showError("Please try again");
    }
  };

  const assignGroupToCompany = async (companyId: number, groupId: number) => {
    try {
      const body = {
        company_id: companyId,
        group_id: `${groupId}`,
      };
      const response = await assignCompanyToGroup(body).unwrap();
      console.log(response);
      if (response?.code === 200 || response?.code === 201) {
        showToast("Group assigned successfully.");
        let idx = -1;
        idx = companyGroups?.findIndex((ele) => ele?.id === groupId);
        if (idx > -1) {
          const updatedData = companies.map((x) => {
            if (x.user_id === companyId) {
              return {
                ...x,
                assigned_group_id: groupId,
              };
            }
            return x;
          });
          setCompanies(updatedData);
        }
      } else {
        showError(response?.message || "Something went wrong");
      }
    } catch (error: any) {
      console.log(error);
      showError(error?.body?.message || "Something went wrong");
    }
  };

  const renderSwitch = (_: any, data: Company) => {
    return (
      <Switch
        onChange={(val) => updateStatus(`${data?.id}`, val)}
        size="small"
        checked={data?.is_active}
      />
    );
  };

  const columns: ColumnsType<Company> = [
    {
      title: "S.no.",
      key: "sno",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "user_company",
      key: "user_company",
    },
    {
      title: "Owner's Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <div>{`${record?.first_name || ""} ${record?.last_name || ""}`}</div>
      ),
    },
    {
      title: "Owner's Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Phone Number",
      dataIndex: "office",
      key: "office",
      align: "center",
      render: (_, record) => (
        <div>
          {`${record?.office_country_code || ""}${
            record?.office_phone_no || ""
          }`}
        </div>
      ),
    },
    {
      title: "Company Phone Number",
      dataIndex: "company_phone_no",
      key: "company_phone_no",
      align: "center",
      render: (_, record) => (
        <div>
          {`${record?.company_country_code || ""}${
            record?.company_phone_no || ""
          }`}
        </div>
      ),
    },
    {
      title: "Assign Group",
      key: "status",
      align: "center",
      render: (_, record) => (
        <Select
          className="select-div"
          value={record?.assigned_group_id || null}
          onChange={(val) => assignGroupToCompany(record?.user_id || 1, val)}
          placeholder={"Assign Group"}
        >
          {companyGroups?.map((item, index) => (
            <Option key={index} value={item?.id}>{`${
              item?.name || ""
            }`}</Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manage-companies/details/${record?.id}`, {
                state: { tab: activeTab },
              });
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>
          {modulePermissions?.is_edit || user?.role === 1 ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  `/manage-companies/update/${record?.id}/${activeTab}`,
                  "_blank"
                );
              }}
            >
              <EditOutlined color="#08c" />
            </Button>
          ) : null}
        </Space>
      ),
    },
    {
      title: "Account Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: renderSwitch,
      className: modulePermissions?.is_edit || user?.role === 1 ? "" : "hidden",
    },
  ];

  return (
    <div>
      <PageLoader isLoading={UpdateStatus.isLoading} />
      <div className="srch">
        <Row gutter={16}>
          <Col xs={24} md={7}>
            <Input
              value={searchTerm}
              onChange={(val) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
              placeholder="Search"
              prefix={<SearchOutlined style={{ fontSize: "20px" }} />}
              // style={{ height: 55, borderRadius: 10, minWidth: "360px" }}
              className="search-bar"
              allowClear
            />
          </Col>
        </Row>
        {isLoading ? (
          <TableLoader />
        ) : (
          <Table
            className="table-container"
            columns={columns}
            dataSource={companies || []}
            pagination={{
              defaultPageSize: 10,
              current: page,
              onChange: onPageChange,
              total,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ApprovedCompanies;
