import { Button, Card, Col, Form, Input, Row, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import useAuth from "../../hooks/useAuth";
import { RcFile } from "antd/es/upload";
import { ImageViewer } from "../../components";
import { UploadMedia } from "../../utils/imageUpload";
import { useEditProfileMutation } from "../../services/auth";
import { showError, showToast } from "../../helpers/toast";
import { setCredentials } from "../../features/auth/authSlice";
import { useAppDispatch } from "../../hooks/store";
import { getFromStorage } from "../../helpers/storage";
import { STORAGE_KEYS } from "../../helpers/constants/storageKeys";
import { isValidInput } from "../../utils/validations";

let imageId = 0;
const Profile = () => {
  const [form] = Form.useForm();
  const user = useAuth();
  const dispatch = useAppDispatch();

  const [updateProfile, { isLoading }] = useEditProfileMutation();

  const [phoneCode, setPhoneCode] = useState("+91");
  const [countryName, setCountryName] = useState("in");
  const [phoneNo, setPhoneNo] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [imageObject, setImageObject] = useState<RcFile | null>(null);
  const [imagePreview, setImagePreview] = useState("");

  const handleImageUpload = async () => {
    try {
      const res = await UploadMedia(imageObject);
      if (res?.code === 200) {
        return res?.data[0]?.id;
      }
      return 0;
    } catch (error: any) {
      console.log(error);
      return 0;
    }
  };

  const onFinish = async (values: any) => {
    if (imageObject) {
      imageId = await handleImageUpload();
    }
    const body = {
      first_name: firstName,
      last_name: lastName,
      company_country_code: phoneCode,
      company_country_iso_code: countryName,
      company_phone_no: phoneNo,
      image: imageId || "",
      business_email: email,
    };

    try {
      const response = await updateProfile(body).unwrap();
      console.log(response);
      if (response?.code === 200) {
        const token = getFromStorage(STORAGE_KEYS.token);
        showToast("Profile updated successfully");
        dispatch(
          setCredentials({
            user: response?.data || null,
            token: token || null,
          })
        );
      } else {
        showError(response?.message || "");
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error);
    }
  };

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(`+${country?.dialCode}`);
    setPhoneNo(phone?.replace(country.dialCode, ""));
    setCountryName(country?.countryCode);
  };

  const handleUpload = (File: RcFile) => {
    setImageObject(File);
    const result = window.URL.createObjectURL(File);
    setImagePreview(result);
  };

  useEffect(() => {
    if (user) {
      setFirstName(user?.first_name || "");
      setLastName(user?.last_name || "");
      setEmail(user?.business_email || "");
      setPhoneCode(user?.company_country_code || "+1");
      setPhoneNo(user?.company_phone_no || "");
      setCountryName(user?.company_country_iso_code || "us");
      setImagePreview(user?.image?.media_file_url || "");
      imageId = user?.image?.id;

      form.setFieldsValue({
        firstName: user?.first_name || "",
        lastName: user?.last_name || "",
        email: user?.business_email || "",
        phone: user?.company_phone_no || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className="module-container">
      <Card>
        <div className="detail-module-card">
          <h2>User Profile</h2>
        </div>

        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            style={{
              marginTop: 50,
            }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item>
                  {imagePreview ? (
                    <ImageViewer
                      source={imagePreview}
                      resetImage={() => {
                        setImageObject(null);
                        setImagePreview("");
                      }}
                    />
                  ) : (
                    <Upload
                      name="logo"
                      beforeUpload={handleUpload}
                      accept="image/png, image/gif, image/jpeg"
                    >
                      <h4>Upload Profile Image</h4>
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name.",
                    },
                    {
                      min: firstName?.length ? 1 : 2,
                      message: "Please input your first name.",
                    },
                  ]}
                >
                  <div>
                    <h4>First Name</h4>
                    <Input
                      value={firstName}
                      maxLength={40}
                      onChange={(val) => {
                        if (isValidInput(val.target.value)) {
                          setFirstName(val.target.value);
                        }
                      }}
                      placeholder="First Name"
                      style={{ height: 55 }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item name="lastName">
                  <div>
                    <h4>Last Name</h4>
                    <Input
                      value={lastName}
                      onChange={(val) => {
                        if (isValidInput(val.target.value)) {
                          setLastName(val.target.value);
                        }
                      }}
                      maxLength={40}
                      placeholder="Last Name"
                      style={{ height: 55 }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                >
                  <div>
                    <h4>Email</h4>
                    <Input
                      disabled
                      style={{ height: 55 }}
                      placeholder="E-mail"
                      value={email}
                      onChange={(val) => setEmail(val.target.value)}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <div>
                    <h4>Phone Number</h4>
                    <div className="phn_cs">
                      <PhoneInput
                        value={phoneCode + phoneNo}
                        enableSearch={true}
                        placeholder="Phone number"
                        containerClass={"cntn_telCls"}
                        inputClass={"cstm_cls"}
                        buttonClass={"custm_btn"}
                        onChange={(phone, country) =>
                          handleChangePhone(phone, country)
                        }
                      />
                    </div>
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Button
                className="submit-btn-container"
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default Profile;
