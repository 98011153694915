/* eslint-disable react-hooks/exhaustive-deps */
import { Card } from "antd";
import { ContentHeader } from "../../components";
import { useEffect, useState } from "react";
import ApprovedCategories from "./types/Approved";
import { useGetCategoriesMutation } from "../../services/main";
import { Category, Permissions } from "../../types/General";
import { TableLoader } from "../../components";
import usePermissions from "../../hooks/permissions";
import { SideBarIds } from "../../utils/constants";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const Categories = () => {
  const user = useAuth();
  const navigate = useNavigate();
  const permissions = usePermissions();
  const [getCategoryList, { isLoading }] = useGetCategoriesMutation();

  const [approvedCategories, setApprovedCategories] = useState<Category[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [modulePermissions, setModulePermissions] = useState<
    Permissions | null | undefined
  >(null);

  useEffect(() => {
    if (user && user?.role !== 1) {
      if (permissions) {
        let idx = -1;
        idx = permissions?.findIndex(
          (ele) => ele?.module?.id === SideBarIds.category
        );
        if (idx > -1) {
          setModulePermissions(permissions[idx]);
        } else {
          navigate(-1);
        }
      }
    }
  }, [permissions, user]);

  const getCategories = async () => {
    try {
      const body = {
        start: page,
        length: 10,
        is_approved: true,
        search: {
          value: debouncedSearchTerm.trim(),
          regex: false,
        },
      };
      const response = await getCategoryList({
        body,
      }).unwrap();
      if (response?.code === 200) {
        setApprovedCategories(response?.data || []);
        setTotal(response?.recordsTotal || 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategories();
  }, [page, debouncedSearchTerm]);

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [searchTerm]);

  return (
    <div className="module-container">
      <ContentHeader
        modulePermissions={modulePermissions}
        heading="Manage Categories"
        buttonNavigateTo="manage-categories/add"
        buttonText="Add"
      />
      <Card className="card_space">
        {isLoading ? (
          <TableLoader />
        ) : (
          <ApprovedCategories
            page={page}
            modulePermissions={modulePermissions}
            total={total}
            searchTerm={searchTerm}
            categories={approvedCategories}
            setSearchTerm={setSearchTerm}
            setPage={setPage}
            setApprovedCategories={setApprovedCategories}
          />
        )}
      </Card>
    </div>
  );
};

export default Categories;
