/* eslint-disable react-hooks/exhaustive-deps */
import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select, Upload } from "antd";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  useAddSubAdminMutation,
  useLazyGetRoleQuery,
  useLazyGetSubAdminByIdQuery,
  useUpdateSubAdminByIdMutation,
} from "../../services/main";
import { showError, showToast } from "../../helpers/toast";
import { useNavigate, useParams } from "react-router-dom";
import { UploadMedia } from "../../utils/imageUpload";
import { RcFile } from "antd/es/upload";
import { CloseCircleOutlined } from "@ant-design/icons";

const { Option } = Select;
let image = 0;

const FormData = (props: any) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [phoneCode, setPhoneCode] = useState("+91");
  const [phoneNo, setphoneNo] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [imageObject, setImageObject] = useState<RcFile | null>(null);

  const [imagePreview, setImagePreview] = useState("");
  const [phoneIso, setPhoneIso] = useState("in");
  const [roles, setRoles] = useState<{ id: number; name: string }[] | []>([]);
  const [selectedRole, setSelectedRole] = useState<number | undefined>();
  const [addSubAdmin, addAdminLoader] = useAddSubAdminMutation();
  const [editSubAdmin, editAdminLoader] = useUpdateSubAdminByIdMutation();
  const [getRoles] = useLazyGetRoleQuery();
  const [SubAdminById] = useLazyGetSubAdminByIdQuery();

  const getRolesList = async () => {
    const body = {
      start: 1,
      length: 50,
      search: {
        value: "",
        regex: false,
      },
    };

    const response = await getRoles({ body }).unwrap();
    if (response?.code === 200) {
      setRoles(response?.data || []);
    }
  };

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    setPhoneIso(country?.countryCode);
    setphoneNo(phone?.replace(country.dialCode, ""));
  };

  const handleChange = (value: any) => {
    console.log(`Selected ${value}`);
    setSelectedRole(value);
    form.setFieldsValue({
      role: value,
    });
    form.validateFields(["role"]);
  };

  const normFile = (e: any) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleImageUpload = async () => {
    try {
      const res = await UploadMedia(imageObject);
      if (res?.code === 200) {
        return res?.data[0]?.id;
      }
      return 0;
    } catch (error: any) {
      console.log(error);
      return 0;
    }
  };

  const onFinish = async (values: any) => {
    console.log("hiii");
    if (imageObject) {
      console.log(imageObject, "imageobject");

      image = await handleImageUpload();
    }
    const body = {
      first_name: firstName,
      last_name: lastName,
      office_country_code: phoneCode?.includes("+")
        ? phoneCode
        : `+${phoneCode}`,
      office_phone_no: phoneNo,
      office_country_iso_code: phoneIso,
      email: email,
      user_role: selectedRole,
      image: image > 0 ? image : "",
      ...(id && id !== "add" ? {} : { password: password }),
      // password: password,
    };

    console.log(body, "bodybody");

    try {
      let response = null;
      if (id && id !== "add") {
        response = await editSubAdmin({
          body,
          subAdmin_id: `${id}`,
        }).unwrap();
      } else {
        response = await addSubAdmin(body).unwrap();
      }

      if (response?.code === 201 || response?.code === 200) {
        showToast(response?.message || "");
        navigate("/managesubadmins", { replace: true });
      } else {
        showError(response?.message || "");
      }
    } catch (error: any) {
      console.log(error);
      showError(error?.data?.message || "");
    }
  };

  const handleUpload = (File: RcFile) => {
    setImageObject(File);
    const result = window.URL.createObjectURL(File);
    setImagePreview(result);
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }: any) => {
    console.log(
      values,
      errorFields,
      outOfDate,
      "values, errorFields, outOfDate"
    );
  };
  console.log(imageObject, "imageobjectstr");
  const subAdminDetail = async (id: string) => {
    try {
      const response = await SubAdminById({ subAdmin_id: id }).unwrap();
      console.log(response, "edit");

      if (response?.code === 200) {
        console.log(response, "form");
        setFirstName(response?.data?.first_name || "");
        setLastName(response?.data?.last_name || "");
        setEmail(response?.data?.email || "");
        setPhoneCode(response?.data?.office_country_code || "");
        setphoneNo(response?.data?.office_phone_no || "");
        setImagePreview(response?.data?.image?.media_file_url || "");
        setSelectedRole(response?.data?.user_role?.id);
        image = response?.data?.image?.id;

        form.setFieldsValue({
          first_name: response?.data?.first_name || "",
          last_name: response?.data?.last_name || "",
          user_email: response?.data?.email || "",
          user_phone: response?.data?.office_phone_no || "",
          password: response?.data?.password || "",
          role: response?.data?.user_role?.name || "",
          phone: response?.data?.office_phone_no || "",
          upload: response?.data?.image?.media_file_url || "",
        });
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      subAdminDetail(id);
    }
  }, [id]);

  useEffect(() => {
    getRolesList();
  }, []);

  return (
    <div>
      <Form
        name="basic"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <h4>Profile Image</h4>
            <Form.Item
              name="upload"
              // label="Upload"
              getValueFromEvent={normFile}
            >
              {imagePreview ? (
                <div style={{ position: "relative", width: "150px" }}>
                  <CloseCircleOutlined
                    onClick={() => {
                      setImageObject(null);
                      setImagePreview("");
                      image = 0;
                    }}
                    style={{
                      color: "red",
                      position: "absolute",
                      right: 0,
                      top: 0,
                      fontSize: 20,
                      cursor: "pointer",
                    }}
                  />
                  <figure
                    style={{
                      height: "150px",
                      width: "100%",
                      border: "1px solid black",
                      borderRadius: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <img src={imagePreview || ""} alt="" />
                  </figure>
                </div>
              ) : (
                <Upload
                  name="logo"
                  beforeUpload={handleUpload}
                  accept="image/png, image/gif, image/jpeg"
                >
                  {/* <h4>Upload Contract Image</h4> */}
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "Please enter your first name!",
                  whitespace: true,
                },
                {
                  min: 3,
                  message: "Name must be at least 3 characters",
                },
              ]}
            >
              <div>
                <h4>First Name</h4>
                <Input
                  placeholder="First Name"
                  style={{ height: 55 }}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="last_name"
              rules={[
                {
                  required: true,
                  message: "Please enter your last name!",
                  whitespace: true,
                },
                {
                  min: 3,
                  message: "Name must be at least 3 characters",
                },
              ]}
            >
              <div>
                <h4>Last Name</h4>
                <Input
                  placeholder="Last Name"
                  style={{ height: 55 }}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please enter phone number",
                },
                {
                  min: 6,
                  message: "Phone number must be at least 6 digits",
                },
              ]}
            >
              <div>
                <h4>Phone Number</h4>
                <div className="phn_cs">
                  <PhoneInput
                    value={phoneCode + phoneNo}
                    enableSearch={true}
                    placeholder="Phone number"
                    containerClass={"cntn_telCls"}
                    inputClass={"cstm_cls"}
                    buttonClass={"custm_btn"}
                    onChange={(phone, country) =>
                      handleChangePhone(phone, country)
                    }
                  />
                </div>
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="user_email"
              rules={[
                {
                  required: true,
                  message: "Please enter your email!",
                },
                {
                  type: "email",
                  message: "The input is not valid email!",
                },
              ]}
            >
              <div>
                <h4>Email</h4>
                <Input
                  // disabled
                  style={{ height: 55 }}
                  placeholder="E-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          {!(id && id !== "add") ? (
            <Col xs={24} md={12}>
              <Form.Item
                name="user_password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password!",
                  },
                  {
                    min: 6,
                    message: "Password must be at least 6 characters",
                  },
                ]}
              >
                <div>
                  <h4>Password</h4>
                  <Input.Password
                    style={{ height: 55 }}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </Form.Item>
            </Col>
          ) : null}
          <Col xs={24} md={12}>
            <Form.Item
              name="role"
              rules={[
                {
                  required: true,
                  message: "Please select a role",
                },
              ]}
            >
              <div>
                <h4>Role</h4>

                <Select
                  value={selectedRole}
                  style={{ width: "100%" }}
                  onChange={handleChange}
                  placeholder={"Choose Role"}
                >
                  {roles?.map((item) => {
                    return (
                      <Option value={item?.id} key={item?.id}>
                        {item?.name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </Form.Item>
          </Col>
        </Row>

        <div className="submit-btn-wrapper">
          <Button
            className="submit-btn-container"
            type="primary"
            htmlType="submit"
            loading={addAdminLoader?.isLoading || editAdminLoader?.isLoading}
          >
            {props?.path ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default FormData;
