/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Input,
  Row,
  Space,
  Switch,
  Table,
  Select,
  Divider,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { EditOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  useAssignCompanyGroupMutation,
  useGetAllCompanyGroupMutation,
  useGetCompaniesMutation,
  useUpdateCompanyGroupMutation,
} from "../../../services/company";
import { useEffect, useState } from "react";
import { Company, CompanyGroup, Permissions } from "../../../types/General";
import TableLoader from "../../../components/loader/TableLoader";
import { showError, showToast } from "../../../helpers/toast";
import { PageLoader } from "../../../components";
import { isValidInput } from "../../../utils/validations";
import useAuth from "../../../hooks/useAuth";

const { Option } = Select;

const GroupedCompanies = ({
  activeTab,
  modulePermissions,
}: {
  activeTab: string;
  modulePermissions: Permissions | null | undefined;
}) => {
  const user = useAuth();
  const navigate = useNavigate();

  const [getCompanies, { isLoading }] = useGetAllCompanyGroupMutation();
  const [getApprovedCompaniesList] = useGetCompaniesMutation();
  const [updateCompanyStatus, UpdateStatus] = useUpdateCompanyGroupMutation();
  const [assignCompanyToGroup] = useAssignCompanyGroupMutation();

  const [page, setPage] = useState(1);
  const [companies, setCompanies] = useState<CompanyGroup[]>([]);
  const [approvedCompanies, setApprovedCompanies] = useState<Company[]>([]);
  const [total, setTotal] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const assignCompany = async (
    id: number,
    selectedCompanies: { id: number; name: string }[]
  ) => {
    const body = {
      company_ids: selectedCompanies?.map((i) => i.id),
      group_id: `${id}`,
    };
    try {
      const response = await assignCompanyToGroup(body).unwrap();
      console.log(response);
      if (response?.code === 201) {
        showToast(response?.message || "");
      } else {
        showError("Please try again");
      }
    } catch (error) {
      console.log(error);
      showError("Please try again");
    }
  };

  const getApprovedCompanies = async () => {
    const body = {
      start: page,
      length: 100,
      is_approved: true,
      search: {
        value: debouncedSearchTerm.trim(),
        regex: false,
      },
    };
    try {
      const result = await getApprovedCompaniesList(body).unwrap();
      console.log(result);
      if (result?.code === 200) {
        setApprovedCompanies(result?.data || []);
        setTotal(result?.recordsTotal || 1);
      } else {
        setApprovedCompanies([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getGroupedCompanies = async () => {
    const body = {
      start: page,
      length: 10,
      search: {
        value: debouncedSearchTerm.trim(),
        regex: false,
      },
    };
    try {
      const result = await getCompanies(body).unwrap();
      console.log(result);
      if (result?.code === 200) {
        setCompanies(result?.data || []);
        setTotal(result?.recordsTotal || 1);
      } else {
        setCompanies([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (activeTab === "4") {
      getGroupedCompanies();
    }
  }, [page, debouncedSearchTerm, activeTab]);

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [searchTerm]);

  useEffect(() => {
    getApprovedCompanies();
  }, []);

  const updateStatus = async (id: string, state: boolean) => {
    const response = await updateCompanyStatus({
      group_id: id,
      body: {
        is_active: state,
      },
    }).unwrap();
    if (response?.code === 200 || response?.code === 201) {
      const updatedData = companies.map((x) => {
        if (x.id === parseInt(id, 10)) {
          return {
            ...x,
            is_active: state,
          };
        }
        return x;
      });
      setCompanies(updatedData);
      showToast("Status updated successfully");
    } else {
      showError("Please try again");
    }
  };

  const renderSwitch = (_: any, data: CompanyGroup) => {
    return (
      <Switch
        onChange={(val) => updateStatus(`${data?.id}`, val)}
        size="small"
        checked={data?.is_active}
      />
    );
  };

  const columns: ColumnsType<CompanyGroup> = [
    {
      title: "S.no.",
      key: "sno",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Assign Company",
      key: "status",
      align: "center",
      render: (_, record) => (
        <Select
          mode="multiple"
          style={{ width: 250 }}
          value={record?.companies?.map((i) => i?.id) || []}
          onChange={(val) => {
            console.log(`qc-id ${val} product ${record?.id}`);
            const newArr = [...companies];
            let idx = -1;
            idx = companies?.findIndex((ele) => ele.id === record.id);
            if (idx > -1) {
              newArr[idx] = {
                ...newArr[idx],
                companies: val?.map((i) => {
                  return { id: i, name: "" };
                }),
              };
            }
            setCompanies([...newArr]);
          }}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: "8px 0" }} />
              <Space style={{ padding: "0 8px 4px" }}>
                <Button
                  type="primary"
                  onClick={() => {
                    if (record?.id && record?.companies) {
                      assignCompany(record.id, record?.companies);
                    }
                  }}
                >
                  Save
                </Button>
              </Space>
            </>
          )}
          placeholder={"Assign Company"}
        >
          {approvedCompanies?.map((item, index) => (
            <Option key={index} value={item?.user_id}>{`${
              item?.user_company || ""
            }`}</Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manage-group/details/${record?.id}`, {
                state: { tab: activeTab },
              });
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>
          {modulePermissions?.is_edit || user?.role === 1 ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  `/manage-companies/group/${record?.id}/${activeTab}`,
                  "_blank"
                );
              }}
            >
              <EditOutlined color="#08c" />
            </Button>
          ) : null}
        </Space>
      ),
    },
    {
      title: "Account Status",
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      render: renderSwitch,
    },
  ];

  return (
    <div>
      <PageLoader isLoading={UpdateStatus.isLoading} />
      <div className="srch">
        <Row
          gutter={16}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Col xs={24} md={7}>
            <Input
              value={searchTerm}
              onChange={(val) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
              placeholder="Search"
              prefix={<SearchOutlined style={{ fontSize: "20px" }} />}
              // style={{ height: 55, borderRadius: 10, minWidth: "360px" }}
              className="search-bar"
              allowClear
            />
          </Col>
          <Col xs={24} md={12} style={{ display: "flex" }}>
            <Button
              className="header-btn"
              type="primary"
              onClick={() => {
                navigate("/manage-companies/group/add", {
                  state: { tab: activeTab },
                });
              }}
            >
              Add
            </Button>
          </Col>
        </Row>
        {isLoading ? (
          <TableLoader />
        ) : (
          <Table
            className="table-container"
            columns={columns}
            dataSource={companies || []}
            pagination={{
              defaultPageSize: 10,
              current: page,
              onChange: onPageChange,
              total,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default GroupedCompanies;
