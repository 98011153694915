import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
  AtomicBlockUtils,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { UploadMedia } from "../../utils/imageUpload";
const EditText = (props: {
  content: string;
  setContent: (arg0: string) => void;
}) => {
  let contentDataState: any;
  if (props.content !== " ") {
    contentDataState = ContentState.createFromBlockArray(
      convertFromHTML(`${props.content}`).contentBlocks
    );
  }
  const [editorState, setEditorState] = useState(
    props.content !== " "
      ? EditorState.createWithContent(contentDataState)
      : EditorState.createEmpty()
  );
  const text = draftToHtml(convertToRaw(editorState.getCurrentContent()));

  useEffect(() => {
    props.setContent(text);
  }, [text]);

  return (
    <div>
      <Editor
        placeholder="Start Typing..."
        toolbar={{
          // options: [
          //   "inline",
          //   "fontSize",
          //   "list",
          //   "textAlign",
          //   "link",
          //   "colorPicker",
          //   "embedded",
          //   "emoji",
          //   "image",
          // ],
          image: {
            uploadCallback: async (file: any) => {
              const imageUrl = await UploadMedia(file);
              return { data: { link: imageUrl?.data[0]?.media_file_url } };
            },
            alt: { present: true, mandatory: false },
          },
          // inline: {
          //   options: ["bold", "italic", "underline"],
          // },
          // textAlign: {
          //   options: ["left", "center"],
          // },
          // list: {
          //   options: ["unordered"],
          // },
        }}
        editorState={editorState}
        onEditorStateChange={setEditorState}
        editorStyle={{
          height: "40vh",
          border: "1px solid #E6E8F0",
          padding: 10,
        }}
      />
    </div>
  );
};
export default EditText;
