import { Button, Card } from "antd";
import { useNavigate } from "react-router-dom";
import FormData from "../../../components/manageSubAdmin/Form";
import useAuth from "../../../hooks/useAuth";
import usePermissions from "../../../hooks/permissions";
import { useEffect } from "react";
import { showWarning } from "../../../helpers/toast";
import { SideBarIds } from "../../../utils/constants";

const SubAdminForm = () => {
  const user = useAuth();
  const navigate = useNavigate();
  const permissions = usePermissions();

  const url = window.location.pathname;
  const path = url.includes("edit");

  useEffect(() => {
    if (user?.role !== 1) {
      if (permissions) {
        let idx = -1;
        idx = permissions?.findIndex(
          (ele) => ele?.module?.id === SideBarIds.subAdmin
        );
        if (idx > -1) {
          if (!permissions[idx]?.is_add || !permissions[idx]?.is_edit) {
            showWarning("You are not authorized to access this page");
            navigate(-1);
          }
        }
        showWarning("You are not authorized to access this page");
        navigate(-1);
        console.log(idx);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, user]);

  return (
    <div className="module-container">
      <Card>
        <div className="detail-module-card">
          <h2>{path ? "Edit " : "Add "}Sub Admin</h2>
          <Button
            className="back-btn-container"
            type="primary"
            onClick={() => {
              navigate("/managesubadmins", { replace: true });
            }}
          >
            Back
          </Button>
        </div>
        <FormData path={path} />
      </Card>
    </div>
  );
};

export default SubAdminForm;
