import { Button, Card, Checkbox, Col, Form, Input, Row, Table } from "antd";
import { useEffect, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAddRoleMutation,
  useLazyGetSubAdminModulesQuery,
  useLazyGetRoleByIdQuery,
  useUpdateRoleMutation,
} from "../../../services/main";
import { showError, showToast } from "../../../helpers/toast";
import { isValidInput } from "../../../utils/validations";
import { PageLoader } from "../../../components";

interface DataType {
  id: string;
  label: string;
  isAdd: boolean;
  isView: boolean;
  isEdit: boolean;
  disabled?: boolean;
}

const RolesForm = (props: any) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();

  const [getRoleDetails] = useLazyGetRoleByIdQuery();
  const [getAllSubAdminModules] = useLazyGetSubAdminModulesQuery();
  const [createRole, addLoader] = useAddRoleMutation();
  const [updateRole, editLoader] = useUpdateRoleMutation();

  const [newData, setNewData] = useState<any>([]);
  const [name, setName] = useState("");
  const [permissions, setPermissions] = useState<DataType[]>([]);

  const getRoleById = async (oldArray: DataType[]) => {
    try {
      const response = await getRoleDetails({
        role_id: `${id || ""}`,
      }).unwrap();
      if (response?.code === 200) {
        setName(response?.data?.name || "");
        form.setFieldValue("role-name", response?.data?.name || "");
        const mapping: {
          [key: number]: { isAdd: boolean; isEdit: boolean; isView: boolean };
        } = {};

        for (let item2 of response?.data?.role_permissions) {
          mapping[item2.module.id] = {
            isAdd: item2.is_add,
            isEdit: item2.is_edit,
            isView: item2.is_view,
          };
        }

        for (let item1 of oldArray) {
          const module = mapping[parseInt(item1.id, 10)];
          if (module) {
            item1.isAdd = module.isAdd;
            item1.isEdit = module.isEdit;
            item1.isView = module.isView;
          }
        }

        setPermissions(oldArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getModules = async () => {
    const response = await getAllSubAdminModules({}).unwrap();
    console.log(response);
    if (response?.code === 200) {
      if (response?.data?.length) {
        let newArr: any = response?.data;
        newArr = newArr?.map((item: { name: string; id: number }) => {
          return {
            label: item?.name || "",
            id: item?.id || "",
            isAdd: false,
            isView: false,
            isEdit: false,
            disabled: false,
          };
        });
        if (id && id !== "add") {
          getRoleById(newArr);
        } else {
          setPermissions(newArr);
        }
      }
    }
  };

  useEffect(() => {
    getModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectChange = (key: any, type: string) => {
    let idx = permissions.findIndex((obj) => obj.label === key.label);

    if (type === "add") {
      permissions[idx].isAdd = !key.isAdd;
      permissions[idx].isView = key.isEdit || key.isAdd ? true : false;
    } else if (type === "edit") {
      permissions[idx].isEdit = !key.isEdit;
      permissions[idx].isView = key.isEdit || key.isAdd ? true : false;
    } else {
      permissions[idx].isView = !key.isView;
      if (!key.isView) {
        permissions[idx].isAdd = false;
        permissions[idx].isEdit = false;
      }
    }

    setPermissions([...permissions]);
  };

  const onFinish = async (values: any) => {
    console.log("Received values of form: ", values);
    permissions?.length > 0 &&
      permissions.map((element) => {
        const obj = {
          module_id: element?.id,
          is_add: element.isAdd,
          is_view: element.isView,
          is_edit: element.isEdit,
          is_delete: false,
        };
        if (obj) {
          newData?.push(obj);
          setNewData([...newData]);
        }
      });
    const selected_permission = newData.filter((element: any) => {
      return (
        element.is_view === true ||
        element.is_edit === true ||
        element.is_add === true
      );
    });
    const body = {
      name,
      role_permissions: selected_permission,
    };
    console.log(body);
    try {
      let response = null;
      const isUpdate = id && id !== "add";

      response = await (isUpdate
        ? updateRole({ role_id: `${id || ""}`, body }).unwrap()
        : createRole({ body }).unwrap());

      if (response?.code === 200) {
        showToast(
          isUpdate ? "Role updated successfully" : "Role created successfully"
        );
        navigate(-1);
      } else {
        showError(response?.message || "Something went wrong");
      }
      console.log(response);
    } catch (error: any) {
      console.log(error);
      showError(error?.body?.message || "Something went wrong");
    }
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }: any) => {
    console.log(
      values,
      errorFields,
      outOfDate,
      "values, errorFields, outOfDate"
    );
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Modules",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "Add",
      dataIndex: "",
      key: "",
      render: (record: any) => {
        return (
          <Checkbox
            onChange={() => onSelectChange(record, "add")}
            checked={record.isAdd || false}
          />
        );
      },
    },
    {
      title: "Edit",
      dataIndex: "",
      key: "",
      render: (record: any) => {
        return (
          <Checkbox
            onChange={() => onSelectChange(record, "edit")}
            checked={record.isEdit || false}
          />
        );
      },
    },
    {
      title: "View",
      dataIndex: "",
      key: "",
      render: (record: any) => {
        return (
          <Checkbox
            onChange={() => onSelectChange(record, "view")}
            checked={record.isView || false}
          />
        );
      },
    },
  ];

  return (
    <div className="module-container">
      <PageLoader isLoading={addLoader?.isLoading || editLoader?.isLoading} />
      <Card>
        <div style={{ width: "100%", display: "flex" }}>
          <Button
            style={{
              background: "#0C163F",
              width: 100,
              height: 40,
              marginLeft: "auto",
            }}
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
        <Form
          name="basic"
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item
                name="role-name"
                rules={[
                  {
                    required: true,
                    message: "Please input role name.",
                  },
                  {
                    min: name?.length ? 1 : 2,
                    message: "Please input role name.",
                  },
                ]}
              >
                <div>
                  <h4>Role Name</h4>
                  <Input
                    value={name}
                    onChange={(val) => {
                      if (isValidInput(val.target.value)) {
                        setName(val.target.value);
                      }
                    }}
                    placeholder="Role Name"
                    style={{ height: 55 }}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} md={24}>
              <Table
                rowKey={"id"}
                style={{ marginTop: 0, marginBottom: 20 }}
                pagination={false}
                dataSource={permissions || []}
                columns={columns}
              />
            </Col>
          </Row>

          <div className="submit-btn-wrapper">
            <Button
              style={{
                background: "#0C163F",
                width: 200,
                height: 50,
              }}
              type="primary"
              htmlType="submit"
              // onClick={() => {
              //   navigate("/manage-roles", { replace: true });
              // }}
            >
              {id && id !== "add" ? "Update" : "Add"}
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default RolesForm;
