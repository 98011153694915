/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect } from "react";
import { Dropdown, Layout, MenuProps } from "antd";
import {
  CaretDownOutlined,
  UserOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { useLazyGetUserQuery } from "../services/auth";
import { useDispatch } from "react-redux";
import {
  resetAuth,
  setCredentials,
  setPermissions,
} from "../features/auth/authSlice";
import { getFromStorage, removeFromStorage } from "../helpers/storage";
import { STORAGE_KEYS } from "../helpers/constants/storageKeys";

const HeaderBar = ({
  collapsed,
  setCollapsed,
}: {
  collapsed: boolean;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [getUser] = useLazyGetUserQuery();

  const user = useAuth();

  const getUserDetails = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    try {
      const result = await getUser({}).unwrap();
      if (result?.code === 200 && token) {
        dispatch(
          setCredentials({
            user: result?.data,
            token: token,
          })
        );
        if (result?.data?.user_role?.permissions?.length) {
          dispatch(setPermissions(result?.data?.user_role?.permissions));
        }
      }
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!user) {
      getUserDetails();
    }
  }, [user]);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <span>Profile</span>,
      icon: <UserOutlined />,
      onClick: () => navigate("/profile"),
    },
    {
      key: "2",
      label: <span>Logout</span>,
      icon: <LogoutOutlined />,
      onClick: () => {
        removeFromStorage(STORAGE_KEYS.token);
        dispatch(resetAuth());
        navigate("/", { replace: true });
      },
    },
  ];
  const { Header } = Layout;

  return (
    <Header style={{ padding: 0 }}>
      <div className="headermain">
        <div
          style={{ cursor: "pointer" }}
          onClick={() => setCollapsed(!collapsed)}
        ></div>
        <div className="header-container">
          <div className="header-img-wrapper">
            <img
              className="header-img"
              src={
                user?.image?.media_file_url ||
                "https://visualpharm.com/assets/381/Admin-595b40b65ba036ed117d3b23.svg"
              }
              alt=""
            />
          </div>
          <div onClick={(e) => e.preventDefault()} className="dropdown-wrapper">
            <Dropdown menu={{ items }}>
              <div>
                <div style={{ height: 20 }}>
                  {(user?.first_name || "User") + " " + (user?.last_name || "")}
                  <CaretDownOutlined />
                </div>
                <div>{user?.user_role?.name || "Admin"}</div>
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
    </Header>
  );
};

export default HeaderBar;
