/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Input, Rate, Row, Space, Switch, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import {
  useGetRatingsMutation,
  useUpdateRatingsByIdMutation,
} from "../../../services/main";
import { Permissions, Reviews } from "../../../types/General";
import { isString } from "../../../utils/validations";
import { showError, showToast } from "../../../helpers/toast";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import usePermissions from "../../../hooks/permissions";
import { SideBarIds } from "../../../utils/constants";

const ApprovedReviews = () => {
  const user = useAuth();
  const navigate = useNavigate();
  const permissions = usePermissions();

  const [getReviewList] = useGetRatingsMutation();
  const [UpdateRating] = useUpdateRatingsByIdMutation();

  const [page, setPage] = useState(1);
  const [reviews, setReviews] = useState<Reviews[]>([]);
  const [total, setTotal] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const [modulePermissions, setModulePermissions] = useState<
    Permissions | null | undefined
  >(null);

  useEffect(() => {
    if (user && user?.role !== 1) {
      if (permissions) {
        let idx = -1;
        idx = permissions?.findIndex(
          (ele) => ele?.module?.id === SideBarIds.reviewAndRatings
        );
        if (idx > -1) {
          setModulePermissions(permissions[idx]);
        } else {
          navigate(-1);
        }
      }
    }
  }, [permissions, user]);

  const updateStatus = async (id: string, state: boolean) => {
    const response = await UpdateRating({
      review_id: id,
      body: {
        is_active: state,
      },
    }).unwrap();
    if (response?.code === 200) {
      const updatedData = reviews?.map((x) => {
        if (x.id === parseInt(id, 10)) {
          return {
            ...x,
            is_active: state,
          };
        }
        return x;
      });
      setReviews(updatedData);
      showToast("Reviews updated successfully");
    } else {
      showError("Please try again");
    }
  };

  const renderSwitch = (_: any, data: Reviews) => {
    return (
      <Switch
        onChange={(val) => updateStatus(`${data?.id}`, val)}
        size="small"
        checked={data?.is_active}
      />
    );
  };

  const getReviews = async () => {
    try {
      const body = {
        start: page,
        length: 10,
        search: {
          value: debouncedSearchTerm.trim(),
          regex: false,
        },
      };
      const response = await getReviewList({ body }).unwrap();
      console.log(response);
      if (response?.code === 200) {
        setReviews(response?.data || []);
        setTotal(response?.recordsTotal || 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (modulePermissions || user?.role === 1) {
      getReviews();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, debouncedSearchTerm, modulePermissions]);

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [searchTerm]);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const columns: ColumnsType<Reviews> = [
    {
      title: "S.no.",
      key: "sno",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <div>
          {`${record?.user?.first_name || ""} ${record?.user?.last_name || ""}`}
        </div>
      ),
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      render: (_, record) => <div>{record?.product?.product_name || ""}</div>,
    },
    {
      title: "Review",
      dataIndex: "review",
      key: "review",
    },
    {
      title: "Rating",
      key: "rating",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Rate allowHalf value={record.rating} />
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: renderSwitch,
      className: modulePermissions?.is_edit || user?.role === 1 ? "" : "hidden",
    },
  ];

  return (
    <div>
      <div className="srch">
        <Row gutter={16}>
          <Col xs={24} md={7}>
            <Input
              value={searchTerm}
              onChange={(val) => {
                if (isString(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
              allowClear
              placeholder="Search"
              prefix={<SearchOutlined style={{ fontSize: "20px" }} />}
              // style={{ height: 55, borderRadius: 10, minWidth: "360px" }}
              className="search-bar"
            />
          </Col>
        </Row>
        <Table
          className="table-container"
          columns={columns}
          dataSource={reviews || []}
          rowKey={"id"}
          pagination={{
            defaultPageSize: 10,
            current: page,
            onChange: onPageChange,
            total,
          }}
        />
      </div>
    </div>
  );
};

export default ApprovedReviews;
