import { Card } from "antd";
import { ContentHeader } from "../../components";
import ApprovedReviews from "./types/Approved";

const Reviews = () => {
  return (
    <div className="module-container">
      <ContentHeader
        heading="Manage Reviews"
        buttonNavigateTo="manage-reviews/add"
      />
      <Card>
        <ApprovedReviews />
      </Card>
    </div>
  );
};

export default Reviews;
