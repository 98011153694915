import { Button, Card, Col, Form, Input, Row } from "antd";
import { ContentHeader, PageLoader } from "../../components";
import { useState } from "react";
import { showError, showToast } from "../../helpers/toast";
import { usePostNotificationMutation } from "../../services/notification";

const Notifications = () => {
  const { TextArea } = Input;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [postNotificaton, { isLoading }] = usePostNotificationMutation();

  const onFinish = async (values: any) => {
    const body = {
      title: title,
      description: description,
    };
    try {
      const response = await postNotificaton(body).unwrap();
      console.log(response);
      if (response?.code === 200) {
        showToast(response?.message || "");
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error);
    }
  };

  return (
    <div className="module-container">
      <PageLoader isLoading={isLoading} />
      <ContentHeader
        heading="Notifications"
        buttonText="View History"
        buttonNavigateTo="notifications/history"
      />
      <Card>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Please input Title!",
                    whitespace: true,
                  },
                ]}
              >
                <div>
                  <h4>Title</h4>
                  <Input
                    placeholder="Title"
                    style={{ height: 55 }}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please input Description!",
                    whitespace: true,
                  },
                ]}
              >
                <div>
                  <h4>Description</h4>
                  <TextArea
                    rows={8}
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>

          <Button
            style={{
              background: "#0C163F",
              width: 100,
              height: 40,
              marginTop: 30,
            }}
            type="primary"
            htmlType="submit"
          >
            Send
          </Button>
        </Form>
      </Card>
    </div>
  );
};

export default Notifications;
