import { Button, Card, Col, Row } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { PageLoader } from "../../../components";
import { useLazyGetCompanyGroupDetailsQuery } from "../../../services/company";

const GroupDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [getCompanyDetails, { isLoading }] =
    useLazyGetCompanyGroupDetailsQuery();

  const [groupName, setGroupName] = useState("");
  const [companies, setCompanies] = useState<{ name: string; id: number }[]>(
    []
  );

  const getDetails = async (id: string) => {
    try {
      const response = await getCompanyDetails({ id }).unwrap();
      console.log(response);
      if (response?.code === 200) {
        const data = response?.data;
        if (data) {
          setGroupName(data?.name || "");
          setCompanies(data?.companies || []);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // if (user && user?.role !== 1) {
    //   if (permissions) {
    //     let idx = -1;
    //     idx = permissions?.findIndex(
    //       (ele) => ele?.module?.id === SideBarIds.companies
    //     );
    //     if (idx > -1) {
    //       if (id && id !== "add") {
    //         getDetails(id);
    //       }
    //     } else {
    //       navigate(-1);
    //     }
    //   }
    // } else if (user?.role === 1) {
    if (id && id !== "add") {
      getDetails(id);
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="module-details-container">
      <PageLoader isLoading={isLoading} />
      <Card>
        <div className="detail-module-card">
          <h2>Company Group Details</h2>
          <Button
            className="back-btn-container"
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigate("/manage-companies", {
                replace: true,
                state: { tab: state?.tab || "1" },
              });
            }}
          >
            Back
          </Button>
        </div>
        <div>
          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Company Group Name</h4>
              <h5>{groupName || ""}</h5>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Companies</h4>
            </Col>
          </Row>

          {companies?.map((item, index) => (
            <Row gutter={16} key={index}>
              <Col xs={20} md={8}>
                <h5>{`${index + 1}. ${item.name || ""}`}</h5>
              </Col>
            </Row>
          ))}
        </div>
      </Card>
    </div>
  );
};
export default GroupDetails;
