import { Form, Input, Row, Col, Button, Card } from "antd";
import {
  //   useLazyGetCategoryByIdQuery,
  useCreateCompanyGroupMutation,
  useLazyGetCompanyGroupDetailsQuery,
  useUpdateCompanyGroupMutation,
} from "../../../services/company";
import { showError, showToast } from "../../../helpers/toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { isValidInput } from "../../../utils/validations";

const CompanyGroupForm = (props: any) => {
  const [form] = Form.useForm();
  const { id, tab } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const url = window.location.pathname;
  const path = url.includes("add");

  const [createCompanyGroup, addLoader] = useCreateCompanyGroupMutation();
  const [getCompanyGroupById] = useLazyGetCompanyGroupDetailsQuery();
  const [updateCompanyGroupById, editLoader] = useUpdateCompanyGroupMutation();

  const [name, setName] = useState("");

  const getCategoryDetails = async (id: string) => {
    const response = await getCompanyGroupById({ id }).unwrap();
    if (response?.code === 200) {
      setName(response?.data?.name);
      form.setFieldsValue({
        group_name: response?.data?.name,
      });
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getCategoryDetails(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onFinish = async (values: any) => {
    console.log("Received values of form: ", values);
    try {
      let response = null;
      const body = {
        group_name: name,
      };
      if (id && id !== "add") {
        response = await updateCompanyGroupById({
          group_id: id,
          body,
        }).unwrap();
      } else {
        response = await createCompanyGroup(body).unwrap();
      }
      console.log(response);
      if (response?.code === 201 || response?.code === 200) {
        showToast(response?.message || "");
        navigate("/manage-companies", {
          replace: true,
          state: { tab: tab || "1" },
        });
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error);
    }
  };
  const onFinishFailed = ({ values, errorFields, outOfDate }: any) => {
    console.log(
      values,
      errorFields,
      outOfDate,
      "values, errorFields, outOfDate"
    );
  };

  return (
    <div className="module-container">
      <Card>
        <div className="detail-module-card">
          <h2>{!path ? "Edit " : "Add "}Group</h2>
        </div>
        <div>
          <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="group_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter group name.",
                    },
                    {
                      min: name?.length ? 1 : 2,
                      message: "Please enter group name.",
                    },
                  ]}
                >
                  <div>
                    <h4>Name</h4>
                    <Input
                      value={name}
                      onChange={(val) => {
                        if (isValidInput(val.target.value)) {
                          setName(val.target.value);
                        }
                      }}
                      placeholder="Name"
                      style={{ height: 55 }}
                      maxLength={50}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <div className="submit-btn-wrapper">
              <Button
                className="submit-btn-container"
                type="primary"
                htmlType="submit"
                loading={addLoader?.isLoading || editLoader?.isLoading}
              >
                {!path ? "Update" : "Add"}
              </Button>
            </div>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default CompanyGroupForm;
