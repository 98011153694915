import { Button, Card, Col, Row } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetCategoryByIdQuery } from "../../../services/main";
import { useEffect, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import usePermissions from "../../../hooks/permissions";
import { SideBarIds } from "../../../utils/constants";

const CategoryDetails = () => {
  const user = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const permissions = usePermissions();

  const [getCategoryById] = useLazyGetCategoryByIdQuery();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [imagePreview, setImagePreview] = useState("");

  const getCategoryDetails = async (id: string) => {
    const response = await getCategoryById({ category_id: id }).unwrap();
    if (response?.code === 200) {
      setName(response?.data?.name);
      setDescription(response?.data?.description);
      setImagePreview(response?.data?.category_image?.media_file_url);
    }
  };

  useEffect(() => {
    if (user && user?.role !== 1) {
      if (permissions) {
        let idx = -1;
        idx = permissions?.findIndex(
          (ele) => ele?.module?.id === SideBarIds.category
        );
        if (idx > -1) {
          if (id && id !== "add") {
            getCategoryDetails(id);
          }
        } else {
          navigate(-1);
        }
      }
    } else if (user?.role === 1) {
      if (id && id !== "add") {
        getCategoryDetails(id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, user, id]);

  return (
    <div className="module-details-container">
      <Card className="product_details">
        <div className="detail-module-card">
          <h2>Category Details</h2>
          <Button
            className="back-btn-container"
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigate("/manage-categories", { replace: true });
            }}
          >
            Back
          </Button>
        </div>

        <div>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <h4>Image</h4>
              <div
                style={{
                  display: "flex",
                }}
              >
                <img className="image-preview" src={imagePreview} alt="" />
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Category Name</h4>
              <h5>{name || ""}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Description</h4>
              <h5>{description || ""}</h5>
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};
export default CategoryDetails;
