import { Button, Card, Col, Row } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetUserIdQuery } from "../../../services/main";
import { useEffect, useState } from "react";
import { User } from "../../../types/General";
import { showError } from "../../../helpers/toast";
import { SideBarIds } from "../../../utils/constants";
import useAuth from "../../../hooks/useAuth";
import usePermissions from "../../../hooks/permissions";

const UserDetails = () => {
  const user = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const permissions = usePermissions();
  const [getUserById] = useLazyGetUserIdQuery();

  const [userData, setUserData] = useState<User | null>(null);

  const getUserDetail = async (id: string) => {
    try {
      const response = await getUserById({ user_id: id }).unwrap();
      if (response?.code === 200) {
        setUserData(response?.data);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (user && user?.role !== 1) {
      if (permissions) {
        let idx = -1;
        idx = permissions?.findIndex(
          (ele) => ele?.module?.id === SideBarIds.users
        );
        if (idx > -1) {
          if (id && id !== "add") {
            getUserDetail(id);
          }
        } else {
          navigate(-1);
        }
      }
    } else if (user?.role === 1) {
      if (id && id !== "add") {
        getUserDetail(id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, user, id]);

  return (
    <div className="module-details-container">
      <Card className="product_details">
        <div className="detail-module-card">
          <h2>User Details</h2>
          <Button
            className="back-btn-container"
            type="primary"
            onClick={() => {
              navigate("/manageUsers", { replace: true });
            }}
          >
            Back
          </Button>
        </div>

        <div>
          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>First Name</h4>
              <h5>{userData?.first_name || ""}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Last Name</h4>
              <h5>{userData?.last_name || ""}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Email</h4>
              <h5>{userData?.email || ""}</h5>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Office Phone Number</h4>
              <h5>
                {`${userData?.office_country_code || ""} ${
                  userData?.office_phone_no || ""
                }`}
              </h5>
            </Col>

            <Col xs={20} md={8}>
              <h4>Company Phone Number</h4>

              <h5>
                {`${userData?.company_country_code || ""} ${
                  userData?.company_phone_no || ""
                }`}
              </h5>
            </Col>

            <Col xs={20} md={8}>
              <h4>Location</h4>
              <h5>{userData?.location || ""}</h5>
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default UserDetails;
