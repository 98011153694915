import { Button, Card, Col, Input, Row } from "antd";
import { useEffect, useState } from "react";
import { ContentHeader, PageLoader } from "../../components";
import { useLazyGetFaqQuery, usePostFaqMutation } from "../../services/cms";
import { Faqs } from "../../types/General";

const ManageFaq = () => {
  const [faqsData, { isLoading }] = useLazyGetFaqQuery();
  const [addFaqData, FaqData] = usePostFaqMutation();

  const [faqs, setFaqs] = useState<Faqs[]>([]);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const removeFaqsHandler = async (id: number | undefined) => {
    const updatedFaqs = faqs.filter((item) => item.id !== id);
    setFaqs(updatedFaqs);
    try {
      const res = await addFaqData(updatedFaqs).unwrap();
      if (res?.code === 200) {
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const getAllFaqsHandler = async () => {
    try {
      const res = await faqsData({}).unwrap();
      if (res?.code === 200) {
        setFaqs(res?.data);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleAddFaq = async () => {
    const newFaq = {
      question: question,
      answer: answer,
    };

    const updatedFaqs = [...faqs, newFaq];
    setFaqs(updatedFaqs);
    setQuestion("");
    setAnswer("");

    try {
      const res = await addFaqData(updatedFaqs).unwrap();
      if (res?.code === 200) {
        getAllFaqsHandler();
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllFaqsHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="module-container">
      <PageLoader isLoading={isLoading || FaqData?.isLoading} />
      <ContentHeader heading="Manage FAQ's" />
      <Card className="faq_card">
        <Row gutter={4}>
          <Col xs={16} md={16}>
            <h3>FAQs</h3>

            {faqs?.map((item, index) => {
              return (
                <div key={index}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-end",
                      gap: "20px",
                      width: "100%",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <div>
                        <h4>Question:</h4>
                        <Input
                          disabled={!!item?.question}
                          type={"text"}
                          name="name"
                          value={item?.question}
                          placeholder="Question"
                          style={{ height: 45, marginLeft: 0, color: "black" }}
                        ></Input>
                      </div>
                      <div style={{}}>
                        <h4>Answer:</h4>
                        <Input
                          disabled={!!item?.answer}
                          type={"text"}
                          name="name"
                          value={item?.answer}
                          placeholder="Answer"
                          style={{ height: 45, marginLeft: 0, color: "black" }}
                        ></Input>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: 20,
                    }}
                  >
                    <Button onClick={() => removeFaqsHandler(item.id)}>
                      Remove
                    </Button>
                  </div>
                </div>
              );
            })}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                gap: "20px",
              }}
            >
              <div style={{ width: "100%" }}>
                <div style={{ marginBottom: 20 }}>
                  <h4>Question:</h4>
                  <Input
                    type={"text"}
                    name="name"
                    value={question}
                    onChange={(val) => setQuestion(val.target.value)}
                    placeholder="Question"
                    style={{ height: 45, marginLeft: 0 }}
                  ></Input>
                </div>
                <div>
                  <h4>Answer:</h4>
                  <Input
                    type={"text"}
                    name="name"
                    value={answer}
                    onChange={(val) => setAnswer(val.target.value)}
                    placeholder="Answer"
                    style={{
                      height: 45,
                      marginLeft: 0,
                    }}
                  ></Input>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 20,
              }}
            >
              <Button
                style={{
                  background: "#0C163F",
                  width: 90,
                  height: 40,

                  color: "white",
                }}
                loading={isLoading || FaqData?.isLoading}
                onClick={handleAddFaq}
                disabled={!question || !answer}
              >
                Add
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ManageFaq;
