import Compressor from "compressorjs";
import { STORAGE_KEYS } from "../helpers/constants/storageKeys";
import { API_URL, END_POINTS } from "../helpers/constants/urls";
import { getFromStorage } from "../helpers/storage";

interface UploadResponse {
  code: number;
  data: any[];
  // Add other properties if necessary
}

export const UploadMedia = (imageObject: any): Promise<UploadResponse> => {
  console.log("imageObject", imageObject);
  return new Promise(async (resolve, reject) => {
    new Compressor(imageObject, {
      quality: 0.6,
      success: async (compressedResult) => {
        console.log("compressedResult", compressedResult);

        const token = await getFromStorage(STORAGE_KEYS.token);
        const formData = new FormData();
        formData.append("media", compressedResult as any);
        let headers = {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        };
        try {
          const res = await fetch(API_URL + END_POINTS.upload, {
            method: "POST",
            headers,
            body: formData,
          });
          let response = await res.json();
          resolve(response); // Resolve the Promise with the response
        } catch (error) {
          console.log(error, ">>>>>>>>>");
          reject(error); // Reject the Promise with the error
        }
      },
    });
  });
};

export const UploadDocument = async (fileObject: any) => {
  const formData = new FormData();
  formData.append("media", fileObject as any);
  const token = await getFromStorage(STORAGE_KEYS.token);

  let headers = {
    Accept: "application/json",
    Authorization: "Bearer " + token,
  };
  try {
    const res = await fetch(API_URL + END_POINTS.upload, {
      method: "POST",
      headers,
      body: formData,
    });
    let response = await res.json();
    return response;
  } catch (error) {
    console.log(error, ">>>>>>>>>");
    return error;
  }
};
