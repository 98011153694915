/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Table,
} from "antd";
import { ContentHeader, PageLoader, TableLoader } from "../../components";
import { EditOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import {
  useAssignTeamToWarehouseMutation,
  useLazyGetSubAdminListQuery,
  useLazyGetWarehouseQuery,
  useUpdateWarehouseStatusMutation,
} from "../../services/warehouse";
import { useEffect, useState } from "react";
import { showError, showToast } from "../../helpers/toast";
import { Permissions, Warehouse } from "../../types/General";
import usePermissions from "../../hooks/permissions";
import { SideBarIds } from "../../utils/constants";
import useAuth from "../../hooks/useAuth";
import { isValidInput } from "../../utils/validations";

const { Option } = Select;

const WareHouse = () => {
  const user = useAuth();
  const navigate = useNavigate();
  const permissions = usePermissions();

  const [getWarehouseMutation, { isLoading }] = useLazyGetWarehouseQuery();
  const [updateWarehouseStatus, updateWarehouseStatusLoader] =
    useUpdateWarehouseStatusMutation();
  const [getQcsList] = useLazyGetSubAdminListQuery();
  const [assignTeamToWarehouse, assignTeamToWarehouseLoader] =
    useAssignTeamToWarehouseMutation();

  const [warehouse, setWarehouse] = useState<Warehouse[]>([]);
  const [qcs, setQcs] = useState<
    { first_name: string; last_name: string; id: number }[]
  >([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [modulePermissions, setModulePermissions] = useState<
    Permissions | null | undefined
  >(null);

  useEffect(() => {
    if (user && user?.role !== 1) {
      if (permissions) {
        let idx = -1;
        idx = permissions?.findIndex(
          (ele) => ele?.module?.id === SideBarIds.warehouse
        );
        if (idx > -1) {
          setModulePermissions(permissions[idx]);
        } else {
          navigate(-1);
        }
      }
    }
  }, [permissions, user]);

  const updateStatus = async (id: string, state: boolean) => {
    try {
      const response = await updateWarehouseStatus({
        warehouse_id: id,
        body: {
          is_active: state,
        },
      }).unwrap();
      if (response?.code === 200) {
        const updatedData = warehouse.map((x) => {
          if (x.id === parseInt(id, 10)) {
            return {
              ...x,
              is_active: state,
            };
          }
          return x;
        });
        setWarehouse(updatedData);
        showToast("Status updated successfully");
      } else {
        showError("Please try again");
      }
    } catch (error) {
      console.log(error);
      showError("Please try again");
    }
  };

  const getWarehouse = async () => {
    try {
      const body = {
        start: page,
        length: 10,
        is_approved: true,
        search: {
          value: debouncedSearchTerm.trim(),
          regex: false,
        },
      };
      const response = await getWarehouseMutation({ body: body }).unwrap();
      if (response?.code === 200) {
        setWarehouse(response?.data || []);
        setTotal(response?.recordsTotal || 1);
      } else {
        setWarehouse([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const assignTeam = async (id: number, members: number[]) => {
    console.log(id, members);
    const body = {
      user_id: members,
      warehouse_id: id,
    };
    try {
      const response = await assignTeamToWarehouse(body).unwrap();
      console.log(response);
      if (response?.code === 200) {
        showToast(response?.message || "");
      } else {
        showError("Please try again");
      }
    } catch (error) {
      console.log(error);
      showError("Please try again");
    }
  };

  const getQcList = async () => {
    try {
      const response = await getQcsList({}).unwrap();
      console.log(response);
      if (response?.code === 200) {
        setQcs(response?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getQcList();
  }, []);

  useEffect(() => {
    getWarehouse();
  }, [page, debouncedSearchTerm]);

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [searchTerm]);

  const renderSwitch = (_: any, data: Warehouse) => (
    <Switch
      onChange={(val) => updateStatus(`${data?.id}`, val)}
      size="small"
      checked={data?.is_active}
    />
  );

  const columns: ColumnsType<Warehouse> = [
    {
      title: "S.no.",
      key: "sno",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_no",
      key: "phone_no",
      align: "center",
      render: (_, record) => (
        <div>{`${record?.country_code || ""}${record?.phone_no || ""}`}</div>
      ),
    },
    {
      title: "Assign Team",
      key: "status",
      align: "center",
      render: (_, record) => (
        <Select
          mode="multiple"
          style={{ width: 200, height: 52 }}
          value={record?.qc_assigned || []}
          onChange={(val) => {
            console.log(`qc-id ${val} product ${record?.id}`);
            const newArr = [...warehouse];
            let idx = -1;
            idx = warehouse?.findIndex((ele) => ele.id === record.id);
            if (idx > -1) {
              newArr[idx] = {
                ...newArr[idx],
                qc_assigned: val,
              };
            }
            setWarehouse([...newArr]);
          }}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: "8px 0" }} />
              <Space style={{ padding: "0 8px 4px" }}>
                <Button
                  type="primary"
                  onClick={() => {
                    if (record?.id && record?.qc_assigned) {
                      assignTeam(record.id, record.qc_assigned);
                    }
                  }}
                >
                  Save
                </Button>
              </Space>
            </>
          )}
          placeholder={"Assign Team"}
        >
          {qcs?.map((item, index) => (
            <Option key={index} value={item?.id}>{`${item?.first_name || ""} ${
              item?.last_name || ""
            }`}</Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manage-warehouse/detail/${record?.id}`);
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>

          {modulePermissions?.is_edit || user?.role === 1 ? (
            <Button
              // onClick={(e) => {
              //   e.preventDefault();
              //   navigate(`/manage-warehouse/${record?.id}`);
              // }}
              onClick={(e) => {
                e.preventDefault();
                window.open(`/manage-warehouse/${record?.id}`, "blank");
              }}
            >
              <EditOutlined color="#08c" />
            </Button>
          ) : null}
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: renderSwitch,
      className: modulePermissions?.is_edit || user?.role === 1 ? "" : "hidden",
    },
  ];

  return (
    <div className="module-container">
      <PageLoader
        isLoading={
          assignTeamToWarehouseLoader?.isLoading ||
          updateWarehouseStatusLoader?.isLoading
        }
      />
      <ContentHeader
        heading="Manage Warehouse"
        buttonNavigateTo="manage-warehouse/add"
        buttonText="Add"
        modulePermissions={modulePermissions}
      />
      <Card>
        <div className="srch">
          <Row gutter={16}>
            <Col xs={24} md={7}>
              <Input
                value={searchTerm}
                onChange={(val) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
                placeholder="Search Warehouse"
                prefix={<SearchOutlined style={{ fontSize: "20px" }} />}
                // style={{ height: 55, borderRadius: 10, minWidth: "360px" }}
                className="search-bar"
                allowClear
              />
            </Col>
          </Row>
          {isLoading ? (
            <TableLoader />
          ) : (
            <Table
              className="table-container"
              columns={columns}
              dataSource={warehouse || []}
              pagination={{
                defaultPageSize: 10,
                current: page,
                onChange: onPageChange,
                total,
              }}
            />
          )}
        </div>
      </Card>
    </div>
  );
};

export default WareHouse;
