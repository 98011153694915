import { END_POINTS } from "../helpers/constants/urls";
import { Cms, Faqs, Graph } from "../types/General";
import emptySplitApi from "../utils/rtk";

type GetCmsResponse = {
  code: number;
  message: string;
  data: Cms;
};

type PostCmsResponse = {
  code: number;
  message: string;
  data: null;
};

type PostCmsBody = {
  terms_and_conditions: string;
  privacy: string;
  about_us: string;
  legal: string;
  cms_email: string;
  cms_phone: string;
  cms_country_code: string;
  cms_country_iso_code: string;
};

type FaqResponse = {
  code: number;
  message: string;
  data: Faqs[];
};

type GraphBody = {
  filter_type: number;
};

type GraphResponse = {
  code: number;
  message: string;
  data: Graph[];
};

type RevenueGraphResponse = {
  code: number;
  message: string;
  data: {
    [key: number]: number;
  };
};

export const CmsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCms: builder.query<GetCmsResponse, {}>({
      query: () => ({
        url: END_POINTS.get_cms,
        method: "GET",
      }),
    }),
    postCms: builder.mutation<PostCmsResponse, PostCmsBody>({
      query: (body) => ({
        url: END_POINTS.post_cms,
        method: "POST",
        body,
      }),
    }),
    getFaq: builder.query<FaqResponse, {}>({
      query: () => ({
        url: END_POINTS.get_faq,
        method: "GET",
      }),
    }),
    postFaq: builder.mutation<PostCmsResponse, Faqs[]>({
      query: (body) => ({
        url: END_POINTS.post_faq,
        method: "POST",
        body,
      }),
    }),
    postGraph: builder.mutation<GraphResponse, GraphBody>({
      query: (body) => ({
        url: END_POINTS.user_graph,
        method: "POST",
        body,
      }),
    }),
    postRevenueGraph: builder.mutation<RevenueGraphResponse, GraphBody>({
      query: (body) => ({
        url: END_POINTS.revenue_graph,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetCmsQuery,
  usePostCmsMutation,
  useLazyGetFaqQuery,
  usePostFaqMutation,
  usePostGraphMutation,
  usePostRevenueGraphMutation,
} = CmsApi;
