import "./otp.css";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AuthWrapper from "../../../layout/authWrapper";

import { Button, Card, Form, FormInstance } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import OTPInput from "otp-input-react";
import {
  usePostForgotPasswordMutation,
  useVerifyOtpMutation,
} from "../../../services/auth";
import { showError, showToast } from "../../../helpers/toast";

const VerifyOtp = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { state } = location;

  const formRef = useRef<FormInstance>(null);

  const [handleVerifyOtp] = useVerifyOtpMutation();
  const [forgotPasswordMutation] = usePostForgotPasswordMutation();

  const [OTP, setOTP] = useState("");
  const [loading, setloading] = useState(false);
  const [countDown, setCountDown] = useState(59);

  const onFinish = async (values: any) => {
    console.log(values, "valuessss");
    const body = {
      email: state,
      otp: values.otp,
    };
    try {
      setloading(true);
      const response = await handleVerifyOtp(body).unwrap();
      console.log(response);
      if (response?.code === 200) {
        showToast(response?.message || "");
        navigate("/reset-password", { state: response?.data });
      }
      setloading(false);
    } catch (error: any) {
      showError(error?.data?.message || "");
      setloading(false);
    }
  };
  const handleResend = async () => {
    try {
      let body = {
        email: state,
      };
      const response = await forgotPasswordMutation(body).unwrap();
      console.log(response);
      if (response?.code === 200) {
        showToast(response?.message || "");
        setCountDown(59);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error);
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Form error:", errorInfo);
  };

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);

  return (
    <AuthWrapper>
      <Card className="otp_card">
        <div className="auth_header" onClick={() => navigate(-1)}>
          <ArrowLeftOutlined
            style={{
              fontSize: 20,
              cursor: "pointer",
              position: "absolute",
              left: "40px",
            }}
          />
          <h3>Verify Otp</h3>
        </div>
        <div style={{ marginTop: "20px" }} className="login_img">
          <img src="/static/images/logo.png" alt="" />
        </div>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{
            maxWidth: 600,
            marginTop: 20,
            marginBottom: 20,
          }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          ref={formRef}
          autoComplete="off"
        >
          <Form.Item
            className="otp_inpy"
            rules={[
              {
                required: true,
                message: "Please input your otp",
              },
              {
                min: 4,
                message: "Please enter 4 digit otp",
              },
            ]}
            name="otp"
          >
            <OTPInput
              value={OTP}
              onChange={setOTP}
              autoFocus={false}
              OTPLength={4}
              otpType="number"
              disabled={false}
              className="otp_inpt"
              inputClassName="inpt_otpcls"
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <div style={{ display: "flex" }}>
              <Button
                style={{
                  background: "#0C163F",
                  width: 200,
                  height: 50,
                  margin: "auto",
                }}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Verify Otp
              </Button>
            </div>
          </Form.Item>
        </Form>
        {countDown === 0 ? (
          <div className="rsnd_btn">
            <h4
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={handleResend}
              className="btn_rd"
            >
              Resend OTP
            </h4>
          </div>
        ) : (
          <h5 style={{ textAlign: "center" }}>
            The verify code will expire in
            <span className="count">
              {" "}
              00 : {countDown < 10 ? `0${countDown}` : countDown}
            </span>
          </h5>
        )}
      </Card>
    </AuthWrapper>
  );
};
export default VerifyOtp;
