import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";

export default function PageNotFound() {
  return (
    <main>
      <div className="container">
        <div className="pagemain">
          <div className="not_found">
            <div className="w_50">
              <figure>
                <img src="/static/images/not_found.jpg" alt="" />
              </figure>
            </div>
            <div className="w_50">
              <h1>404</h1>

              <h2>Page Not Found</h2>
              <p>
                The page you are looking for does not exist. How you got here is
                a mystery. But you can click the button below to go back to the
                homepage.
              </p>
              <Link to="/dashboard">
                <Button className="page_btn">Dashboard</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
