import { Button, Card, Col, Row, Table } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { showError } from "../../../helpers/toast";
import { Role, SubAmin } from "../../../types/General";
import { useEffect, useState } from "react";
import {
  useLazyGetRoleByIdQuery,
  useLazyGetSubAdminByIdQuery,
} from "../../../services/main";
import { SideBarIds } from "../../../utils/constants";
import useAuth from "../../../hooks/useAuth";
import usePermissions from "../../../hooks/permissions";

const SubAdminDetails = () => {
  const user = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const permissions = usePermissions();

  const [SubAdminById] = useLazyGetSubAdminByIdQuery();
  const [getRoleDetails] = useLazyGetRoleByIdQuery();
  const [imagePreview, setImagePreview] = useState("");
  console.log(imagePreview, "0000");

  const [subAdmin, setSubAdmin] = useState<SubAmin | null>(null);
  const [roleDetails, setRoleDetails] = useState<Role | null>(null);

  const getRoleById = async (id: number) => {
    try {
      const response = await getRoleDetails({
        role_id: `${id || ""}`,
      }).unwrap();
      if (response?.code === 200) {
        setRoleDetails(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const subAdminDetail = async (id: string) => {
    try {
      const response = await SubAdminById({ subAdmin_id: id }).unwrap();
      if (response?.code === 200) {
        setSubAdmin(response?.data);
        getRoleById(response?.data?.user_role?.id);
        setImagePreview(response?.data?.image?.media_file_url);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (user && user?.role !== 1) {
      if (permissions) {
        let idx = -1;
        idx = permissions?.findIndex(
          (ele) => ele?.module?.id === SideBarIds.subAdmin
        );
        if (idx > -1) {
          if (id && id !== "add") {
            subAdminDetail(id);
          }
        } else {
          navigate(-1);
        }
      }
    } else if (user?.role === 1) {
      if (id && id !== "add") {
        subAdminDetail(id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, user, id]);

  const columns: ColumnsType<{
    id: number;
    is_add: boolean;
    is_edit: boolean;
    is_view: boolean;
    module: {
      id: number;
      name: string;
    };
  }> = [
    {
      title: "Modules",
      dataIndex: "label",
      key: "label",
      render: (_, record) => {
        return <div>{record?.module?.name || ""}</div>;
      },
    },
    {
      title: "Add",
      dataIndex: "",
      key: "",
      render: (_, record) => {
        return (
          <div>
            {record?.is_add ? (
              <CheckOutlined style={{ color: "green" }} />
            ) : (
              <CloseOutlined style={{ color: "red" }} />
            )}
          </div>
        );
      },
    },
    {
      title: "Edit",
      dataIndex: "",
      key: "",
      render: (_, record) => {
        return (
          <div>
            {record?.is_edit ? (
              <CheckOutlined style={{ color: "green" }} />
            ) : (
              <CloseOutlined style={{ color: "red" }} />
            )}
          </div>
        );
      },
    },
    {
      title: "View",
      dataIndex: "",
      key: "",
      render: (_, record) => {
        return (
          <div>
            {record?.is_view ? (
              <CheckOutlined style={{ color: "green" }} />
            ) : (
              <CloseOutlined style={{ color: "red" }} />
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="module-details-container">
      <Card className="product_details">
        <div className="detail-module-card">
          <h2>Sub-Admin Details</h2>
          <Button
            className="back-btn-container"
            type="primary"
            onClick={() => {
              navigate("/managesubadmins", { replace: true });
            }}
          >
            Back
          </Button>
        </div>

        <div>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <h4>Profile Image</h4>
              <div
                style={{
                  display: "flex",
                }}
              >
                <img
                  style={{
                    height: 100,
                    width: 100,
                  }}
                  src={imagePreview || "/static/images/dummy.jpeg"}
                  alt=""
                />
              </div>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Full Name</h4>
              <h5>{`${subAdmin?.first_name} ${subAdmin?.last_name}` || ""}</h5>
            </Col>

            <Col xs={20} md={8}>
              <h4>Email</h4>
              <h5>{subAdmin?.email || ""}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Phone Number</h4>
              <h5>{`${subAdmin?.office_country_code || ""} ${
                subAdmin?.office_phone_no || ""
              }`}</h5>
            </Col>
          </Row>

          <Row gutter={16}>
            {/* <Col xs={20} md={8}>
              <h4>Address</h4>
              <h5>C-127, phase-8, 3rd floor</h5>
            </Col> */}
            <Col xs={20} md={8}>
              <h4>Role</h4>
              <h5>{subAdmin?.user_role?.name || ""}</h5>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} md={24}>
              <Table
                style={{ marginTop: 0, marginBottom: 0 }}
                pagination={false}
                dataSource={roleDetails?.role_permissions || []}
                columns={columns}
              />
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default SubAdminDetails;
