import AuthWrapper from "../../../layout/authWrapper";
import "../login/login.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { usePostForgotPasswordMutation } from "../../../services/auth";
import { showError, showToast } from "../../../helpers/toast";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [forgotPasswordMutation, { isLoading }] =
    usePostForgotPasswordMutation();

  const onFinish = async (values: any) => {
    console.log("Received values of form: ", values);
    try {
      const response = await forgotPasswordMutation(values).unwrap();
      console.log(response);
      if (response?.code === 200) {
        showToast(response?.message || "");
        navigate("/verify-otp", { state: values?.email });
      } else {
        showError(response?.message || "");
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error);
    }
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }: any) => {
    console.log(
      values,
      errorFields,
      outOfDate,
      "values, errorFields, outOfDate"
    );
  };

  return (
    <AuthWrapper>
      <Card className="login_card">
        <div onClick={() => navigate(-1)}>
          <ArrowLeftOutlined style={{ fontSize: 20, cursor: "pointer" }} />
        </div>
        <div className="login_img">
          <img src="/static/images/logo.png" alt="" />
        </div>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{
            maxWidth: 600,
            marginTop: 50,
          }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            className="validate"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input
              style={{ width: "400px", height: "50px" }}
              placeholder="Email"
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <div style={{ display: "flex" }}>
              <Button
                style={{
                  background: "#0C163F",
                  width: 200,
                  height: 50,
                  margin: "auto",
                }}
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                Send
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </AuthWrapper>
  );
};

export default ForgetPassword;
