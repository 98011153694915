import { END_POINTS } from "../helpers/constants/urls";
import { Company, Paginated } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CompanyResponse = {
  code: number;
  message: string;
  data: Company[];
  recordsTotal: number;
  pending_compay_obj_count: number;
};

type CompanyDetailsResponse = {
  code: number;
  message: string;
  data: Company;
};

type GetCompaniesPayload = {
  start: number;
  is_unapproved?: boolean;
  is_approved?: boolean;
  length: number;
  search?: Paginated;
};

export const companyApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompanies: builder.mutation<CompanyResponse, GetCompaniesPayload>({
      query: (body) => ({
        url: END_POINTS.get_companies,
        method: "POST",
        body,
      }),
    }),
    getCompanyDetails: builder.query<CompanyDetailsResponse, { id: string }>({
      query: ({ id }) => ({
        url: `${END_POINTS.get_company_details}${id}`,
        method: "GET",
      }),
    }),
    updateCompanyDetails: builder.mutation<
      CompanyDetailsResponse,
      { company_id: string; body: Omit<Company, "id"> }
    >({
      query: ({ company_id, body }) => ({
        url: `${END_POINTS.update_company_details}${company_id}/`,
        method: "PUT",
        body,
      }),
    }),
    updateCompanyStatus: builder.mutation<
      CompanyDetailsResponse,
      {
        company_id: string;
        body: {
          is_approved?: boolean;
          is_unapproved?: boolean;
          reject_reason?: string;
        };
      }
    >({
      query: ({ company_id, body }) => ({
        url: `${END_POINTS.update_company_status}${company_id}/`,
        method: "PUT",
        body,
      }),
    }),
    updateCompanyApprovedStatus: builder.mutation<
      CompanyDetailsResponse,
      {
        company_id: string;
        body: {
          is_active: boolean;
        };
      }
    >({
      query: ({ company_id, body }) => ({
        url: `${END_POINTS.update_company_approved_status}${company_id}/`,
        method: "PUT",
        body,
      }),
    }),
    getAllCompanyGroup: builder.mutation<
      {
        code: number;
        message: string;
        data: {
          id: number;
          name: string;
          companies: { id: number; name: string }[];
          is_active: boolean;
        }[];
        recordsTotal: number;
      },
      {
        start: number;
        length: number;
        search: Paginated;
      }
    >({
      query: (body) => ({
        url: END_POINTS.get_all_company_group,
        method: "POST",
        body,
      }),
    }),
    createCompanyGroup: builder.mutation<
      CompanyResponse,
      { group_name: string }
    >({
      query: (body) => ({
        url: END_POINTS.create_company_group,
        method: "POST",
        body,
      }),
    }),
    updateCompanyGroup: builder.mutation<
      CompanyResponse,
      { body: { group_name?: string; is_active?: boolean }; group_id: string }
    >({
      query: ({ body, group_id }) => ({
        url: `${END_POINTS.update_company_group}${group_id}/`,
        method: "PUT",
        body,
      }),
    }),
    assignCompanyGroup: builder.mutation<
      CompanyResponse,
      { group_id: string; company_id?: number; company_ids?: number[] }
    >({
      query: (body) => ({
        url: `${END_POINTS.assign_company_group}`,
        method: "PUT",
        body,
      }),
    }),
    getCompanyGroupDetails: builder.query<
      {
        data: { name: string; companies: { name: string; id: number }[] };
        code: number;
        message: string;
      },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.get_company_group_details}${id}/`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetCompaniesMutation,
  useLazyGetCompanyDetailsQuery,
  useUpdateCompanyDetailsMutation,
  useUpdateCompanyStatusMutation,
  useUpdateCompanyApprovedStatusMutation,
  useCreateCompanyGroupMutation,
  useUpdateCompanyGroupMutation,
  useAssignCompanyGroupMutation,
  useGetAllCompanyGroupMutation,
  useLazyGetCompanyGroupDetailsQuery,
} = companyApi;
