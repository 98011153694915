import { Button, Card, Col, Row } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetOrderDetailsQuery } from "../../../services/orders";
import { useEffect, useState } from "react";
import { OrderStatus } from "../../../utils/constants";

const OrderDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [orderDetails, setOrderDetails] = useState({
    orderNo: "",
    cost: "",
    commissionedCost: "",
    quantity: "",
    product: "",
    productCost: "",
    category: "",
    brand: "",
    image: "",
    status: "",
    customerName: "",
    address: "",
    state: "",
    zipcode: "",
    tokenAmount: "",
    phoneNumber: "",
    wareHouseName: "",
  });

  const [getOrderById] = useLazyGetOrderDetailsQuery();

  const getOrderDetails = async (order_id: string) => {
    try {
      const response = await getOrderById({ id: order_id }).unwrap();
      if (response?.code === 200) {
        setOrderDetails({
          ...orderDetails,
          orderNo: `${response?.data?.id || ""}`,
          cost: `${response?.data?.cost || ""}`,
          productCost: `${response?.data?.product_price || ""}`,
          commissionedCost: `${
            response?.data?.commissioned_product_cost || ""
          }`,
          quantity: `${response?.data?.quantity || ""}`,
          tokenAmount: `${response?.data?.token_amount || ""}`,
          product: response?.data?.product?.product_name || "",
          category: response?.data?.product?.product_category?.name || "",
          brand: response?.data?.product?.brand?.name || "",
          image: response?.data?.product?.product_image || "",
          status: `${response?.data?.delivery_status || ""}`,
          customerName: `${
            response?.data?.address_details?.order_first_name || ""
          } ${response?.data?.address_details?.order_last_name || ""}`,
          address: `${response?.data?.address_details?.order_address || ""}`,
          state: `${response?.data?.address_details?.order_state || ""}`,
          zipcode: `${response?.data?.address_details?.order_zipcode || ""}`,
          phoneNumber: `${
            response?.data?.address_details?.order_country_code || ""
          } ${response?.data?.address_details?.order_phone_no || ""}`,
          wareHouseName: `${
            response?.data?.warehouse_name
              ? response?.data?.warehouse_name + ","
              : "-"
          } ${response?.data?.warehouse_location || "-"}`,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getOrderDetails(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="module-details-container">
      <Card className="product_details">
        <div className="detail-module-card">
          <h2>Order Details</h2>
          <Button
            className="back-btn-container"
            type="primary"
            onClick={() => {
              navigate("/manage-orders", { replace: true });
            }}
          >
            Back
          </Button>
        </div>

        <div>
          <Row gutter={16}>
            {/* <Col xs={24} md={12}>
              <h4>Image</h4>
              <div
                style={{
                  display: "flex",
                }}
              >
                <img
                  className="image-preview"
                  src={orderDetails?.image || ""}
                  alt=""
                />
              </div>
            </Col> */}
            <Col xs={24} md={12}>
              <h4>Order Number</h4>
              <h5>#{orderDetails?.orderNo || ""}</h5>
            </Col>
            <Col xs={24} md={12}>
              <h4>Delivery status</h4>
              <h5>
                {OrderStatus[
                  OrderStatus?.findIndex(
                    (ele) => ele?.id === parseInt(orderDetails?.status),
                    10
                  )
                ]?.name || ""}
              </h5>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} md={12}>
              <h2>Shipping Details</h2>
              <h5>
                {orderDetails?.customerName
                  ? orderDetails?.customerName + ","
                  : ""}
              </h5>
              <h5>
                {orderDetails?.phoneNumber
                  ? orderDetails?.phoneNumber + ","
                  : ""}
              </h5>
              <h5>
                {orderDetails?.address ? orderDetails?.address + "," : ""}
              </h5>
              <h5>{orderDetails?.state ? orderDetails?.state + "," : ""}</h5>
              <h5>{orderDetails?.zipcode || ""}</h5>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Product</h4>
              <h5>{orderDetails?.product}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Quantity</h4>
              <h5>{orderDetails?.quantity}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Total</h4>
              <h5>{orderDetails?.commissionedCost}</h5>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Booking Amount</h4>
              <h5>{`$${orderDetails?.tokenAmount}`}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Commission</h4>
              <h5>{`$${
                parseFloat(`${orderDetails?.cost}`) -
                parseFloat(`${orderDetails?.productCost}`)
              }`}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Assigned Warehouse</h4>
              <h5>{`${orderDetails?.wareHouseName}`}</h5>
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default OrderDetailsPage;
