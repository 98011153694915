import { Button, Card, Col, Form, Input, Row } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  useLazyGetUserIdQuery,
  useUpdateUserDetailByIdMutation,
} from "../../../services/main";
import { showToast } from "../../../helpers/toast";
import useAuth from "../../../hooks/useAuth";
import usePermissions from "../../../hooks/permissions";
import { SideBarIds } from "../../../utils/constants";

const UsersForm = () => {
  const user = useAuth();
  const navigate = useNavigate();
  const permissions = usePermissions();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [getUserById] = useLazyGetUserIdQuery();
  const [editUser] = useUpdateUserDetailByIdMutation();

  const [phoneCode, setPhoneCode] = useState("+91");
  const [phoneNo, setphoneNo] = useState("");
  const [companyPhoneCode, setCompanyPhoneCode] = useState("+91");
  const [companyPhoneNo, setCompanyPhoneNo] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");

  useEffect(() => {
    if (user && user?.role !== 1) {
      if (permissions) {
        let idx = -1;
        idx = permissions?.findIndex(
          (ele) => ele?.module?.id === SideBarIds.users
        );
        if (idx > -1) {
          if (!permissions[idx]?.is_edit) {
            navigate(-1);
          }
          if (!permissions[idx]?.is_add) {
            navigate(-1);
          }
          if (id && id !== "add") {
            getUserDetail(id);
          }
        } else {
          navigate(-1);
        }
      }
    }
    if (id && id !== "add") {
      getUserDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, user, id]);

  const onFinish = async (values: any) => {
    const body = {
      first_name: firstName,
      last_name: lastName,
      location: location,
      office_country_code: phoneCode?.includes("+")
        ? phoneCode
        : `+${phoneCode}`,
      office_phone_no: phoneNo,
      company_phone_no: companyPhoneNo,
      company_country_code: companyPhoneCode?.includes("+")
        ? companyPhoneCode
        : `+${companyPhoneCode}`,
      email: email,
    };

    try {
      const response = await editUser({ body, user_id: `${id}` }).unwrap();
      showToast(response?.message);
      navigate("/manageUsers", { replace: true });
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    setphoneNo(phone?.replace(country.dialCode, ""));
    // setCountryName(country?.countryCode);
  };
  const handleChangeCompanyPhone = (phone: any, country: any) => {
    setCompanyPhoneCode(country?.dialCode);
    setCompanyPhoneNo(phone?.replace(country.dialCode, ""));
    setLocation(country?.countryCode);
  };

  const getUserDetail = async (id: string) => {
    try {
      const response = await getUserById({ user_id: id }).unwrap();
      if (response?.code === 200) {
        setPhoneCode(response?.data?.office_country_code || "91");
        setphoneNo(response?.data?.office_phone_no || "");
        setCompanyPhoneCode(response?.data?.company_country_code || "91");
        setCompanyPhoneNo(response?.data?.company_phone_no || "");
        setFirstName(response?.data?.first_name || "");
        setLastName(response?.data?.last_name || "");
        setEmail(response?.data?.email || "");
        setLocation(response?.data?.location || "");

        form.setFieldsValue({
          first_name: response?.data?.first_name || "",
          last_name: response?.data?.last_name || "",
          email: response?.data?.email || "",
          officePhone: response?.data?.office_phone_no || "",
          companyPhone: response?.data?.company_phone_no || "",
          location: response?.data?.location || "",
        });
      }
    } catch (error) {
      console.log(error, "errror");
    }
  };

  return (
    <div className="module-container">
      <Card>
        <div className="detail-module-card">
          <h2>Edit User</h2>
          <Button
            className="back-btn-container"
            type="primary"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>

        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            style={{
              marginTop: 20,
            }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your name!",
                      whitespace: true,
                    },
                    {
                      min: 3,
                      message: "Name must be at least 3 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>First Name</h4>
                    <Input
                      placeholder="First Name"
                      style={{ height: 55 }}
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="last_name"
                  rules={[
                    { required: true, message: "Please enter your lastname!" },
                    {
                      min: 3,
                      message: "Username must be at least 3 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Last Name</h4>
                    <Input
                      placeholder="Last name"
                      style={{ height: 55 }}
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your email!",
                    },
                    {
                      type: "email",
                      message: "The input is not valid email!",
                    },
                  ]}
                >
                  <div>
                    <h4>Email</h4>
                    <Input
                      style={{ height: 55 }}
                      placeholder="E-mail"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="officePhone"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your phone number!",
                    },
                  ]}
                >
                  <div>
                    <h4>Office Phone Number</h4>
                    <div className="phn_cs">
                      <PhoneInput
                        value={phoneCode + phoneNo}
                        enableSearch={true}
                        placeholder="Phone number"
                        containerClass={"cntn_telCls"}
                        inputClass={"cstm_cls"}
                        buttonClass={"custm_btn"}
                        onChange={(phone, country) =>
                          handleChangePhone(phone, country)
                        }
                      />
                    </div>
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="companyPhone"
                  rules={[
                    {
                      required: true,
                      message: "Please enter company phone number!",
                    },
                  ]}
                >
                  <div>
                    <h4>Company Phone Number</h4>
                    <div className="phn_cs">
                      <PhoneInput
                        value={companyPhoneCode + companyPhoneNo}
                        enableSearch={true}
                        placeholder="Phone number"
                        containerClass={"cntn_telCls"}
                        inputClass={"cstm_cls"}
                        buttonClass={"custm_btn"}
                        onChange={(phone, country) =>
                          handleChangeCompanyPhone(phone, country)
                        }
                      />
                    </div>
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item name="location">
                  <div>
                    <h4>Location</h4>
                    <Input
                      placeholder="Location"
                      style={{ height: 55 }}
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <div className="submit-btn-wrapper">
              <Button
                className="submit-btn-container"
                type="primary"
                htmlType="submit"
              >
                Update
              </Button>
            </div>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default UsersForm;
