import { Button, Col, Form, Input, Row, Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetCategoriesMutation,
  useLazyGetSubCategoryByIdQuery,
  usePostSubCategoryMutation,
  useUpdateSubCategoryByIdMutation,
} from "../../../services/main";
import { showError, showToast } from "../../../helpers/toast";
import { useEffect, useState } from "react";
import { Category } from "../../../types/General";
import { isValidInput } from "../../../utils/validations";

const { TextArea } = Input;
const { Option } = Select;

const FormData = (props: any) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const [approvedCategories, setApprovedCategories] = useState<Category[]>([]);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState<number | null>(null);

  const [subCategoryMutation, addLoader] = usePostSubCategoryMutation();
  const [getCategoryList] = useGetCategoriesMutation();
  const [getSubCategoryById] = useLazyGetSubCategoryByIdQuery();
  const [updateSubCategoryById, editLoader] =
    useUpdateSubCategoryByIdMutation();

  const onFinish = async (values: any) => {
    console.log("Received values of form: ", values);
    try {
      let response = null;
      const body = {
        name,
        category: category || 0,
        description,
        is_active: true,
      };
      if (id && id !== "add") {
        response = await updateSubCategoryById({
          body,
          sub_category_id: `${id}`,
        }).unwrap();
      } else {
        response = await subCategoryMutation(body).unwrap();
      }
      console.log(response);
      if (response?.code === 201 || response?.code === 200) {
        showToast(response?.message || "");
        navigate("/manage-subcategories", { replace: true });
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error);
    }
  };

  const getCategories = async () => {
    try {
      const body = {
        start: 1,
        length: 10,
        is_approved: true,
        search: {
          value: "",
          regex: false,
        },
      };
      const response = await getCategoryList({
        body,
      }).unwrap();
      console.log(response);
      if (response?.code === 200) {
        setApprovedCategories(response.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubCategoryDetails = async (id: string) => {
    const response = await getSubCategoryById({ sub_category_id: id }).unwrap();
    console.log(response);
    if (response?.code === 200) {
      setName(response?.data?.name);
      setDescription(response?.data?.description);
      setCategory(response?.data?.category?.id);
      form.setFieldsValue({
        sub_category_name: response?.data?.name,
        description: response?.data?.description,
        category: response?.data?.category?.id,
      });
    }
  };

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id && id !== "add") {
      getSubCategoryDetails(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onFinishFailed = ({ values, errorFields, outOfDate }: any) => {
    console.log(
      values,
      errorFields,
      outOfDate,
      "values, errorFields, outOfDate"
    );
  };

  return (
    <div>
      <Form
        name="basic"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="sub_category_name"
              rules={[
                {
                  required: true,
                  message: "Please enter sub-category name.",
                },
                {
                  min: name?.length ? 1 : 2,
                  message: "Please enter sub-category name.",
                },
              ]}
            >
              <div>
                <h4>Name</h4>
                <Input
                  value={name}
                  onChange={(val) => {
                    if (isValidInput(val.target.value)) {
                      setName(val.target.value);
                    }
                  }}
                  placeholder="Name"
                  maxLength={50}
                  style={{ height: 55 }}
                />
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="category"
              rules={[
                {
                  required: true,
                  message: "Please select a category",
                },
              ]}
            >
              <div>
                <h4>Category</h4>
                <Select
                  style={{ width: "100%" }}
                  placeholder={"Choose Category"}
                  value={category}
                  onChange={(value) => {
                    setCategory(value);
                    form.setFieldsValue({
                      category: value,
                    });
                    form.validateFields(["category"]);
                  }}
                >
                  {approvedCategories?.map((item, index) => (
                    <Option value={item?.id} key={index}>
                      {item?.name || ""}
                    </Option>
                  ))}
                </Select>
              </div>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please enter category description.",
                  whitespace: true,
                },
                {
                  min: 10,
                  message: "Name must be at least 10 characters",
                },
              ]}
            >
              <div>
                <h4>Description</h4>
                <TextArea
                  value={description}
                  onChange={(val) => {
                    if (isValidInput(val.target.value)) {
                      setDescription(val.target.value);
                    }
                  }}
                  rows={10}
                  placeholder="Description"
                />
              </div>
            </Form.Item>
          </Col>
        </Row>

        <div className="submit-btn-wrapper">
          <Button
            className="submit-btn-container"
            type="primary"
            htmlType="submit"
            loading={addLoader?.isLoading || editLoader?.isLoading}
          >
            {props?.path ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default FormData;
