import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select, Upload } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import {
  useAddWarehouseMutation,
  useLazyGetWarehouseByIdQuery,
  useUpdateWarehouseByIdMutation,
} from "../../../services/warehouse";
import { useNavigate, useParams } from "react-router-dom";
import { showError, showToast } from "../../../helpers/toast";
import { RcFile } from "antd/es/upload";
import { PageLoader } from "../../../components";
import { UploadMedia } from "../../../utils/imageUpload";
import { isValidInput } from "../../../utils/validations";

const { Option } = Select;
let contractId = 0;

const FormData = (props: any) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [addWareHouse, addWareHouseData] = useAddWarehouseMutation();
  const [WarehouseById, wareHouseData] = useLazyGetWarehouseByIdQuery();
  const [editWareHouse, { isLoading }] = useUpdateWarehouseByIdMutation();

  const [phoneIso, setPhoneIso] = useState("in");
  const [phoneCode, setPhoneCode] = useState("+91");
  const [phoneNo, setphoneNo] = useState("");
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [email, setEmail] = useState("");
  const [type, setType] = useState<any>();
  const [imageObject, setImageObject] = useState<RcFile | null>(null);
  const [imagePreview, setImagePreview] = useState("");
  console.log(imagePreview, "image");

  const onFinishFailed = ({ values, errorFields, outOfDate }: any) => {
    console.log(
      values,
      errorFields,
      outOfDate,
      "values, errorFields, outOfDate"
    );
  };

  const handleImageUpload = async () => {
    try {
      const res = await UploadMedia(imageObject);
      if (res?.code === 200) {
        return res?.data[0]?.id;
      }
      return 0;
    } catch (error: any) {
      console.log(error);
      return 0;
    }
  };

  const onFinish = async (values: any) => {
    if (imageObject) {
      contractId = await handleImageUpload();
    }

    const body = {
      name: name,
      location: location,
      email: email,
      phone_no: phoneNo,
      country_code: phoneCode,
      country_iso_code: phoneIso,
      type: type,
      contract: contractId,
    };

    try {
      let response = null;
      if (id && id !== "add") {
        response = await editWareHouse({
          body,
          warehouse_id: `${id}`,
        }).unwrap();
      } else {
        response = await addWareHouse(body).unwrap();
      }

      if (response?.code === 201 || response?.code === 200) {
        showToast(response?.message || "");
        navigate("/manage-warehouse", { replace: true });
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error);
    }
  };

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    setPhoneIso(country?.countryCode);
    setphoneNo(phone?.replace(country.dialCode, ""));
  };

  const handleUpload = (File: RcFile) => {
    console.log(File, "sjj=fsfd");

    setImageObject(File);
    const result = window.URL.createObjectURL(File);
    setImagePreview(result);
  };

  const handleOnChange = (val: any) => {
    setType(val);
  };

  const warehouseDetail = async (id: string) => {
    try {
      const response = await WarehouseById({ warehouse_id: id }).unwrap();
      console.log(response, "edit");

      if (response?.code === 200) {
        console.log(response, "form");
        setName(response?.data?.name || "");
        setLocation(response?.data?.location || "");
        setEmail(response?.data?.email || "");
        setPhoneCode(response?.data?.country_code || "");
        setphoneNo(response?.data?.phone_no || "");
        setType(response?.data?.type || 0);
        setImagePreview(response?.data?.contract?.media_file_url || "");
        contractId = response?.data?.contract?.id;

        form.setFieldsValue({
          warehouse_name: response?.data?.name || "",
          productlocation: response?.data?.location || "",
          email: response?.data?.email || "",
          phone: response?.data?.phone_no || "",
          role: response?.data?.type || "",
          upload: response?.data?.contract?.media_file_url || "",
        });
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      warehouseDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div>
      <PageLoader
        isLoading={
          isLoading || wareHouseData?.isLoading || addWareHouseData?.isLoading
        }
      />
      <Form
        name="basic"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="warehouse_name"
              rules={[
                {
                  required: true,
                  message: "Please enter warehouse name.",
                },
                {
                  min: name?.length ? 1 : 2,
                  message: "Please enter warehouse name",
                },
              ]}
            >
              <div>
                <h4>Name</h4>
                <Input
                  placeholder="Name"
                  style={{ height: 55 }}
                  value={name}
                  onChange={(e) => {
                    if (isValidInput(e.target.value)) {
                      setName(e.target.value);
                    }
                  }}
                />
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="productlocation"
              rules={[
                {
                  required: true,
                  message: "Please enter warehouse location!",
                },
                {
                  min: location?.length ? 1 : 2,
                  message: "Please enter warehouse location!",
                },
              ]}
            >
              <div>
                <h4>Location</h4>
                <Input
                  placeholder="Location"
                  style={{ height: 55 }}
                  value={location}
                  onChange={(e) => {
                    if (isValidInput(e.target.value)) {
                      setLocation(e.target.value);
                    }
                  }}
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter your email!",
                },
                {
                  type: "email",
                  message: "The input is not valid email!",
                },
              ]}
            >
              <div>
                <h4>Email</h4>
                <Input
                  // disabled
                  style={{ height: 55 }}
                  placeholder="E-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please enter your phone number!",
                },
                {
                  min: 8,
                  message: "Phone number must be at least 8 characters",
                },
              ]}
            >
              <div>
                <h4>Phone Number</h4>
                <div className="phn_cs">
                  <PhoneInput
                    value={phoneCode + phoneNo}
                    enableSearch={true}
                    placeholder="Phone number"
                    containerClass={"cntn_telCls"}
                    inputClass={"cstm_cls"}
                    buttonClass={"custm_btn"}
                    onChange={(phone, country) =>
                      handleChangePhone(phone, country)
                    }
                  />
                </div>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item name="role">
              <div>
                <h4>Type</h4>
                <Select
                  style={{ width: "100%" }}
                  placeholder={"Choose Type"}
                  value={type === 1 ? "Internal" : "External"}
                  onChange={(val) => handleOnChange(val)}
                >
                  <Option value={1}>Internal</Option>
                  <Option value={2}>External</Option>
                </Select>
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <h4>Contract</h4>
            <Form.Item
              name="upload"
              rules={[
                {
                  required: false,
                  message: "Please add contract image.",
                },
              ]}
            >
              {imagePreview ? (
                <div>
                  <CloseCircleOutlined
                    onClick={() => {
                      setImageObject(null);
                      setImagePreview("");
                    }}
                    style={{
                      color: "red",
                      position: "absolute",
                      right: 10,
                      top: 10,
                      fontSize: 20,
                      cursor: "pointer",
                    }}
                  />
                  <figure
                    style={{
                      height: "200px",
                      width: "100%",
                      borderRadius: "5px",
                      overflow: "hidden",
                    }}
                  >
                    <img src={imagePreview || ""} alt="" />
                  </figure>
                </div>
              ) : (
                <Upload
                  name="logo"
                  beforeUpload={handleUpload}
                  accept="image/png, image/gif, image/jpeg"
                >
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              )}
            </Form.Item>
          </Col>
        </Row>

        <div className="submit-btn-wrapper">
          <Button
            className="submit-btn-container"
            type="primary"
            htmlType="submit"
            loading={
              isLoading ||
              wareHouseData?.isLoading ||
              addWareHouseData?.isLoading
            }
          >
            {props?.path ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default FormData;
