import { Button, Card, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetSubCategoryByIdQuery } from "../../../services/main";
const SubCategoryDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");

  const [getSubCategoryById] = useLazyGetSubCategoryByIdQuery();

  const getSubCategoryDetails = async (id: string) => {
    const response = await getSubCategoryById({ sub_category_id: id }).unwrap();
    console.log(response);
    if (response?.code === 200) {
      setName(response?.data?.name);
      setDescription(response?.data?.description);
      setCategory(response?.data?.category?.name);
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getSubCategoryDetails(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="module-details-container">
      <Card className="product_details">
        <div className="detail-module-card">
          <h2>Sub-Category Details</h2>
          <Button
            className="back-btn-container"
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigate("/manage-subcategories", { replace: true });
            }}
          >
            Back
          </Button>
        </div>
        <div>
          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Name</h4>
              <h5>{name || ""}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Category</h4>
              <h5>{category || ""}</h5>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Description</h4>
              <h5>{description || ""}</h5>
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};
export default SubCategoryDetails;
