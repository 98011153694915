export const SideBarIds = {
  category: 4,
  subCategories: 3,
  brands: 5,
  products: 6,
  users: 2,
  warehouse: 10,
  reviewAndRatings: 12,
  orders: 13,
  subAdmin: 15,
  companies: 7,
};

export const OrderStatus = [
  { id: 1, name: "Order placed" },
  { id: 2, name: "Shipped" },
  { id: 3, name: "Delivered" },
  { id: 4, name: "Rejected" },
  { id: 5, name: "Cancelled" },
];
