import { Button, Select, Space, Switch, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import ProductFilters from "../filters";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Brand, Category, Product } from "../../../types/General";
import { PageLoader, TableLoader } from "../../../components";
import { showError, showToast } from "../../../helpers/toast";
import {
  useUpdateProductStatusMutation,
  useAssignQcToProductMutation,
  useGetProductMutation,
  useAssignScToProductMutation,
} from "../../../services/product";

const { Option } = Select;

const ApprovedProducts = ({
  qcs,
  scs,
  brands,
  activeTab,
  categories,
  setPendingCount,
  setUpdateCount,
}: {
  activeTab: string;
  setPendingCount: Dispatch<SetStateAction<number>>;
  setUpdateCount: Dispatch<SetStateAction<number>>;
  qcs: { first_name: string; last_name: string; id: number }[];
  scs: { first_name: string; last_name: string; id: number }[];
  categories: Category[];
  brands: Brand[];
}) => {
  const navigate = useNavigate();
  const [getProducts, { isLoading }] = useGetProductMutation();
  const [updateCompanyStatus, updateStatusLoader] =
    useUpdateProductStatusMutation();
  const [assignQc, assignQcLoader] = useAssignQcToProductMutation();
  const [assignSc, assignScLoader] = useAssignScToProductMutation();

  const [page, setPage] = useState(1);
  const [products, setProducts] = useState<Product[]>([]);
  const [total, setTotal] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const [selectedBrand, setSelectedBrand] = useState<number | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [productId, setProductId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const getApprovedProducts = async () => {
    const body = {
      start: page,
      length: 10,
      is_approved: true,
      product_category: selectedCategory ? `${selectedCategory}` : "",
      brand: selectedBrand ? `${selectedBrand}` : "",
      id: productId || "",
      start_date: startDate || "",
      end_date: endDate || "",
      search: {
        value: debouncedSearchTerm.trim(),
        regex: false,
      },
    };
    try {
      const result = await getProducts(body).unwrap();
      console.log(result);
      if (result?.code === 200) {
        setProducts(result?.data || []);
        setTotal(result?.recordsTotal || 1);
        setPendingCount(result?.pending_obj_count || 0);
        setUpdateCount(result?.requested_obj_count || 0);
      } else {
        setProducts([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (activeTab === "1") {
      getApprovedProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    debouncedSearchTerm,
    activeTab,
    selectedBrand,
    selectedCategory,
    productId,
    startDate,
  ]);

  const updateStatus = async (id: string, state: boolean) => {
    const response = await updateCompanyStatus({
      product_id: id,
      body: {
        is_active: state,
      },
    }).unwrap();
    if (response?.code === 200) {
      const updatedData = products.map((x) => {
        if (x.id === parseInt(id, 10)) {
          return {
            ...x,
            is_active: state,
          };
        }
        return x;
      });
      setProducts(updatedData);
      showToast("Status updated successfully");
    } else {
      showError("Please try again");
    }
  };

  const assignQcToProduct = async (productId: string, qcId: number) => {
    try {
      const body = {
        qc: qcId,
      };
      const response = await assignQc({ product_id: productId, body }).unwrap();
      console.log(response);
      if (response?.code === 200) {
        showToast("QC assigned successfully.");
        let idx = -1;
        idx = qcs?.findIndex((ele) => ele?.id === qcId);
        if (idx > -1) {
          const updatedData = products.map((x) => {
            if (x.id === parseInt(productId, 10)) {
              return {
                ...x,
                assign_qc: {
                  first_name: qcs[idx]?.first_name || "",
                  last_name: qcs[idx]?.last_name || "",
                  id: Number(qcs[idx]?.id),
                },
              };
            }
            return x;
          });
          setProducts(updatedData);
        }
      } else {
        showError(response?.message || "Something went wrong");
      }
    } catch (error: any) {
      console.log(error);
      showError(error?.body?.message || "Something went wrong");
    }
  };

  const assignScToProduct = async (productId: string, qcId: number) => {
    try {
      const body = {
        sc: qcId,
      };
      const response = await assignSc({ product_id: productId, body }).unwrap();
      console.log(response);
      if (response?.code === 200) {
        showToast("SC assigned successfully.");
        let idx = -1;
        idx = scs?.findIndex((ele) => ele?.id === qcId);
        if (idx > -1) {
          const updatedData = products.map((x) => {
            if (x.id === parseInt(productId, 10)) {
              return {
                ...x,
                assign_sales_consultant: {
                  first_name: scs[idx]?.first_name || "",
                  last_name: scs[idx]?.last_name || "",
                  id: Number(scs[idx]?.id),
                },
              };
            }
            return x;
          });
          setProducts(updatedData);
        }
      } else {
        showError(response?.message || "Something went wrong");
      }
    } catch (error: any) {
      console.log(error);
      showError(error?.body?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [searchTerm]);

  const renderSwitch = (_: any, data: Product) => {
    return (
      <Switch
        onChange={(val) => updateStatus(`${data?.id}`, val)}
        size="small"
        checked={data?.is_active}
      />
    );
  };

  const columns: ColumnsType<Product> = [
    {
      title: "S.no.",
      key: "sno",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
      align: "center",
    },
    {
      title: "Product Id",
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "product_name",
      key: "product_name",
      width: "20%",
      render: (_, record) => (
        <div>
          {record?.product_name?.length > 20
            ? `${record?.product_name?.slice(0, 20)}...`
            : record?.product_name}
        </div>
      ),
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      width: "20%",
      render: (_, record) => (
        <div>
          {record?.by_company && typeof record?.by_company !== "number"
            ? record?.by_company?.name || "--"
            : ""}
        </div>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: "20%",
      render: (_, record) => (
        <div>
          {record?.product_category &&
          typeof record?.product_category !== "number"
            ? record?.product_category?.name || "--"
            : ""}
        </div>
      ),
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      width: "20%",
      render: (_, record) => (
        <div>
          {record?.brand && typeof record?.brand !== "number"
            ? record?.brand?.name || "--"
            : ""}
        </div>
      ),
    },
    {
      title: "Country of Origin",
      dataIndex: "country_of_origin",
      key: "country_of_origin",
      width: "20%",
      render: (_, record) => (
        <div>
          {record?.country_of_origin &&
          typeof record?.country_of_origin !== "number"
            ? record?.country_of_origin.name
            : ""}
        </div>
      ),
    },
    {
      title: "Price",
      dataIndex: "product_cost",
      key: "product_cost",
      align: "center",
      width: "25%",
      render: (_, record) => <div>{`$${record?.product_cost || ""}`}</div>,
    },
    {
      title: "Assign QC",
      key: "status",
      align: "center",
      width: "20%",
      render: (_, record) => (
        <Select
          className="select-div"
          value={record?.assign_qc?.id || null}
          onChange={(val) => assignQcToProduct(`${record?.id}`, val)}
          placeholder={"Assign QC"}
        >
          {qcs?.map((item, index) => (
            <Option key={index} value={item?.id}>{`${item?.first_name || ""} ${
              item?.last_name || ""
            }`}</Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Assign SC",
      key: "status",
      align: "center",
      width: "20%",
      render: (_, record) => (
        <Select
          className="select-div"
          value={record?.assign_sales_consultant?.id || null}
          onChange={(val) => assignScToProduct(`${record?.id}`, val)}
          placeholder={"Assign SC"}
        >
          {scs?.map((item, index) => (
            <Option key={index} value={item?.id}>{`${item?.first_name || ""} ${
              item?.last_name || ""
            }`}</Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "20%",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              window.open(
                `/manage-products/details/${record?.id}/${activeTab}`,
                "_blank"
              );
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>

          <Button
            onClick={(e) => {
              e.preventDefault();
              window.open(
                `/manage-products/${record?.id}/${activeTab}`,
                "_blank"
              );
            }}
          >
            <EditOutlined color="#08c" />
          </Button>
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: "20%",
      render: renderSwitch,
    },
  ];

  return (
    <div>
      <PageLoader
        isLoading={
          assignQcLoader?.isLoading ||
          assignScLoader?.isLoading ||
          updateStatusLoader?.isLoading
        }
      />
      <div className="srch">
        <ProductFilters
          categories={categories}
          brands={brands}
          productId={productId}
          searchTerm={searchTerm}
          selectedBrand={selectedBrand}
          selectedCategory={selectedCategory}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setProductId={setProductId}
          setSearchTerm={setSearchTerm}
          setSelectedBrand={setSelectedBrand}
          setSelectedCategory={setSelectedCategory}
        />
        {isLoading ? (
          <TableLoader />
        ) : (
          <Table
            className="table-container"
            columns={columns}
            dataSource={products || []}
            rowKey={"id"}
            pagination={{
              defaultPageSize: 10,
              current: page,
              onChange: onPageChange,
              total,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ApprovedProducts;
