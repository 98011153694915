/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Card } from "antd";
import { ContentHeader } from "../../components";
import ApprovedSubCategories from "./types/Approved";
import { useGetSubCategoriesMutation } from "../../services/main";
import { Permissions, SubCategory } from "../../types/General";
import { TableLoader } from "../../components";
import usePermissions from "../../hooks/permissions";
import { SideBarIds } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { showWarning } from "../../helpers/toast";

const SubCategories = () => {
  const user = useAuth();
  const navigate = useNavigate();
  const permissions = usePermissions();
  const [getSubCategoryList, { isLoading }] = useGetSubCategoriesMutation();

  const [approvedSubCategories, setApprovedSubCategories] = useState<
    SubCategory[]
  >([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [modulePermissions, setModulePermissions] = useState<
    Permissions | null | undefined
  >(null);

  useEffect(() => {
    if (user && user?.role !== 1) {
      if (permissions) {
        let idx = -1;
        idx = permissions?.findIndex(
          (ele) => ele?.module?.id === SideBarIds.subCategories
        );
        if (idx > -1) {
          setModulePermissions(permissions[idx]);
        } else {
          navigate(-1);
          showWarning("You are not authorized to access this");
        }
      }
    }
  }, [permissions, user]);

  const getSubCategories = async () => {
    const body = {
      start: page,
      length: 10,
      is_approved: true,
      search: {
        value: debouncedSearchTerm.trim(),
        regex: false,
      },
    };
    try {
      const response = await getSubCategoryList({
        body,
      }).unwrap();
      console.log(response);
      if (response?.code === 200) {
        setApprovedSubCategories(response?.data || []);
        setTotal(response?.recordsTotal || 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSubCategories();
  }, [page, debouncedSearchTerm]);

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [searchTerm]);

  return (
    <div className="module-container">
      <ContentHeader
        modulePermissions={modulePermissions}
        heading="Manage Sub-Categories"
        buttonNavigateTo="manage-subcategories/add"
        buttonText="Add"
      />
      <Card className="card_space">
        {isLoading ? (
          <TableLoader />
        ) : (
          <ApprovedSubCategories
            page={page}
            total={total}
            subCategories={approvedSubCategories}
            searchTerm={searchTerm}
            modulePermissions={modulePermissions}
            setSearchTerm={setSearchTerm}
            setPage={setPage}
            setApprovedSubCategories={setApprovedSubCategories}
          />
        )}
      </Card>
    </div>
  );
};

export default SubCategories;
