import { Card } from "antd";
import { ContentHeader } from "../../components";
import ManageBuyers from "./users/Buyers";

const ManageUsers = () => {
  return (
    <div className="module-container">
      <ContentHeader
        heading="Manage Users"
        buttonNavigateTo="manageusers/add"
      />
      <Card>
        <ManageBuyers />
      </Card>
    </div>
  );
};

export default ManageUsers;
