import "./App.css";

import RoutesNavigation from "./routes";

function App() {
  // console.log = () => {};
  return <RoutesNavigation />;
}

export default App;
