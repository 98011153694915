import { END_POINTS } from "../helpers/constants/urls";
import { Paginated, Warehouse, WarehouseBody } from "../types/General";
import emptySplitApi from "../utils/rtk";

type GetBrandsPayload = {
  start: number;
  is_active?: boolean;
  length: number;
  search?: Paginated;
};

type UserByIdResponse = {
  code: number;
  message: string;
  data: boolean;
};

type WarehouseResponse = {
  code: number;
  message: string;
  data: Warehouse[];
  recordsTotal: number;
};

type WarehouseResponseById = {
  code: number;
  message: string;
  data: Warehouse;
};

type AddWarehouseResponse = {
  code: number;
  message: string;
  data: null;
};

export const mainApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getWarehouse: builder.query<WarehouseResponse, { body: GetBrandsPayload }>({
      query: ({ body }) => ({
        url: END_POINTS.get_all_warehouse,
        method: "POST",
        body,
      }),
    }),
    updateWarehouseStatus: builder.mutation<
      UserByIdResponse,
      {
        warehouse_id: string;
        body: Omit<Warehouse, "id"> | Pick<Warehouse, "is_active">;
      }
    >({
      query: ({ warehouse_id, body }) => ({
        url: `${END_POINTS.update_warehouse_status}${warehouse_id}/`,
        method: "PUT",
        body,
      }),
    }),
    getWarehouseById: builder.query<
      WarehouseResponseById,
      { warehouse_id: string }
    >({
      query: ({ warehouse_id }) => ({
        url: `${END_POINTS.get_warehouse_by_id}${warehouse_id}`,
        method: "GET",
      }),
    }),
    getSubAdminList: builder.query<
      {
        code: number;
        data: { first_name: string; last_name: string; id: number }[];
        message: string;
      },
      {}
    >({
      query: () => ({
        url: `${END_POINTS.get_subAdmin_list}`,
        method: "GET",
      }),
    }),
    updateWarehouseById: builder.mutation<
      WarehouseResponseById,
      { warehouse_id: string; body: Omit<WarehouseBody, "id"> }
    >({
      query: ({ warehouse_id, body }) => ({
        url: `${END_POINTS.update_warehouse_by_id}${warehouse_id}/`,
        method: "PUT",
        body,
      }),
    }),
    addWarehouse: builder.mutation<AddWarehouseResponse, WarehouseBody>({
      query: (body) => ({
        url: END_POINTS.add_warehouse,
        method: "POST",
        body,
      }),
    }),
    assignTeamToWarehouse: builder.mutation<
      AddWarehouseResponse,
      { user_id: number[]; warehouse_id: number }
    >({
      query: (body) => ({
        url: END_POINTS.assign_team,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetWarehouseQuery,
  useUpdateWarehouseStatusMutation,
  useLazyGetWarehouseByIdQuery,
  useUpdateWarehouseByIdMutation,
  useAddWarehouseMutation,
  useAssignTeamToWarehouseMutation,
  useLazyGetSubAdminListQuery,
} = mainApi;
