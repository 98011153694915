import React from "react";

export const CmsToolbar = () => (
  <div>
    <div
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      <h3>Content Management System</h3>
    </div>
  </div>
);
