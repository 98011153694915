import { Badge, Card, Tabs } from "antd";
import { ContentHeader } from "../../components";
import { useEffect, useState } from "react";
import {
  ApprovedProducts,
  PendingProducts,
  UnApprovedProducts,
  UpdatedProducts,
} from "./types";
import {
  useGetBrandsMutation,
  useGetCategoriesMutation,
} from "../../services/main";
import { Brand, Category } from "../../types/General";
import {
  useLazyGetQcListQuery,
  useLazyGetScListQuery,
} from "../../services/product";
import { useLocation } from "react-router-dom";

const Products = () => {
  const { state } = useLocation();

  const [getCategoryList] = useGetCategoriesMutation();
  const [getBrandList] = useGetBrandsMutation();
  const [getQcsList] = useLazyGetQcListQuery();
  const [getScsList] = useLazyGetScListQuery();

  const [activeTab, setActiveTab] = useState("1");
  const [pendingCount, setPendingCount] = useState(0);
  const [updateCount, setUpdateCount] = useState(0);

  const [approvedCategories, setApprovedCategories] = useState<Category[]>([]);
  const [approvedBrands, setApprovedBrands] = useState<Brand[]>([]);
  const [qcs, setQcs] = useState<
    { first_name: string; last_name: string; id: number }[]
  >([]);
  const [scs, setScs] = useState<
    { first_name: string; last_name: string; id: number }[]
  >([]);

  const getBrands = async () => {
    try {
      const body = {
        start: 1,
        length: 50,
        is_active: true,
        search: {
          value: "",
          regex: false,
        },
      };
      const response = await getBrandList({ body: body }).unwrap();
      console.log(response);
      if (response?.code === 200) {
        setApprovedBrands(response.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCategories = async () => {
    try {
      const body = {
        start: 1,
        length: 50,
        is_active: true,
        search: {
          value: "",
          regex: false,
        },
      };
      const response = await getCategoryList({
        body,
      }).unwrap();
      console.log(response);
      if (response?.code === 200) {
        setApprovedCategories(response.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getQcList = async () => {
    try {
      const response = await getQcsList({}).unwrap();
      console.log(response);
      if (response?.code === 200) {
        setQcs(response?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getScList = async () => {
    try {
      const response = await getScsList({}).unwrap();
      console.log(response);
      if (response?.code === 200) {
        setScs(response?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log({ state });
    if (state) {
      if (state?.tab) {
        setActiveTab(state?.tab);
      }
    }
  }, [state]);

  useEffect(() => {
    if (!approvedCategories?.length && activeTab === "1") {
      getCategories();
    }
    if (!approvedBrands?.length && activeTab === "1") {
      getBrands();
    }
    if (!qcs?.length && activeTab === "1") {
      getQcList();
    }
    if (!scs?.length && activeTab === "1") {
      getScList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { TabPane } = Tabs;

  const onChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <div className="module-container">
      <ContentHeader
        heading="Manage Product"
        buttonNavigateTo="manage-products/add"
        buttonText="Add"
      />
      <Card>
        <Tabs
          activeKey={activeTab}
          defaultActiveKey="1"
          onChange={onChange}
          animated={false}
        >
          <TabPane tab="Approved" key="1">
            <ApprovedProducts
              qcs={qcs}
              scs={scs}
              activeTab={activeTab}
              brands={approvedBrands}
              categories={approvedCategories}
              setPendingCount={setPendingCount}
              setUpdateCount={setUpdateCount}
            />
          </TabPane>
          <TabPane
            tab={
              <Badge
                style={{ marginLeft: 8, top: -5, right: -6 }}
                count={pendingCount}
                overflowCount={99}
              >
                Pending
              </Badge>
            }
            key="2"
          >
            <PendingProducts
              qcs={qcs}
              scs={scs}
              brands={approvedBrands}
              categories={approvedCategories}
              activeTab={activeTab}
              setPendingCount={setPendingCount}
              setUpdateCount={setUpdateCount}
            />
          </TabPane>
          <TabPane
            tab={
              <Badge
                style={{ marginLeft: 8, top: -5, right: -6 }}
                count={updateCount}
                overflowCount={99}
              >
                Update Requests
              </Badge>
            }
            key="3"
          >
            <UpdatedProducts
              qcs={qcs}
              scs={scs}
              activeTab={activeTab}
              brands={approvedBrands}
              categories={approvedCategories}
              setUpdateCount={setUpdateCount}
            />
          </TabPane>
          <TabPane tab="Un-Approved" key="4">
            <UnApprovedProducts
              activeTab={activeTab}
              brands={approvedBrands}
              categories={approvedCategories}
              setUpdateCount={setUpdateCount}
              setPendingCount={setPendingCount}
            />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default Products;
