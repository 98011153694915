import { useEffect, useState } from "react";
import {
  ShopOutlined,
  // BellOutlined,
  UserOutlined,
  TeamOutlined,
  ProfileOutlined,
  PieChartOutlined,
  LineChartOutlined,
  BankOutlined,
  FormOutlined,
  HistoryOutlined,
  ApartmentOutlined,
  SwitcherOutlined,
  AppstoreOutlined,
  SafetyCertificateOutlined,
  ShoppingCartOutlined,
  // SettingOutlined,
  CrownOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import "./layout.css";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { SideBarIds } from "../utils/constants";

const { Sider } = Layout;

const SiderBar = ({ collapsed }: { collapsed: boolean }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const user = useAuth();

  const SideBarValues = [
    {
      key: "1000",
      icon: <PieChartOutlined />,
      label: "Dashboard",
      onClick: () => navigate("/dashboard"),
    },
    {
      key: `${SideBarIds.subAdmin}`,
      icon: <TeamOutlined />,
      label: "Manage Sub-Admin",
      onClick: () => navigate("/managesubadmins"),
    },
    {
      key: `${SideBarIds.users}`,
      icon: <UserOutlined />,
      label: "Manage Users",
      onClick: () => navigate("/manageusers"),
    },
    {
      key: `${SideBarIds.category}`,
      icon: <AppstoreOutlined />,
      label: "Manage Categories",
      onClick: () => navigate("/manage-categories"),
    },
    {
      key: `${SideBarIds.subCategories}`,
      icon: <ApartmentOutlined />,
      label: "Manage Sub-Categories",
      onClick: () => navigate("/manage-subcategories"),
    },
    {
      key: `${SideBarIds.brands}`,
      icon: <CrownOutlined />,
      label: "Manage Brands",
      onClick: () => navigate("/manage-brands"),
    },
    {
      key: `${SideBarIds.products}`,
      icon: <HistoryOutlined />,
      label: "Manage Products",
      onClick: () => navigate("/manage-products"),
    },
    {
      key: `${SideBarIds.companies}`,
      icon: <BankOutlined />,
      label: "Manage Companies",
      onClick: () => navigate("/manage-companies"),
    },
    {
      key: "1800",
      icon: <SafetyCertificateOutlined />,
      label: "Manage Roles & Permissions",
      onClick: () => navigate("/manage-roles"),
    },
    {
      key: `${SideBarIds.orders}`,
      icon: <ShoppingCartOutlined />,
      label: "Manage Orders",
      onClick: () => navigate("/manage-orders"),
    },
    {
      key: `${SideBarIds.warehouse}`,
      icon: <ShopOutlined />,
      label: "Manage Warehouse",
      onClick: () => navigate("/manage-warehouse"),
    },
    // {
    //   key: "1100",
    //   icon: <BellOutlined />,
    //   label: "Notification Management",
    //   onClick: () => navigate("/notifications"),
    // },
    {
      key: "1200",
      icon: <FormOutlined />,
      label: "Manage CMS",
      onClick: () => navigate("/manage-cms"),
    },
    {
      key: "1300",
      icon: <SwitcherOutlined />,
      label: "Manage FAQ's",
      onClick: () => navigate("/manage-faq"),
    },
    {
      key: "1400",
      icon: <LineChartOutlined />,
      label: "Analytics",
      onClick: () => navigate("/analytics"),
    },
    {
      key: `${SideBarIds.reviewAndRatings}`,
      icon: <ProfileOutlined />,
      label: "Reviews & Ratings",
      onClick: () => navigate("/manage-reviews"),
    },
    // {
    //   key: "1600",
    //   icon: <SettingOutlined />,
    //   label: "Settings",
    //   onClick: () => navigate("/settings"),
    // },
  ];

  const [selectedKey, setSelectedKey] = useState("1");

  const [sideBarValues, setSideBarValues] = useState<ItemType[]>();

  useEffect(() => {
    switch (true) {
      case location?.pathname?.includes("dashboard"):
        setSelectedKey("1000");
        break;
      case location?.pathname?.includes("managesubadmins"):
        setSelectedKey("15");
        break;
      case location?.pathname?.includes("manageusers"):
        setSelectedKey("2");
        break;
      case location?.pathname?.includes("manage-categories"):
        setSelectedKey("4");
        break;
      case location?.pathname?.includes("manage-subcategories"):
        setSelectedKey("3");
        break;
      case location?.pathname?.includes("manage-products"):
        setSelectedKey("6");
        break;
      case location?.pathname?.includes("manage-companies"):
        setSelectedKey("7");
        break;
      case location?.pathname?.includes("manage-roles"):
        setSelectedKey("1800");
        break;
      case location?.pathname?.includes("manage-orders"):
        setSelectedKey("13");
        break;
      // case location?.pathname?.includes("notifications"):
      //   setSelectedKey("1100");
      case location?.pathname?.includes("manage-cms"):
        setSelectedKey("1200");
        break;
      case location?.pathname?.includes("manage-faq"):
        setSelectedKey("1300");
        break;
      case location?.pathname?.includes("analytics"):
        setSelectedKey("1400");
        break;
      case location?.pathname?.includes("manage-reviews"):
        setSelectedKey("12");
        break;
      // case location?.pathname?.includes("settings"):
      //   setSelectedKey("1600");
      //   break;
      case location?.pathname?.includes("manage-brands"):
        setSelectedKey("5");
        break;
      case location?.pathname?.includes("manage-warehouse"):
        setSelectedKey("10");
        break;
      default:
        setSelectedKey("");
        break;
    }
  }, [location]);

  useEffect(() => {
    if (user) {
      if (user?.role !== 1 && user?.user_role?.permissions?.length) {
        const filtered = [] as ItemType[];
        SideBarValues?.map((item) => {
          let idx = -1;
          idx = user?.user_role?.permissions?.findIndex(
            (ele) => `${ele.module.id}` === `${item?.key}`
          ) as any as number;
          if (
            idx > -1 ||
            item?.key === "1000" ||
            (item?.key === `${SideBarIds.orders}` &&
              user?.assigned_warehouse?.length) ||
            (item?.key === `${SideBarIds.products}` &&
              user?.assigned_products?.length)
          ) {
            filtered.push(item);
          }
          return null;
        });
        setSideBarValues(filtered);
      } else if (user?.role === 1) {
        setSideBarValues(SideBarValues);
      } else {
        setSideBarValues([SideBarValues[0]]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, user]);

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div className="logo">
        <img
          src={
            collapsed
              ? "/static/images/logo_small.png"
              : "/static/images/logo.png"
          }
          className={collapsed ? "logo_img_small" : "logo_img"}
          alt=""
        />
      </div>
      <Menu
        theme="dark"
        mode="inline"
        className="side_menu"
        defaultSelectedKeys={[selectedKey]}
        selectedKeys={[selectedKey]}
        items={sideBarValues || []}
      />
    </Sider>
  );
};

export default SiderBar;
