import { Button, Card } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useNotificationHistroyMutation } from "../../../services/notification";
import { Notification } from "../../../types/General";
import { TableLoader } from "../../../components";
import { showError } from "../../../helpers/toast";
import moment from "moment";

const NotificationsHistory = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [notificationHistroy, setNotificationHistory] = useState<
    Notification[]
  >([]);

  const [getNotification, { isLoading }] = useNotificationHistroyMutation();

  const columns: ColumnsType<Notification> = [
    {
      title: "S.No.",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Time",
      dataIndex: "created_at",
      key: "created_at",

      render: (created_at) =>
        moment(created_at).format("MMMM Do YYYY, h:mm:ss a"),
    },
  ];

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getNotificationHistory = async () => {
    const body = {
      start: page,
      length: 10,
      search: {
        value: "",
        regex: false,
      },
    };

    try {
      const response = await getNotification({ body: body }).unwrap();
      console.log(response);
      if (response?.code === 200) {
        setNotificationHistory(response?.data || []);
        setTotal(response?.recordsTotal || 1);
      }
    } catch (error: any) {
      console.log(error);
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getNotificationHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <div className="module-container">
      <Card>
        <div>
          <div className="detail-module-card">
            <h2>Notification History</h2>
            <Button
              style={{
                background: "#0C163F",
                width: 100,
                height: 40,
              }}
              type="primary"
              onClick={() => {
                navigate("/notifications", { replace: true });
              }}
            >
              Back
            </Button>
          </div>
          {isLoading ? (
            <TableLoader />
          ) : (
            <Table
              columns={columns}
              dataSource={notificationHistroy}
              pagination={{
                defaultPageSize: 10,
                current: page,
                onChange: onPageChange,
                total,
                showSizeChanger: false,
              }}
            />
          )}
        </div>
      </Card>
    </div>
  );
};

export default NotificationsHistory;
