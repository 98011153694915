import { Button, Card, Col, Row } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { PageLoader } from "../../../components";
import { useLazyGetCompanyDetailsQuery } from "../../../services/company";
import useAuth from "../../../hooks/useAuth";
import usePermissions from "../../../hooks/permissions";
import { SideBarIds } from "../../../utils/constants";

const CompanyDetails = () => {
  const user = useAuth();
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const permissions = usePermissions();

  const [getCompanyDetails, { isLoading }] = useLazyGetCompanyDetailsQuery();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [location, setLocation] = useState("");

  const [businessPhoneCode, setBusinessPhoneCode] = useState("+91");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [businessCountryName, setBusinessCountryName] = useState("in");
  const [businessPhoneNo, setBusinessPhoneNo] = useState("");

  const [phoneCode, setPhoneCode] = useState("+91");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [countryName, setCountryName] = useState("in");
  const [phoneNo, setPhoneNo] = useState("");

  const getDetails = async (id: string) => {
    try {
      const response = await getCompanyDetails({ id }).unwrap();
      console.log(response);
      if (response?.code === 200) {
        const data = response?.data;
        if (data) {
          setFirstName(data?.first_name || "");
          setLastName(data?.last_name || "");
          setCompanyName(data?.user_company || "");
          setBusinessEmail(data?.email || "");
          setBusinessPhoneNo(data?.company_phone_no || "");
          setBusinessCountryName(data?.company_country_iso_code || "");
          setBusinessPhoneCode(data?.company_country_code || "");
          setJobTitle(data?.job_title || "");
          setPhoneNo(data?.office_phone_no || "");
          setPhoneCode(data?.office_country_code || "");
          setCountryName(data?.office_country_iso_code || "");
          setLocation(data?.location || "");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user && user?.role !== 1) {
      if (permissions) {
        let idx = -1;
        idx = permissions?.findIndex(
          (ele) => ele?.module?.id === SideBarIds.companies
        );
        if (idx > -1) {
          if (id && id !== "add") {
            getDetails(id);
          }
        } else {
          navigate(-1);
        }
      }
    } else if (user?.role === 1) {
      if (id && id !== "add") {
        getDetails(id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, user, id]);

  return (
    <div className="module-details-container">
      <PageLoader isLoading={isLoading} />
      <Card className="product_details">
        <div className="detail-module-card">
          <h2>Company Details</h2>
          <Button
            className="back-btn-container"
            type="primary"
            onClick={() => {
              navigate("/manage-companies", {
                replace: true,
                state: { tab: state?.tab || "1" },
              });
            }}
          >
            Back
          </Button>
        </div>
        <div>
          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Company Name</h4>
              <h5>{companyName}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Company Number</h4>
              <h5>{businessPhoneCode + " " + businessPhoneNo}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Admin Name</h4>
              <h5>
                {firstName + lastName} ({jobTitle})
              </h5>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Email</h4>
              <h5>{businessEmail}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Admin Number</h4>
              <h5>{phoneCode + " " + phoneNo}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Location</h4>
              <h5>{location}</h5>
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};
export default CompanyDetails;
