import { useEffect, useState } from "react";
import { ContentHeader } from "../../components";
import { Button, Card, Col, Form, Input, Row } from "antd";
import {
  useLazyGetTokenAmountQuery,
  useUpdateTokenAmountMutation,
} from "../../services/main";
import { isFloat } from "../../utils/validations";
import { showError, showToast } from "../../helpers/toast";

const Settings = () => {
  const [form] = Form.useForm();

  const [getAmount] = useLazyGetTokenAmountQuery();
  const [editAmount, { isLoading }] = useUpdateTokenAmountMutation();

  const [tokenAmount, setTokenAmount] = useState("");
  const [tokenId, setTokenId] = useState(0);

  const onFinish = async (values: any) => {
    const body = {
      amount: `${parseFloat(tokenAmount).toFixed(2)}`,
    };
    try {
      const response = await editAmount({ body, token_id: tokenId }).unwrap();
      console.log(response);
      if (response?.code === 201 || response?.code === 200) {
        showToast(response?.message || "");
        setTokenAmount(response?.data?.amount);
        form.setFieldsValue({
          amount: response?.data?.amount,
        });
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error);
    }
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }: any) => {
    console.log(
      values,
      errorFields,
      outOfDate,
      "values, errorFields, outOfDate"
    );
  };

  const getTokenAmount = async () => {
    try {
      const response = await getAmount({}).unwrap();
      console.log(response);
      if (response?.code === 200) {
        setTokenAmount(parseFloat(response?.data?.amount || "0").toFixed(2));
        setTokenId(response?.data?.id);
        form.setFieldsValue({
          amount: parseFloat(response?.data?.amount || "0").toFixed(2),
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTokenAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="module-container">
      <ContentHeader heading="Settings" />
      <Card>
        <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item
                name="amount"
                rules={[
                  {
                    required: true,
                    message: "Please enter token amount.",
                  },
                  {
                    min: tokenAmount?.length ? 1 : 2,
                    message: "Please enter token amount.",
                  },
                ]}
              >
                <div>
                  <h4>Token amount</h4>
                  <Input
                    value={tokenAmount}
                    onChange={(val) => {
                      if (
                        (isFloat(val.target.value) &&
                          parseFloat(val.target.value) < 100) ||
                        val.target.value === ""
                      ) {
                        setTokenAmount(val.target.value);
                      }
                    }}
                    suffix={<p>%</p>}
                    placeholder="Token amount"
                    style={{ height: 55 }}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Button
            style={{
              background: "#0C163F",
              width: 100,
              height: 40,
              marginTop: 50,
            }}
            loading={isLoading}
            type="primary"
            htmlType="submit"
          >
            Save
          </Button>
        </Form>
      </Card>
    </div>
  );
};

export default Settings;
