import { Button, Col, Input, Row, Space, Switch, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { EyeOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Brand, Permissions } from "../../../types/General";
import { Dispatch, SetStateAction } from "react";
import { useUpdateBrandByIdMutation } from "../../../services/main";
import { showError, showToast } from "../../../helpers/toast";
import useAuth from "../../../hooks/useAuth";
import { isValidInput } from "../../../utils/validations";

const ApprovedBrands = ({
  brands,
  page,
  total,
  searchTerm,
  modulePermissions,
  setBrands,
  setPage,
  setSearchTerm,
}: {
  brands: Brand[];
  setBrands: Dispatch<SetStateAction<Brand[]>>;
  page: number;
  total: number;
  searchTerm: string;
  modulePermissions?: Permissions | null;
  setPage: Dispatch<SetStateAction<number>>;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}) => {
  const user = useAuth();
  const navigate = useNavigate();
  const [editBrand] = useUpdateBrandByIdMutation();

  const updateStatus = async (id: string, state: boolean) => {
    const response = await editBrand({
      brand_id: id,
      body: {
        is_active: state,
      },
    }).unwrap();
    if (response?.code === 200) {
      const updatedData = brands.map((x) => {
        if (x.id === parseInt(id, 10)) {
          return {
            ...x,
            is_active: state,
          };
        }
        return x;
      });
      setBrands(updatedData);
      showToast("Status updated successfully");
    } else {
      showError("Please try again");
    }
  };

  const renderSwitch = (_: any, data: Brand) => {
    return (
      <Switch
        onChange={(val) => updateStatus(`${data?.id}`, val)}
        size="small"
        checked={data?.is_active}
      />
    );
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const columns: ColumnsType<Brand> = [
    {
      title: "S.no.",
      key: "sno",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
      align: "center",
    },
    {
      title: "Logo",
      dataIndex: "logo",
      key: "logo",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <img
            alt=""
            src={
              record?.brand_logo?.media_file_url ||
              "https://visualpharm.com/assets/919/Picture-595b40b85ba036ed117dc360.svg"
            }
            style={{ width: 50, height: "auto" }}
          />
        </Space>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (_, record) => (
        <div>
          {record?.description?.length > 50
            ? `${record?.description?.slice(0, 50)}...`
            : record?.description}
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manage-brands/details/${record?.id}`);
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>

          {modulePermissions?.is_edit || user?.role === 1 ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                window.open(`/manage-brands/${record?.id}`, "_blank");
              }}
            >
              <EditOutlined color="#08c" />
            </Button>
          ) : null}
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: renderSwitch,
      className: modulePermissions?.is_edit || user?.role === 1 ? "" : "hidden",
    },
  ];

  return (
    <div>
      <div className="srch">
        <Row gutter={16}>
          <Col xs={24} md={7}>
            <Input
              value={searchTerm}
              onChange={(val) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
              placeholder="Search"
              prefix={<SearchOutlined style={{ fontSize: "20px" }} />}
              className="search-bar"
              allowClear
            />
          </Col>
        </Row>
        <Table
          className="table-container"
          columns={columns}
          dataSource={brands || []}
          pagination={{
            defaultPageSize: 10,
            current: page,
            onChange: onPageChange,
            total,
          }}
        />
      </div>
    </div>
  );
};

export default ApprovedBrands;
