import { END_POINTS } from "../helpers/constants/urls";
import { User } from "../types/User";
import emptySplitApi from "../utils/rtk";

type PostLoginBody = {
  email: string;
  password: string;
};

type PostLoginResponse = {
  code: number;
  data: User;
  message: string;
};

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postLogin: builder.mutation<PostLoginResponse, PostLoginBody>({
      query: (body) => ({
        url: END_POINTS.login,
        method: "POST",
        body,
      }),
    }),
    postForgotPassword: builder.mutation<
      { code: number; message: string },
      { email: string }
    >({
      query: (body) => ({
        url: END_POINTS.forgot_password,
        method: "PUT",
        body,
      }),
    }),
    verifyOtp: builder.mutation<
      { code: number; message: string; data: string },
      { email: string; otp: string }
    >({
      query: (body) => ({
        url: END_POINTS.verify_otp,
        method: "PUT",
        body,
      }),
    }),
    resetPassword: builder.mutation<
      { code: number; message: string },
      { encoded_id: string; password: string }
    >({
      query: (body) => ({
        url: END_POINTS.change_password,
        method: "PUT",
        body,
      }),
    }),
    getUser: builder.query<PostLoginResponse, {}>({
      query: () => ({
        url: END_POINTS.get_user,
        method: "GET",
      }),
    }),
    editProfile: builder.mutation<
      { code: number; data: User; message: string },
      Partial<Omit<User, "image">> & { image: number | string }
    >({
      query: (body) => ({
        url: END_POINTS.update_profile,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  usePostLoginMutation,
  usePostForgotPasswordMutation,
  useVerifyOtpMutation,
  useResetPasswordMutation,
  useLazyGetUserQuery,
  useEditProfileMutation,
} = authApi;
