import { Button, Card, Col, Form, Input, Row } from "antd";
import { useEffect, useState } from "react";
import { ContentHeader, PageLoader } from "../../components";
import { CmsToolbar } from "../../components/manageCms/cms-list-toolbar";
import EditText from "../../components/textEditor";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { showError, showToast } from "../../helpers/toast";
import { useLazyGetCmsQuery, usePostCmsMutation } from "../../services/cms";

const ManageCms = () => {
  const [updateCms, { isLoading }] = usePostCmsMutation();
  const [cmsDetails] = useLazyGetCmsQuery();

  const [countryName, setCountryName] = useState("in");
  const [phoneCode, setPhoneCode] = useState("+91");
  const [phoneNo, setphoneNo] = useState<string>("");

  const [email, setEmail] = useState<string>("");
  const [aboutUs, setAboutUs] = useState<string>("");
  const [privacyPolicy, setPrivacyPolicy] = useState<string>("");
  const [terms, setTerms] = useState("");
  const [legal, setLegal] = useState<string>("");

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    setphoneNo(phone?.replace(country.dialCode, ""));
    setCountryName(country?.countryCode);
  };

  const onFinish = async () => {
    const body = {
      terms_and_conditions: terms,
      privacy: privacyPolicy,
      about_us: aboutUs,
      legal: legal,
      cms_email: email,
      cms_phone: phoneNo,
      cms_country_code: countryName,
      cms_country_iso_code: phoneCode,
      executive_phone: "",
      executive_country_code: "",
      executive_country_iso_code: "",
    };

    try {
      const response = await updateCms(body).unwrap();

      if (response?.code === 200) {
        showToast(response?.message || "");
      }
    } catch (error: any) {
      console.log(error, "error");
      showError(error?.data?.message || "");
    }
  };

  const getCmsDetail = async () => {
    try {
      const res = await cmsDetails({}).unwrap();

      if (res?.code === 200) {
        setAboutUs(res?.data?.about_us || " ");
        setEmail(res?.data?.cms_email);
        setPrivacyPolicy(res?.data?.privacy || " ");
        setTerms(res?.data?.terms_and_conditions || " ");
        setphoneNo(res?.data?.cms_phone);
        setCountryName(res?.data?.cms_country_code);
        setPhoneCode(res?.data?.cms_country_iso_code || "");
        setLegal(res?.data?.legal || " ");
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCmsDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="module-container">
      <PageLoader isLoading={isLoading} />
      <ContentHeader heading="Manage CMS" />

      <CmsToolbar />
      <Card>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <h3>Privacy Policy</h3>
            {privacyPolicy ? (
              <EditText content={privacyPolicy} setContent={setPrivacyPolicy} />
            ) : null}
          </Col>
          <Col xs={24} md={12}>
            <h3>Terms and Conditions</h3>
            {terms ? <EditText content={terms} setContent={setTerms} /> : null}
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} md={12}>
            <h3>About us</h3>
            {aboutUs ? (
              <EditText content={aboutUs} setContent={setAboutUs} />
            ) : null}
          </Col>
          <Col xs={24} md={12}>
            <h3>Legal</h3>
            {legal ? <EditText content={legal} setContent={setLegal} /> : null}
          </Col>
        </Row>

        <h3>Contact Us</h3>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                ]}
              >
                <div>
                  <h4>Email</h4>
                  <Input
                    style={{ height: 55, width: 320 }}
                    placeholder="E-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </Form.Item>
            </Col>{" "}
            <Col xs={24} md={12}>
              <div>
                <h4>Phone Number</h4>
                <div className="phn_cs" style={{ height: 55, width: 320 }}>
                  <PhoneInput
                    value={phoneCode + phoneNo}
                    enableSearch={true}
                    placeholder="Phone number"
                    containerClass={"cntn_telCls"}
                    inputClass={"cstm_cls"}
                    buttonClass={"custm_btn"}
                    onChange={(phone, country) =>
                      handleChangePhone(phone, country)
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Form>

        <Row gutter={4}>
          <Col xs={16} md={20}>
            <Button
              onClick={() => onFinish()}
              style={{
                background: "#0C163F",
                width: 200,
                height: 50,
                margin: "auto",
                marginTop: 20,
              }}
              type="primary"
            >
              Save
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ManageCms;
