/* eslint-disable react-hooks/exhaustive-deps */
import { Card } from "antd";
import ApprovedBrands from "./types/Approved";
import { ContentHeader } from "../../components";
import { useGetBrandsMutation } from "../../services/main";
import { useEffect, useState } from "react";
import { Brand, Permissions } from "../../types/General";
import { TableLoader } from "../../components";
import usePermissions from "../../hooks/permissions";
import { SideBarIds } from "../../utils/constants";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const Brands = () => {
  const user = useAuth();
  const navigate = useNavigate();
  const permissions = usePermissions();
  const [getBrandList, { isLoading }] = useGetBrandsMutation();

  const [approvedBrands, setApprovedBrands] = useState<Brand[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [modulePermissions, setModulePermissions] = useState<
    Permissions | null | undefined
  >(null);

  useEffect(() => {
    if (user && user?.role !== 1) {
      if (permissions) {
        let idx = -1;
        idx = permissions?.findIndex(
          (ele) => ele?.module?.id === SideBarIds.brands
        );
        if (idx > -1) {
          setModulePermissions(permissions[idx]);
        } else {
          navigate(-1);
        }
      }
    }
  }, [permissions, user]);

  const getBrands = async () => {
    const body = {
      start: page,
      length: 10,
      search: {
        value: debouncedSearchTerm,
        regex: false,
      },
    };
    try {
      const response = await getBrandList({ body: body }).unwrap();
      console.log(response);
      if (response?.code === 200) {
        setApprovedBrands(response?.data || []);
        setTotal(response?.recordsTotal || 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBrands();
  }, [page, debouncedSearchTerm]);

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanu
    };
  }, [searchTerm]);

  return (
    <div className="module-container">
      <ContentHeader
        heading="Manage Brands"
        buttonNavigateTo="manage-brands/add"
        buttonText="Add"
        modulePermissions={modulePermissions}
      />
      <Card className="card_space">
        {isLoading ? (
          <TableLoader />
        ) : (
          <ApprovedBrands
            page={page}
            total={total}
            brands={approvedBrands}
            searchTerm={searchTerm}
            modulePermissions={modulePermissions}
            setSearchTerm={setSearchTerm}
            setBrands={setApprovedBrands}
            setPage={setPage}
          />
        )}
      </Card>
    </div>
  );
};

export default Brands;
