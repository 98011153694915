import { Button, Col, Input, Modal, Row, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  EyeOutlined,
  SearchOutlined,
  CloseOutlined,
  CheckOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Company, Permissions } from "../../../types/General";
import TableLoader from "../../../components/loader/TableLoader";
import { showToast, showWarning } from "../../../helpers/toast";
import { PageLoader } from "../../../components";
import {
  useGetCompaniesMutation,
  useUpdateCompanyStatusMutation,
} from "../../../services/company";
import { isValidInput } from "../../../utils/validations";
import useAuth from "../../../hooks/useAuth";
const { confirm } = Modal;

const PendingCompanies = ({
  activeTab,
  setPendingCount,
  modulePermissions,
}: {
  activeTab: string;
  setPendingCount: Dispatch<SetStateAction<number>>;
  modulePermissions: Permissions | null | undefined;
}) => {
  const user = useAuth();
  const navigate = useNavigate();

  const [getCompanies, GetCompanies] = useGetCompaniesMutation();
  const [updateCompanyStatus, { isLoading }] = useUpdateCompanyStatusMutation();

  const [page, setPage] = useState(1);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [total, setTotal] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [visible, setVisible] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [selectedId, setSelectedId] = useState(0);

  const handleShowModal = () => {
    setVisible(true);
  };

  const handleHideModal = () => {
    setVisible(false);
  };

  const getPendingCompanies = async () => {
    const body = {
      start: page,
      length: 10,
      search: {
        value: debouncedSearchTerm.trim(),
        regex: false,
      },
    };
    try {
      const result = await getCompanies(body).unwrap();
      console.log(result);
      if (result?.code === 200) {
        setCompanies(result?.data || []);
        setTotal(result?.recordsTotal || 1);
        setPendingCount(result?.pending_compay_obj_count || 0);
      } else {
        setCompanies([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAcceptCompanyStatus = async (id: number) => {
    try {
      const body = {
        is_approved: true,
      };
      const response = await updateCompanyStatus({
        company_id: `${id}`,
        body,
      }).unwrap();
      console.log(response);
      if (response?.code === 200) {
        showToast("Company approved");
        getPendingCompanies();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRejectCompanyStatus = async () => {
    try {
      const body = {
        is_unapproved: true,
        reject_reason: rejectReason,
      };
      const response = await updateCompanyStatus({
        company_id: `${selectedId}`,
        body,
      }).unwrap();
      console.log(response);
      if (response?.code === 200) {
        showToast("Company rejected");
        getPendingCompanies();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showDeleteConfirm = (id: number) => {
    confirm({
      title: "Hold on",
      content: "Are you sure you want to approve this company?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onCancel() {
        console.log("Cancel");
      },
      onOk() {
        handleAcceptCompanyStatus(id);
        console.log("OK", id);
      },
    });
  };

  useEffect(() => {
    if (activeTab === "2") {
      getPendingCompanies();
    }
  }, [page, debouncedSearchTerm, activeTab]);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [searchTerm]);

  const columns: ColumnsType<Company> = [
    {
      title: "S.no.",
      key: "sno",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "user_company",
      key: "user_company",
    },
    {
      title: "Owner's Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <div>{`${record?.first_name || ""} ${record?.last_name || ""}`}</div>
      ),
    },
    {
      title: "Owner's Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Phone Number",
      dataIndex: "office",
      key: "office",
      align: "center",
      render: (_, record) => (
        <div>
          {`${record?.office_country_code || ""}${
            record?.office_phone_no || ""
          }`}
        </div>
      ),
    },
    {
      title: "Company Phone Number",
      dataIndex: "company_phone_no",
      key: "company_phone_no",
      align: "center",
      render: (_, record) => (
        <div>
          {`${record?.company_country_code || ""}${
            record?.company_phone_no || ""
          }`}
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manage-companies/details/${record?.id}`, {
                state: { tab: activeTab },
              });
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>
          {modulePermissions?.is_edit || user?.role === 1 ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  `/manage-companies/update/${record?.id}/${activeTab}`,
                  "_blank"
                );
              }}
            >
              <EditOutlined color="#08c" />
            </Button>
          ) : null}
        </Space>
      ),
    },
    {
      title: "Approve",
      key: "approve",
      align: "center",
      className: modulePermissions?.is_edit || user?.role === 1 ? "" : "hidden",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              setSelectedId(record?.id);
              showDeleteConfirm(record?.id);
            }}
          >
            <CheckOutlined style={{ color: "#52c41a" }} />
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setSelectedId(record?.id);
              handleShowModal();
            }}
          >
            <CloseOutlined style={{ color: "#d4380d" }} />
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <PageLoader isLoading={isLoading} />
      <div className="srch">
        <Row gutter={16}>
          <Col xs={24} md={7}>
            <Input
              value={searchTerm}
              onChange={(val) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
              placeholder="Search"
              prefix={<SearchOutlined style={{ fontSize: "20px" }} />}
              className="search-bar"
              allowClear
            />
          </Col>
        </Row>
        {GetCompanies.isLoading ? (
          <TableLoader />
        ) : (
          <Table
            className="table-container"
            columns={columns}
            dataSource={companies || []}
            pagination={{
              defaultPageSize: 10,
              current: page,
              onChange: onPageChange,
              total,
            }}
          />
        )}
      </div>
      <Modal
        open={visible}
        title="Reason"
        onCancel={handleHideModal}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              if (rejectReason?.length < 10) {
                showWarning("Please enter a valid reason.");
              } else {
                handleHideModal();
                handleRejectCompanyStatus();
              }
            }}
          >
            Submit
          </Button>,
        ]}
      >
        <div style={{ width: "100%", marginTop: 20, marginBottom: 20 }}>
          <Input.TextArea
            placeholder="Please enter the reason for not approving the company, we will share this feedback with the concerned company admin."
            style={{
              height: "100px",
              borderRadius: "8px",
              minHeight: "40vh",
            }}
            value={rejectReason}
            onChange={(val) => setRejectReason(val.target.value)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default PendingCompanies;
