import { END_POINTS } from "../helpers/constants/urls";
import { Notification } from "../types/General";
import emptySplitApi from "../utils/rtk";

type GetCmsResponse = {
  code: number;
  message: string;
  data: null;
};

type NotificationBody = {
  title: string;
  description: string;
};

type NotificationHistoryBody = {
  start: number;
  length: number;
  search: {
    value: string;
    regex: boolean;
  };
};

type NotifHistroyResponse = {
  recordsTotal: number;
  code: number;
  message: string;
  data: Notification[];
};

export const NotificationApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postNotification: builder.mutation<GetCmsResponse, NotificationBody>({
      query: (body) => ({
        url: END_POINTS.send_notification,
        method: "POST",
        body,
      }),
    }),
    notificationHistroy: builder.mutation<
      NotifHistroyResponse,
      { body: NotificationHistoryBody }
    >({
      query: ({ body }) => ({
        url: END_POINTS.notif_history,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { usePostNotificationMutation, useNotificationHistroyMutation } =
  NotificationApi;
