export const isString = (val: string) => {
  if (val.includes(".") && val.length > 1 && val[val.length - 1] !== " ") {
    return true;
  }
  if (val.length === 1 && val === " ") {
    return false;
  }
  if (
    val[val.length - 1] === " " &&
    val[val.length - 1] !== val[val.length - 2] &&
    val[val.length - 2] !== " "
  ) {
    return true;
  }
  if (
    val[val.length - 1]?.trim()?.toLowerCase() !==
      val[val.length - 1]?.trim()?.toUpperCase() ||
    val === ""
  ) {
    return true;
  }
  return false;
};

export const isFloat = (val: any) => {
  console.log(val);
  if (val?.includes("-") || val?.includes("+")) {
    return false;
  }
  if (val[val.length - 1] === " " || val === "." || val === "0") {
    return false;
  }
  if (val.includes(".")) {
    val = val.replace(".", "");
    if ((!val.includes(".") && !isNaN(val?.trim())) || val === "") {
      return true;
    }
    return false;
  }
  if (!isNaN(val?.trim()) || val === "") {
    return true;
  }

  return false;
};

export const isNumber = (val: any) => {
  if (val[val.length - 1] === " " || val === "0") {
    return false;
  }
  if (val.includes(".")) {
    return false;
  }
  if (!isNaN(val?.trim()) || val === "") {
    return true;
  }
  return false;
};

export const isAlphanumeric = (val: any) => {
  if (val === "") {
    return true;
  }
  if (val.trim() === "" || /[^a-zA-Z0-9.\s]/.test(val)) {
    return false; // Reject if the value is empty, contains special characters, or only consists of whitespace
  }

  if (val.includes("  ") || val.includes("..")) {
    return false; // Reject if there are consecutive spaces or decimals
  }

  if (val?.includes("-") || val?.includes("+")) {
    return false;
  }
  if (
    val[val.length - 1] === " " &&
    val[val.length - 1] !== val[val.length - 2] &&
    val[val.length - 2] !== " "
  ) {
    return true;
  }
  if (val.includes(".")) {
    val = val.replace(".", "");
    if (!val.includes(".") || val === "") {
      return true;
    }
    return false;
  }
  if (!isNaN(val?.trim()) || val === "") {
    return true;
  }
  if (val[val.length - 1] === " " || val === "0") {
    return false;
  }
  return true;
};

export const isValidInput = (value: string) => {
  if (value === "") {
    return true;
  }
  if (value.trim() === "") {
    return false; // Reject if the value is empty or only consists of whitespace
  }

  if (value.includes("  ") || value.includes("..")) {
    return false; // Reject if there are consecutive spaces or decimals
  }

  return true; // Accept the input if it meets all the conditions
};

export const validMobileNo = (number: string) => {
  let phone_no = /^[1-9]{1}[0-9]{9}$/;
  if (number) {
    if (phone_no.test(number)) {
      return false;
    }
    return true;
  }
};

export const balanceFormat = (number: string) => {
  return parseFloat(number)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};
