/* eslint-disable react-hooks/exhaustive-deps */
import { EditOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Table,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { ContentHeader, TableLoader } from "../../components";
import {
  useGetSubAdminMutation,
  useLazyGetRoleQuery,
  useUpdateSubAdminRoleMutation,
  useUpdateUserByIdMutation,
} from "../../services/main";
import { useEffect, useState } from "react";
import { Permissions, SubAmin } from "../../types/General";
import { showError, showToast } from "../../helpers/toast";
import { isValidInput } from "../../utils/validations";
import useAuth from "../../hooks/useAuth";
import usePermissions from "../../hooks/permissions";
import { SideBarIds } from "../../utils/constants";

const { Option } = Select;

const ManageSubAdmins = () => {
  const user = useAuth();
  const navigate = useNavigate();
  const permissions = usePermissions();

  const [getRoles] = useLazyGetRoleQuery();
  const [getSubAdminMutataion, { isLoading }] = useGetSubAdminMutation();
  const [updateSubAminStatus] = useUpdateUserByIdMutation();
  const [updateSubAminRole] = useUpdateSubAdminRoleMutation();

  const [subAdmin, setSubAdmin] = useState<SubAmin[]>([]);
  const [roles, setRoles] = useState<{ id: number; name: string }[] | []>([]);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [modulePermissions, setModulePermissions] = useState<
    Permissions | null | undefined
  >(null);

  useEffect(() => {
    if (user && user?.role !== 1) {
      if (permissions) {
        let idx = -1;
        idx = permissions?.findIndex(
          (ele) => ele?.module?.id === SideBarIds.subAdmin
        );
        if (idx > -1) {
          setModulePermissions(permissions[idx]);
        } else {
          navigate(-1);
        }
      }
    }
  }, [permissions, user]);

  const updateStatus = async (id: string, state: boolean) => {
    console.log(id, state, "state");
    const response = await updateSubAminStatus({
      user_id: id,
      body: {
        is_active: state,
      },
    }).unwrap();
    if (response?.code === 200) {
      const updatedData = subAdmin.map((x) => {
        if (x.id === parseInt(id, 10)) {
          return {
            ...x,
            is_active: state,
          };
        }
        return x;
      });
      setSubAdmin(updatedData);
      showToast("Status updated successfully");
    } else {
      showError("Please try again");
    }
  };

  const updateRole = async (id: string, role: number) => {
    const response = await updateSubAminRole({
      subAdmin_id: id,
      body: {
        role_id: role,
      },
    }).unwrap();
    if (response?.code === 200) {
      const updatedData = subAdmin.map((x) => {
        if (x.id === parseInt(id, 10)) {
          return {
            ...x,
            user_role: {
              id: role,
              name: "",
            },
          };
        }
        return x;
      });
      setSubAdmin(updatedData);
      showToast("Status updated successfully");
    } else {
      showError("Please try again");
    }
  };

  const getRolesList = async () => {
    const body = {
      start: 1,
      length: 50,
      search: {
        value: "",
        regex: false,
      },
    };

    const response = await getRoles({ body }).unwrap();
    if (response?.code === 200) {
      setRoles(response?.data || []);
    }
  };

  const getSubAdmin = async () => {
    try {
      const body = {
        start: page,
        length: 10,
        is_approved: true,
        search: {
          value: debouncedSearchTerm.trim(),
          regex: false,
        },
      };
      const response = await getSubAdminMutataion({ body: body }).unwrap();
      if (response?.code === 200) {
        setSubAdmin(response?.data || []);
        setTotal(response?.recordsTotal || 1);
      } else {
        setSubAdmin([]);
      }
    } catch (error: any) {
      console.log(error);
      showError(error?.data?.message || "");
    }
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (!roles?.length) {
      getRolesList();
    }
  }, []);

  useEffect(() => {
    getSubAdmin();
  }, [page, debouncedSearchTerm]);

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [searchTerm]);

  const renderSwitch = (_: any, data: SubAmin) => (
    <Switch
      onChange={(val) => updateStatus(`${data?.id}`, val)}
      size="small"
      checked={data?.is_active}
    />
  );

  const columns: ColumnsType<SubAmin> = [
    {
      title: "S.no.",
      key: "sno",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (_, record) => (
        <div>
          {record.first_name} {record.last_name}
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone No",
      dataIndex: "office_phone_no",
      key: "office_phone_no",
      align: "center",
      render: (_, record) => (
        <div>{`${record?.office_country_code || ""}${
          record?.office_phone_no || ""
        }`}</div>
      ),
    },
    {
      title: "Assign Role",
      key: "status",
      align: "center",
      render: (_, record) => (
        <Select
          className="select-div"
          value={record?.user_role?.id || null}
          onChange={(val) => updateRole(`${record?.id}`, val)}
          placeholder={"Assign Role"}
        >
          {roles?.map((item, index) => (
            <Option key={index} value={item?.id}>
              {item?.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/managesubadmins/subadmindetail/${record?.id}`);
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>
          {modulePermissions?.is_edit || user?.role === 1 ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                window.open(`/managesubadmins/edit/${record?.id}`, "_blank");
              }}
            >
              <EditOutlined color="#08c" />
            </Button>
          ) : null}
        </Space>
      ),
    },
    {
      title: "Account Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: renderSwitch,
      className: modulePermissions?.is_edit || user?.role === 1 ? "" : "hidden",
    },
  ];

  return (
    <div className="module-container">
      <ContentHeader
        heading="Manage Sub Admins"
        buttonText="Add Sub Admin"
        buttonNavigateTo="managesubadmins/add"
        modulePermissions={modulePermissions}
      />
      <Card>
        <div className="srch">
          <Row gutter={16}>
            <Col xs={24} md={7}>
              <Input
                value={searchTerm}
                onChange={(val) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
                placeholder="Search"
                prefix={<SearchOutlined style={{ fontSize: "20px" }} />}
                className="search-bar"
                allowClear
              />
            </Col>
          </Row>
          {isLoading ? (
            <TableLoader />
          ) : (
            <Table
              className="table-container"
              columns={columns}
              dataSource={subAdmin || {}}
              pagination={{
                defaultPageSize: 10,
                current: page,
                onChange: onPageChange,
                total,
              }}
            />
          )}
        </div>
      </Card>
    </div>
  );
};

export default ManageSubAdmins;
