import { Button, Input, Modal, Select, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  EyeOutlined,
  EditOutlined,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import ProductFilters from "../filters";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Brand, Category, Product } from "../../../types/General";
import { PageLoader, TableLoader } from "../../../components";
import {
  useGetProductMutation,
  useAssignQcToProductMutation,
  useAssignScToProductMutation,
  useApproveProductAcceptRejectStatusMutation,
} from "../../../services/product";
import { showError, showToast, showWarning } from "../../../helpers/toast";

const { confirm } = Modal;
const { Option } = Select;

const UpdatedCompanies = ({
  activeTab,
  setUpdateCount,
  brands,
  categories,
  qcs,
  scs,
}: {
  activeTab: string;
  setUpdateCount: Dispatch<SetStateAction<number>>;
  qcs: { first_name: string; last_name: string; id: number }[];
  scs: { first_name: string; last_name: string; id: number }[];
  categories: Category[];
  brands: Brand[];
}) => {
  const navigate = useNavigate();

  const [getProducts, { isLoading }] = useGetProductMutation();
  const [assignQc, assignQcLoader] = useAssignQcToProductMutation();
  const [assignSc, assignScLoader] = useAssignScToProductMutation();
  const [updateProductStatus, updateProductStatusLoader] =
    useApproveProductAcceptRejectStatusMutation();

  const [page, setPage] = useState(1);
  const [products, setProducts] = useState<Product[]>([]);

  const [total, setTotal] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const [selectedBrand, setSelectedBrand] = useState<number | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [productId, setProductId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const [endDate, setEndDate] = useState("");
  const [visible, setVisible] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  const handleHideModal = () => {
    setVisible(false);
  };

  const getUpdatedProducts = async () => {
    const body = {
      start: page,
      length: 10,
      is_update_request: true,
      product_category: selectedCategory ? `${selectedCategory}` : "",
      brand: selectedBrand ? `${selectedBrand}` : "",
      id: productId || "",
      start_date: startDate || "",
      end_date: endDate || "",
      search: {
        value: debouncedSearchTerm.trim(),
        regex: false,
      },
    };
    try {
      const result = await getProducts(body).unwrap();
      console.log(result);
      if (result?.code === 200) {
        setProducts(result?.data || []);
        setTotal(result?.recordsTotal || 1);
        setUpdateCount(result?.requested_obj_count || 0);
      } else {
        setProducts([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const assignQcToProduct = async (productId: string, qcId: number) => {
    try {
      const body = {
        qc: qcId,
      };
      const response = await assignQc({ product_id: productId, body }).unwrap();
      console.log(response);
      if (response?.code === 200) {
        showToast("Qc assigned successfully.");
        let idx = -1;
        idx = qcs?.findIndex((ele) => ele?.id === qcId);
        if (idx > -1) {
          console.log(qcs[idx]);
          const updatedData = products.map((x) => {
            if (x.id === parseInt(productId, 10)) {
              return {
                ...x,
                assign_qc: {
                  first_name: qcs[idx]?.first_name || "",
                  last_name: qcs[idx]?.last_name || "",
                  id: Number(qcs[idx]?.id),
                },
              };
            }
            return x;
          });
          setProducts(updatedData);
        }
      } else {
        showError(response?.message || "Something went wrong");
      }
    } catch (error: any) {
      console.log(error);
      showError(error?.body?.message || "Something went wrong");
    }
  };

  const assignScToProduct = async (productId: string, qcId: number) => {
    try {
      const body = {
        sc: qcId,
      };
      const response = await assignSc({ product_id: productId, body }).unwrap();
      console.log(response);
      if (response?.code === 200) {
        showToast("SC assigned successfully.");
        let idx = -1;
        idx = scs?.findIndex((ele) => ele?.id === qcId);
        if (idx > -1) {
          const updatedData = products.map((x) => {
            if (x.id === parseInt(productId, 10)) {
              return {
                ...x,
                assign_sales_consultant: {
                  first_name: scs[idx]?.first_name || "",
                  last_name: scs[idx]?.last_name || "",
                  id: Number(scs[idx]?.id),
                },
              };
            }
            return x;
          });
          setProducts(updatedData);
        }
      } else {
        showError(response?.message || "Something went wrong");
      }
    } catch (error: any) {
      console.log(error);
      showError(error?.body?.message || "Something went wrong");
    }
  };

  const handleRejectProductStatus = async (status: boolean, id: number) => {
    try {
      const body = {
        status,
        product_rejection_reason: rejectReason,
      };
      const response = await updateProductStatus({
        product_id: `${selectedId || id}`,
        body,
      }).unwrap();
      if (response?.code === 200) {
        showToast(status ? "Product updated" : "Product update rejected");
        getUpdatedProducts();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showDeleteConfirm = (id: number) => {
    confirm({
      title: "Hold on",
      content: "Are you sure you want to approve this product?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onCancel() {
        console.log("Cancel");
      },
      onOk() {
        handleRejectProductStatus(true, id);
        console.log("OK", id);
      },
    });
  };

  useEffect(() => {
    if (activeTab === "3") {
      getUpdatedProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    debouncedSearchTerm,
    activeTab,
    selectedBrand,
    selectedCategory,
    productId,
    startDate,
  ]);

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [searchTerm]);

  const columns: ColumnsType<Product> = [
    {
      title: "S.no.",
      key: "sno",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
      align: "center",
    },
    {
      title: "Product Id",
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      render: (_, record) => (
        <div>
          {record?.by_company && typeof record?.by_company !== "number"
            ? record?.by_company?.name || ""
            : ""}
        </div>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (_, record) => (
        <div>
          {record?.product_category &&
          typeof record?.product_category !== "number"
            ? record?.product_category?.name || "other"
            : "other"}
        </div>
      ),
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      render: (_, record) => (
        <div>
          {record?.brand && typeof record?.brand !== "number"
            ? record?.brand?.name || "other"
            : "other"}
        </div>
      ),
    },
    {
      title: "Country of Origin",
      dataIndex: "country_of_origin",
      key: "country_of_origin",
      render: (_, record) => (
        <div>
          {record?.country_of_origin &&
          typeof record?.country_of_origin !== "number"
            ? record?.country_of_origin.name
            : ""}
        </div>
      ),
    },
    {
      title: "Price",
      dataIndex: "product_cost",
      key: "product_cost",
      align: "center",
      render: (_, record) => <div>{`$${record?.product_cost || ""}`}</div>,
    },
    {
      title: "Assign QC",
      key: "status",
      align: "center",
      render: (_, record) => (
        <Select
          style={{ width: "50%", height: 52 }}
          value={record?.assign_qc?.id || null}
          onChange={(val) => {
            console.log(`qc-id ${val} product-id ${record?.id}`);
            assignQcToProduct(`${record?.id}`, val);
          }}
          placeholder={"Assign QC"}
        >
          {qcs?.map((item, index) => (
            <Option key={index} value={item?.id}>{`${item?.first_name || ""} ${
              item?.last_name || ""
            }`}</Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Assign SC",
      key: "status",
      align: "center",
      render: (_, record) => (
        <Select
          className="select-div"
          value={record?.assign_sales_consultant?.id || null}
          onChange={(val) => assignScToProduct(`${record?.id}`, val)}
          placeholder={"Assign SC"}
        >
          {scs?.map((item, index) => (
            <Option key={index} value={item?.id}>{`${item?.first_name || ""} ${
              item?.last_name || ""
            }`}</Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              window.open(
                `/manage-products/${record?.id}/${activeTab}`,
                "_blank"
              );
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manage-products/${record?.id}?type=unapproved`, {
                state: { tab: activeTab },
              });
            }}
          >
            <EditOutlined color="#08c" />
          </Button>
        </Space>
      ),
    },
    {
      title: "Approve",
      key: "approve",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              setSelectedId(record?.id);
              showDeleteConfirm(record?.id);
            }}
          >
            <CheckOutlined style={{ color: "#52c41a" }} />
          </Button>
          <Button>
            <CloseOutlined
              onClick={() => {
                setSelectedId(record?.id);
                setVisible(true);
              }}
              style={{ color: "#d4380d" }}
            />
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <PageLoader
        isLoading={
          assignQcLoader?.isLoading ||
          assignScLoader?.isLoading ||
          updateProductStatusLoader?.isLoading
        }
      />
      <div className="srch">
        <ProductFilters
          brands={brands}
          categories={categories}
          productId={productId}
          searchTerm={searchTerm}
          selectedBrand={selectedBrand}
          selectedCategory={selectedCategory}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setProductId={setProductId}
          setSearchTerm={setSearchTerm}
          setSelectedBrand={setSelectedBrand}
          setSelectedCategory={setSelectedCategory}
        />
        {isLoading ? (
          <TableLoader />
        ) : (
          <Table
            className="table-container"
            columns={columns}
            dataSource={products || []}
            pagination={{
              defaultPageSize: 10,
              current: page,
              onChange: onPageChange,
              total,
            }}
          />
        )}
      </div>
      <Modal
        open={visible}
        title="Reason"
        onCancel={handleHideModal}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              if (rejectReason?.length < 10) {
                showWarning("Please enter a valid reason.");
              } else {
                handleHideModal();
                handleRejectProductStatus(false, selectedId);
              }
            }}
          >
            Submit
          </Button>,
        ]}
      >
        <div style={{ width: "100%", marginTop: 20, marginBottom: 20 }}>
          <Input.TextArea
            placeholder="Please enter the reason for not approving the product, we will share this feedback with the concerned authority."
            style={{
              height: "100px",
              borderRadius: "8px",
              minHeight: "40vh",
            }}
            value={rejectReason}
            onChange={(val) => setRejectReason(val.target.value)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default UpdatedCompanies;
