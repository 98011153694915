import { Button, Card, Col, Row } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetWarehouseByIdQuery } from "../../../services/warehouse";
import { useEffect, useState } from "react";
import { Warehouse } from "../../../types/General";
import { showError } from "../../../helpers/toast";

const WareHouseDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [WarehouseById] = useLazyGetWarehouseByIdQuery();

  const [warehouse, setWarehouse] = useState<Warehouse | null>(null);
  const [imagePreview, setImagePreview] = useState("");

  const warehouseDetail = async (id: string) => {
    try {
      const response = await WarehouseById({ warehouse_id: id }).unwrap();
      if (response?.code === 200) {
        setWarehouse(response?.data);
        setImagePreview(response?.data?.contract?.media_file_url);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      warehouseDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="module-details-container">
      <Card className="product_details">
        <div className="detail-module-card">
          <h2>Ware House Details</h2>
          <Button
            className="back-btn-container"
            type="primary"
            onClick={() => {
              navigate("/manage-warehouse", { replace: true });
            }}
          >
            Back
          </Button>
        </div>

        <div>
          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Name</h4>
              <h5>{warehouse?.name || ""}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Email</h4>
              <h5>{warehouse?.email || ""}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Phone Number</h4>
              <h5>{`${warehouse?.country_code || ""} ${
                warehouse?.phone_no || ""
              }`}</h5>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Location</h4>
              <h5>{warehouse?.location || ""}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Type</h4>
              <h5>{warehouse?.type === 1 ? "Internal" : "External"}</h5>
            </Col>
            <Col xs={24} md={12}>
              <h4>Contract</h4>
              <div className="product_figure_preview">
                <figure>
                  <img src={imagePreview || ""} alt="" />
                </figure>
              </div>
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default WareHouseDetails;
