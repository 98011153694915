import { Button, Card } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import FormData from "./Form";
import useAuth from "../../../hooks/useAuth";
import usePermissions from "../../../hooks/permissions";
import { useEffect } from "react";
import { showWarning } from "../../../helpers/toast";
import { SideBarIds } from "../../../utils/constants";

const VerifyCompany = () => {
  const user = useAuth();
  const { state } = useLocation();
  const navigate = useNavigate();
  const permissions = usePermissions();

  useEffect(() => {
    if (user?.role !== 1) {
      if (permissions) {
        let idx = -1;
        idx = permissions?.findIndex(
          (ele) => ele?.module?.id === SideBarIds.companies
        );
        if (idx > -1) {
          if (!permissions[idx]?.is_add || !permissions[idx]?.is_edit) {
            showWarning("You are not authorized to access this page");
            navigate(-1);
          }
        }
        showWarning("You are not authorized to access this page");
        navigate(-1);
        console.log(idx);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, user]);

  return (
    <div className="module-container">
      <Card>
        <div className="detail-module-card">
          <h2>Edit Company Details</h2>
          <Button
            className="back-btn-container"
            type="primary"
            onClick={() => {
              navigate("/manage-companies", {
                replace: true,
                state: { tab: state?.tab || "1" },
              });
            }}
          >
            Back
          </Button>
        </div>
        <FormData />
      </Card>
    </div>
  );
};

export default VerifyCompany;
