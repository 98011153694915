import AuthWrapper from "../../../layout/authWrapper";
import "../login/login.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { showError, showToast } from "../../../helpers/toast";
import { useState } from "react";
import { useResetPasswordMutation } from "../../../services/auth";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { state } = location;

  const [password, setpassword] = useState("");
  const [resetPasswordMutation, { isLoading }] = useResetPasswordMutation();

  const onFinish = async (values: any) => {
    const body = {
      password,
      encoded_id: state,
    };
    console.log(body, "body");

    try {
      const response = await resetPasswordMutation(body).unwrap();
      console.log(response);
      if (response?.code === 200) {
        showToast(response?.message || "");
        navigate("/", { replace: true });
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error);
    }
  };

  const validateConfirmPassword = ({
    getFieldValue,
  }: {
    getFieldValue: any;
  }) => ({
    validator(_: any, value: any) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Passwords do not match."));
    },
  });

  const onFinishFailed = ({ values, errorFields, outOfDate }: any) => {
    console.log(
      values,
      errorFields,
      outOfDate,
      "values, errorFields, outOfDate"
    );
  };

  return (
    <AuthWrapper>
      <Card className="login_card">
        <div onClick={() => navigate(-1)}>
          <ArrowLeftOutlined style={{ fontSize: 20, cursor: "pointer" }} />
        </div>
        <div className="login_img">
          <img src="/static/images/logo.png" alt="" />
        </div>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{
            maxWidth: 600,
            marginTop: 50,
          }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="password"
            className="pswrd"
            rules={[
              { required: true, message: "Please add password" },
              //   {
              //     pattern:
              //       /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
              //     message:
              //       "Must contain 8 or more characters, one uppercase, one lowercase, one special character and one number.",
              //   },
            ]}
          >
            <Input.Password
              style={{ width: "400px", height: "50px" }}
              value={password}
              onChange={(e) => setpassword(e.target.value)}
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              validateConfirmPassword,
              // {
              //   validator: handleConfirmPassword,
              // },
            ]}
          >
            <Input.Password
              style={{ width: "400px", height: "50px" }}
              placeholder="Confirm Password"
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <div style={{ display: "flex" }}>
              <Button
                style={{
                  background: "#0C163F",
                  width: 200,
                  height: 50,
                  margin: "auto",
                }}
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                Send
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </AuthWrapper>
  );
};

export default ResetPassword;
