import { Input, Select, Row, Col, DatePicker, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Dispatch, SetStateAction, useState } from "react";
import { Brand, Category } from "../../../types/General";
import { isNumber, isString } from "../../../utils/validations";
import { EventValue, RangeValue } from "rc-picker/lib/interface";
import { Dayjs } from "dayjs";

const { Option } = Select;
const { RangePicker } = DatePicker;

const ProductFilters = ({
  searchTerm,
  endDate,
  setSearchTerm,
  selectedBrand,
  setSelectedBrand,
  selectedCategory,
  setSelectedCategory,
  productId,
  setProductId,
  setEndDate,
  setStartDate,
  brands,
  categories,
}: {
  setSearchTerm: Dispatch<SetStateAction<string>>;
  searchTerm: string;
  selectedBrand: number | null;
  setSelectedBrand: Dispatch<SetStateAction<number | null>>;
  selectedCategory: number | null;
  setSelectedCategory: Dispatch<SetStateAction<number | null>>;
  productId: string;
  endDate: string;
  setProductId: Dispatch<SetStateAction<string>>;
  setStartDate: Dispatch<SetStateAction<string>>;
  setEndDate: Dispatch<SetStateAction<string>>;
  categories: Category[];
  brands: Brand[];
}) => {
  const [selectedDates, setSelectedDates] = useState<
    RangeValue<Dayjs>[] | null
  >([]);

  const clearFilter = () => {
    setSearchTerm("");
    setSelectedBrand(null);
    setSelectedCategory(null);
    setProductId("");
    setStartDate("");
    setSelectedDates(null);
  };

  const handleDateChange = (dates: RangeValue<Dayjs>[]) => {
    setSelectedDates(dates);
  };

  return (
    <div>
      <Row gutter={20}>
        <Col xs={12} md={5}>
          <Input
            value={searchTerm}
            onChange={(val) => {
              if (isString(val.target.value)) {
                setSearchTerm(val.target.value);
              }
            }}
            allowClear
            placeholder="Search"
            prefix={<SearchOutlined style={{ fontSize: "20px" }} />}
            className="search-bar"
          />
        </Col>
        <Col xs={12} md={3}>
          <Input
            value={productId}
            onChange={(val) => {
              if (isNumber(val.target.value)) {
                setProductId(val.target.value);
              }
            }}
            allowClear
            style={{ height: 55 }}
            placeholder="Product id"
          />
        </Col>
        <Col xs={12} md={4}>
          <Select
            style={{ width: "100%" }}
            placeholder={"Brands"}
            value={selectedBrand}
            onChange={(value) => {
              setSelectedBrand(value);
            }}
            allowClear
          >
            {brands?.map((item, index) => (
              <Option value={item?.id} key={index}>
                {item?.name || ""}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={12} md={4}>
          <Select
            style={{ width: "100%" }}
            placeholder={"Category"}
            value={selectedCategory}
            onChange={(value) => {
              setSelectedCategory(value);
            }}
            allowClear
          >
            {categories?.map((item, index) => (
              <Option value={item?.id} key={index}>
                {item?.name || ""}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={12} md={6}>
          <RangePicker
            onChange={(val) => {
              handleDateChange(val as RangeValue<Dayjs>[]);
              if (val?.length) {
                setStartDate(val[0]?.format("YYYY-MM-DD") || "");
                setEndDate(val[1]?.format("YYYY-MM-DD") || "");
              } else {
                setStartDate("");
                setEndDate("");
              }
            }}
            value={selectedDates as [EventValue<Dayjs>, EventValue<Dayjs>]}
            disabledDate={(d) => !d || d.isAfter(new Date())}
            style={{ height: 55 }}
          />
        </Col>
        <Col xs={12} md={2}>
          {productId || selectedBrand || selectedCategory || endDate ? (
            <Button onClick={clearFilter} className="header-btn" type="primary">
              Clear
            </Button>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default ProductFilters;
