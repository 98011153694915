import { Button, Col, Input, Row, Space, Switch, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { EyeOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  useLazyGetAllUsersQuery,
  useUpdateUserByIdMutation,
} from "../../../services/main";
import { useEffect, useState } from "react";
import { Permissions, User } from "../../../types/General";
import { showError, showToast } from "../../../helpers/toast";
import { TableLoader } from "../../../components";
import { isValidInput } from "../../../utils/validations";
import usePermissions from "../../../hooks/permissions";
import useAuth from "../../../hooks/useAuth";
import { SideBarIds } from "../../../utils/constants";

const ManageBuyers = () => {
  const user = useAuth();
  const navigate = useNavigate();
  const permissions = usePermissions();

  const [getAllUsersList, { isLoading }] = useLazyGetAllUsersQuery();
  const [updateUserStatus] = useUpdateUserByIdMutation();
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [modulePermissions, setModulePermissions] = useState<
    Permissions | null | undefined
  >(null);

  useEffect(() => {
    if (user && user?.role !== 1) {
      if (permissions) {
        let idx = -1;
        idx = permissions?.findIndex(
          (ele) => ele?.module?.id === SideBarIds.users
        );
        if (idx > -1) {
          setModulePermissions(permissions[idx]);
        } else {
          navigate(-1);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, user]);

  const getUsers = async () => {
    try {
      const body = {
        start: page,
        length: 10,
        is_approved: true,
        search: {
          value: debouncedSearchTerm.trim(),
          regex: false,
        },
      };
      const response = await getAllUsersList({ body: body }).unwrap();
      if (response?.code === 200) {
        setAllUsers(response?.data || []);
        setTotal(response?.recordsTotal || 1);
      } else {
        setAllUsers([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [searchTerm]);

  const updateStatus = async (id: string, state: boolean) => {
    const response = await updateUserStatus({
      user_id: id,
      body: {
        is_active: state,
      },
    }).unwrap();
    if (response?.code === 200) {
      const updatedData = allUsers.map((x) => {
        if (x.id === parseInt(id, 10)) {
          return {
            ...x,
            is_active: state,
          };
        }
        return x;
      });
      setAllUsers(updatedData);
      showToast("Status updated successfully");
    } else {
      showError("Please try again");
    }
  };

  useEffect(() => {
    if (modulePermissions || user?.role === 1) {
      getUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, debouncedSearchTerm, modulePermissions]);

  const renderSwitch = (_: any, data: User) => (
    <Switch
      onChange={(val) => updateStatus(`${data?.id}`, val)}
      size="small"
      checked={data?.is_active}
    />
  );

  const columns: ColumnsType<User> = [
    {
      title: "S.no.",
      key: "sno",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (_, record) => (
        <div>
          {record.first_name} {record.last_name}
        </div>
      ),
    },
    {
      title: "Company",
      dataIndex: "user_company",
      key: "user_company",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "company_phone_no",
      key: "company_phone_no",
      align: "center",
      render: (_, record) => (
        <div>{`${record?.company_country_code || ""}${
          record?.company_phone_no || ""
        }`}</div>
      ),
    },
    {
      title: "Address",
      dataIndex: "location",
      key: "location",
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manageusers/userdetail/${record?.id}`);
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>
          {modulePermissions?.is_edit || user?.role === 1 ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                window.open(`/manageusers/edit/${record?.id}`, "_blank");
              }}
            >
              <EditOutlined color="#08c" />
            </Button>
          ) : null}
        </Space>
      ),
    },
    {
      title: "Account Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: renderSwitch,
      className: modulePermissions?.is_edit || user?.role === 1 ? "" : "hidden",
    },
  ];

  return (
    <div>
      <div className="srch">
        <Row gutter={16}>
          <Col xs={24} md={7}>
            <Input
              value={searchTerm}
              onChange={(val) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
              placeholder="Search "
              prefix={<SearchOutlined style={{ fontSize: "20px" }} />}
              // style={{ height: 55, borderRadius: 10, minWidth: "360px" }}
              className="search-bar"
              allowClear
            />
          </Col>
        </Row>
        {isLoading ? (
          <TableLoader />
        ) : (
          <Table
            className="table-container"
            columns={columns}
            dataSource={allUsers || []}
            pagination={{
              defaultPageSize: 10,
              current: page,
              onChange: onPageChange,
              total,
              showSizeChanger: false,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ManageBuyers;
