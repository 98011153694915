import { Spin } from "antd";

const PageLoader = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <div
      style={{
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        zIndex: 2,
        left: "50%",
        top: "50%",
      }}
    >
      <Spin size="large" />
    </div>
  );
};

export default PageLoader;
