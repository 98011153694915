import { Breadcrumb, Button, Card } from "antd";
import { useNavigate } from "react-router-dom";
import { Permissions } from "../types/General";
import useAuth from "../hooks/useAuth";

type ContentHeaderType = {
  heading: string;
  buttonText?: string;
  buttonNavigateTo?: string;
  modulePermissions?: Permissions | null;
};

const ContentHeader = ({
  heading,
  buttonText,
  buttonNavigateTo,
  modulePermissions,
}: ContentHeaderType) => {
  const navigate = useNavigate();
  const user = useAuth();

  return (
    <div>
      <div>
        <Breadcrumb
          items={[
            {
              title: (
                <div
                  className="breadcrumb_item"
                  onClick={() => navigate("/dashboard")}
                >
                  Dashboard
                </div>
              ),
            },
            {
              title: <div className="breadcrumb_item">{heading}</div>,
            },
          ]}
          style={{ fontSize: 16 }}
        />
      </div>

      <Card
        className="module_title_bar" 
        style={{
          margin: "15px 0px",
        }}
      >
        <div className="content-header-container">
          <div
            style={{
              fontSize: 20,
              fontWeight: 600,
            }}
          >
            {heading}
          </div>
          {modulePermissions?.is_add || user?.role === 1 ? (
            <>
              {buttonText ? (
                <Button
                  className="header-btn"
                  type="primary"
                  // htmlType="submit"
                  onClick={() => {
                    if (buttonNavigateTo) {
                      navigate(`/${buttonNavigateTo}`);
                    } else {
                      navigate("/dashboard");
                    }
                  }}
                >
                  {buttonText || "Add"}
                </Button>
              ) : (
                <></>
              )}
            </>
          ) : null}
        </div>
      </Card>
    </div>
  );
};

export default ContentHeader;
