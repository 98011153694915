import { Button, Card, Col, Input, Row, Space, Switch, Table } from "antd";
import { ContentHeader } from "../../components";
import { EditOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import {
  useChangeRoleStatusMutation,
  useLazyGetRoleQuery,
} from "../../services/main";
import { useEffect, useState } from "react";
import { showError, showToast } from "../../helpers/toast";
import { isValidInput } from "../../utils/validations";

interface DataType {
  id: number;
  name: string;
  is_active: boolean;
}

const Roles = () => {
  const navigate = useNavigate();
  const [getRoles, { isLoading }] = useLazyGetRoleQuery();
  const [changeRoleStatus] = useChangeRoleStatusMutation();

  const [page, setPage] = useState(1);
  const [roles, setRoles] = useState<DataType[] | []>([]);
  const [total, setTotal] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const getRolesList = async () => {
    const body = {
      start: page,
      length: 10,
      search: {
        value: debouncedSearchTerm.trim(),
        regex: false,
      },
    };

    const response = await getRoles({ body }).unwrap();
    console.log(response);
    if (response?.code === 200) {
      setRoles(response?.data || []);
      setTotal(response?.recordsTotal || 1);
    }
  };

  useEffect(() => {
    getRolesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, page]);

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [searchTerm]);

  const updateStatus = async (id: string, state: boolean) => {
    try {
      const response = await changeRoleStatus({
        role_id: id,
        body: {
          is_active: state,
        },
      }).unwrap();
      if (response?.code === 200) {
        const updatedData = roles.map((x) => {
          if (x.id === parseInt(id, 10)) {
            return {
              ...x,
              is_active: state,
            };
          }
          return x;
        });
        setRoles(updatedData);
        showToast("Status updated successfully");
      } else {
        showError("Please try again");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderSwitch = (_: any, data: DataType) => {
    return (
      <Switch
        onChange={(val) => updateStatus(`${data?.id}`, val)}
        size="small"
        checked={data?.is_active}
      />
    );
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "S.no.",
      key: "sno",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manage-roles/details/${record?.id}`);
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>

          <Button
            onClick={(e) => {
              e.preventDefault();
              window.open(`/manage-roles/${record?.id}`, "_blank");
            }}
          >
            <EditOutlined color="#08c" />
          </Button>
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: renderSwitch,
    },
  ];

  return (
    <div className="module-container">
      <ContentHeader
        heading="Manage Roles and Permissions"
        buttonNavigateTo="manage-roles/add"
        buttonText="Add"
      />
      <Card>
        <div className="srch">
          <Row gutter={16}>
            <Col xs={24} md={7}>
              <Input
                value={searchTerm}
                onChange={(val) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
                allowClear
                placeholder="Search Role"
                prefix={<SearchOutlined style={{ fontSize: "20px" }} />}
                // style={{ height: 55, borderRadius: 10, minWidth: "360px" }}
                className="search-bar"
              />
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={roles || []}
            loading={isLoading}
            pagination={{
              defaultPageSize: 10,
              current: page,
              onChange: onPageChange,
              total,
            }}
          />
        </div>
      </Card>
    </div>
  );
};

export default Roles;
