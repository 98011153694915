import { END_POINTS } from "../helpers/constants/urls";
import { OrderProducts, Orders, Paginated, Warehouse } from "../types/General";
import emptySplitApi from "../utils/rtk";

type OrdersResponse = {
  code: number;
  message: string;
  data: Orders[];
  recordsTotal: number;
  pending_compay_obj_count: number;
};

type OrderDetailsResponse = {
  code: number;
  message: string;
  data: OrderProducts;
};

type GetOrdersPayload = {
  start: number;
  length: number;
  search?: Paginated;
};

export const ordersApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.mutation<OrdersResponse, GetOrdersPayload>({
      query: (body) => ({
        url: END_POINTS.get_orders,
        method: "POST",
        body,
      }),
    }),
    getOrderDetails: builder.query<OrderDetailsResponse, { id: string }>({
      query: ({ id }) => ({
        url: `${END_POINTS.get_order_details}${id}`,
        method: "GET",
      }),
    }),
    getWareHouses: builder.query<
      { code: number; message: string; data: Warehouse[] },
      {}
    >({
      query: () => ({
        url: `${END_POINTS.get_warehouses}`,
        method: "GET",
      }),
    }),
    updateOrderStatus: builder.mutation<
      OrderDetailsResponse,
      {
        product_order_id: string;
        body: {
          order_status: number;
        };
      }
    >({
      query: ({ product_order_id, body }) => ({
        url: `${END_POINTS.update_order_status}${product_order_id}/`,
        method: "PUT",
        body,
      }),
    }),
    assignWarehouseToOrder: builder.mutation<
      OrderDetailsResponse,
      {
        product_order_id: string;
        body: {
          warehouse_id: number;
        };
      }
    >({
      query: ({ product_order_id, body }) => ({
        url: `${END_POINTS.assign_warehouses}${product_order_id}/`,
        method: "PUT",
        body,
      }),
    }),
    assignSubAdminToOrder: builder.mutation<
      OrderDetailsResponse,
      {
        product_order_id: string;
        body: {
          qc_id: number;
        };
      }
    >({
      query: ({ product_order_id, body }) => ({
        url: `${END_POINTS.assigned_qc_to_order_product}${product_order_id}/`,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useGetOrdersMutation,
  useUpdateOrderStatusMutation,
  useLazyGetOrderDetailsQuery,
  useLazyGetWareHousesQuery,
  useAssignWarehouseToOrderMutation,
  useAssignSubAdminToOrderMutation,
} = ordersApi;
