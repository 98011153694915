// export const API_URL = "https://devenilcon.webdevelopmentsolution.net/api/"; // development
export const API_URL = "https://enilcon.webdevelopmentsolution.net/api/"; // staging

export const END_POINTS = {
  login: "auth/admin/login/",
  forgot_password: "admin/forgot-password/",
  verify_otp: "admin/forgot-verify-otp/",
  change_password: "admin/change-password/",
  update_profile: "admin/update-profile/",
  get_user: "get-profile-details-by-token/",
  categories_list: "produt/get-all-category-with-pagination/",
  category: "product/get-product-category-by-id/",
  update_category: "product/update-category/",
  subcategories_list: "produt/get-all-sub-category-with-pagination/",
  sub_category: "product/get-product-sub-category-by-id/",
  add_category: "product/create-category/",
  add_subcategory: "product/create-sub-category/",
  update_sub_category: "product/update-sub-category/",
  brands_list: "produt/get-all-brand-with-pagination/",
  brand: "product/get-brand-by-id/",
  upload: "upload/media/",
  update_brand: "product/update-brand-details-by-id/",
  add_brand: "product/add-brand/",
  get_all_user: "users/get-all-users-list-with-pagination/",
  user: "users/get-user-details-by-id/",
  update_user_status_by_id: "users/change-approved-status/",
  update_user_detail: "user/update-user-details/",
  update_user_role: "users/change-user-role/",
  get_cms: "cms/get-cms/",
  post_cms: "cms/create-update-cms/",
  get_faq: "cms/get-faq/",
  post_faq: "cms/get-create-update-delete-faq/",
  get_companies: "comapines/get-company-owner-list-with-pagination/",
  get_company_details: "comapines/get-company-owner-detail-by-id/",
  update_company_details: "user/update-user-details/",
  update_company_status: "comapines/change-company-owner-status/",
  update_company_approved_status: "comapines/change-approved-status/",
  add_warehouse: "warehouse/create-ware-house/",
  get_all_warehouse: "/warehouse/get-all-warehouse-list-with-pagination/",
  update_warehouse_by_id: "warehouse/update-ware-house/",
  get_warehouse_by_id: "warehouse/get-ware-house-by-id/",
  update_warehouse_status: "warehouse/change-warehouse-status/",
  get_countries_list: "countries/get-all-countries-by-pagination/",
  get_sub_category_by_category_id:
    "product/get-product-sub-category-by-category-id/",
  add_product: "product/create-product-by-admin/",
  get_products: "produt/get-all-products-with-pagination/",
  send_notification: "notification/send-notification/",
  notif_history: "notification/notification-history/",
  user_graph: "user/get-users-graph/",
  revenue_graph: "user/get-revenue-graph/",
  add_subAdmin: "sub-admin/create-sub-admin/",
  get_all_subAdmin: "sub-admin/get-sub-admin-pagination/",
  get_subAdmin_list: "sub-admin/get-all-sub-admin-list/",
  get_subAdmin_by_id: "sub-admin/get-sub-admin-by-id/",
  update_subAdmin_by_id: "sub-admin/update-sub-admin/",
  update_product: "product/update-product/",
  get_qc_list: "roles/get-all-qc/",
  assign_qc: "product/assign-qc/",
  get_product_detail: "product/get-product-by-id/",
  get_requested_product_detail: "admin/get-requested-product-by-id/",
  update_product_status: "product/change-product-status/",
  update_product_accept_reject_status: "product/accept-reject-project-status/",
  approve_product_accept_reject_status:
    "admin/approve-requested-product-by-id/",
  get_roles: "role/get-all-role-pagination-list/",
  update_role: "role/update-details/",
  get_role_by_id: "role/get-role-details-by-id/",
  create_role: "sub-admin-role/create-role/",
  change_role_status: "role/change-role-status/",
  get_sub_admin_modules: "role/get-all-subadmin-module/",
  get_token_amount: "token/get-all-token-amounts/",
  update_token_amount: "token/update-token-amount/",
  assign_team: "warehouse/assign-qc-to-warehouse/",
  get_sales_consultants: "roles/get-all-sales-consultant/",
  assign_sales_consultant: "product/assign-sales-consultant/",
  get_warehouses: "warehouse/list-all-warehouse/",
  assign_warehouses: "cart/assign-warehouse-to-order/",
  get_orders: "order/get-order-details-pagination/",
  get_order_details: "order/get-ordered-product-details-by-id/",
  update_order_status: "order/update-order-status/",
  dashboard: "dashboard/get-dashboard-counts/",
  get_ratings: "rating/get-rating-by-pagination/",
  update_rating: "rating/update-rating-status-by-id/",
  create_company_group: "user/create-company-group/",
  update_company_group: "user/update-company-group/",
  assign_company_group: "user/assign-company-to-group/",
  get_all_company_group: "user/get-all-company-group/",
  get_company_group_details: "user/get-company-group-by-id/",
  get_product_matrix: "user/dashboard-matrix-product-count/",
  get_assigned_qc_products: "user/get-assigned-qc-product-name/",
  assigned_qc_to_order_product: "cart/assign-qc-to-ordered-product/",
};
